import { NgModule } from '@angular/core';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { FeatureFlagsStoreModule } from '@core/feature-flags/store/feature-flags-store.module';

@NgModule({
  declarations: [FeatureFlagDirective],
  exports: [FeatureFlagDirective],
  imports: [FeatureFlagsStoreModule],
})
export class FeatureFlagsModule {}
