import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'kody-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
})
export class FormGroupComponent implements OnInit {
  @Input() control: FormControl = new FormControl();
  // formControlName of the input.
  @Input() name: string;
  @Input() label: string;
  @Input() validate? = true;
  @Input() validationErrors?: { [key: string]: string } = {};
  @Input() required? = false;

  readonly faExclamationTriangle = faExclamationTriangle;
  autoRequired: boolean;

  ngOnInit(): void {
    if (!this.validate) {
      return;
    }
    if (this.control.validator && this.control.validator(new FormControl(''))) {
      const errors = this.control.validator(new FormControl(''));
      this.autoRequired = Object.keys(errors).includes('required');
    }
  }
}
