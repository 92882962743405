import { Component, Input } from '@angular/core';

@Component({
  selector: 'kody-pill',
  templateUrl: './kodypay-label.component.html',
  styleUrls: ['./kodypay-label.component.scss'],
})
export class KodypayLabelComponent {
  @Input() color: PillColor;
  @Input() text: string;
}

export type PillColor = 'primary' | 'success' | 'warn' | 'orange' | 'gray';
