import { createSelector } from '@ngrx/store';
import { IMerchantState, IStock, IStore, IOrder, IMenuCategory } from '@app/state/merchant-state/interfaces';
import { MatTableDataSource } from '@angular/material/table';
import {
  FilterOptions,
  ClickCollectOrderStatuses,
  DefaultOrderStatuses,
  OrderStatusesForTableArr,
  CollectStatusLabels,
  UserStatusesForTableArr,
  StaffStatusesForTableArr,
  TransactionStatusesForTableMap,
  TransactionMethodsForTable,
} from '@utils/constants';
import { IMerchant, IMerchantKyc } from '@app/state/app-state/interfaces';

export interface MerchantState {
  merchantState: IMerchantState;
}

const merchantState = (state: MerchantState) => state.merchantState;

export const getStores = createSelector(merchantState, (state: IMerchantState) => new MatTableDataSource<IStore>(state.stores));

export const getOrders = createSelector(merchantState, (state: IMerchantState) => new MatTableDataSource<IOrder>(state.orders));

export const getMenuCategories = createSelector(
  merchantState,
  (state: IMerchantState) => new MatTableDataSource<IMenuCategory>(state.restaurantMenuCategories)
);

export const getStoreDataIntervals = createSelector(merchantState, (state: IMerchantState) => state.dataIntervals);

export const getStoresArr = createSelector(merchantState, (state: IMerchantState) => state.stores);

export const getDefaultStore = createSelector(merchantState, (state: IMerchantState) => {
  if (state.stores && state.stores.length) {
    return state.stores[0];
  }
});

export const getMenuCategoriesArr = createSelector(merchantState, (state: IMerchantState) => state.restaurantMenuCategories);

export const getOfferTypes = createSelector(merchantState, (state: IMerchantState) => state.offerTypes);

export const getStatuses = createSelector(merchantState, (state: IMerchantState) => state.statuses);

export const getStockToEdit = createSelector(merchantState, (state: IMerchantState) => (state.stockToEdit ? state.stockToEdit : {}));

export const getStoreToEdit = createSelector(merchantState, (state: IMerchantState) => state.storeToEdit);

export const getOrderToEdit = createSelector(merchantState, (state: IMerchantState) => state.orderToEdit);

export const getCategoryToEdit = createSelector(merchantState, (state: IMerchantState) => state.categoryToEdit);

export const getAddons = createSelector(merchantState, (state: IMerchantState) => state.addonItems);

export const getAddonGroups = createSelector(merchantState, (state: IMerchantState) => state.addonGroups);

export const getOrderStatuses = createSelector(merchantState, (state: IMerchantState) => {
  if (state?.orderToEdit.type == 'order_click_collect') {
    return ClickCollectOrderStatuses;
  } else {
    return DefaultOrderStatuses;
  }
});

export const getItemStatusLabel = (item: string) =>
  createSelector(merchantState, () => {
    return CollectStatusLabels?.[item] ? (CollectStatusLabels?.[item] as string) : 'N/A';
  });

export const getStatusById = (item: string) =>
  createSelector(merchantState, (state: IMerchantState) => {
    return state.statusesById?.[item]?.name;
  });

export const getRestaurantMenuCategoryId = (item: string) =>
  createSelector(merchantState, (state: IMerchantState) => {
    return state.restaurantMenuCategoryId?.[item]?.name;
  });

export const getAssociatedStoresAsString = (item: IStock) =>
  createSelector(merchantState, (state: IMerchantState) => {
    let ret = '';
    if (item.merchantStoreIds?.length > 0) {
      ret = state.storeById?.[item.merchantStoreIds[0]]?.name;

      if (item.merchantStoreIds.length > 1) {
        ret += `, +${item.merchantStoreIds.length - 1} more`;
      }
    }
    return ret;
  });

export const getAssociatedStoresAsStringForExport = (item: IStock) =>
  createSelector(merchantState, (state: IMerchantState) => {
    let ret = '';
    const beforeRet = [];
    if (item.merchantStoreIds?.length > 0) {
      for (let i = 0; i < item.merchantStoreIds.length; i++) {
        beforeRet.push(state.storeById?.[item.merchantStoreIds[i]]?.name);
      }

      ret = beforeRet.join(' / ');
    }
    return ret;
  });

export const getServiceChargeAmounts = createSelector(merchantState, () => {
  const min = 0;
  const max = 40;
  const increment = 2.5;
  // 0% - 40% in 2.5% increments
  return Array.from({ length: (max - min) / increment + 1 }, (_, index) => {
    const percentage = index * increment + min;
    return { display: `${percentage}%`, value: percentage };
  });
});

export const getMerchantToEdit = createSelector(merchantState, (state: IMerchantState) => state.merchantToEdit);

export const getMerchantKycToEdit = createSelector(merchantState, getMerchantToEdit, (_state: IMerchantState, merchant: IMerchant) => {
  return merchant?.merchantKyc || ({} as IMerchantKyc);
});

export const getPaymentProvider = (provider: string) =>
  createSelector(
    merchantState,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => TransactionMethodsForTable?.[provider]
  );

export const getFilteringOptions = (filterOpts: FilterOptions) =>
  createSelector(merchantState, (state: IMerchantState) => {
    switch (filterOpts) {
      case FilterOptions.STORES: {
        const newObjArr = state.stores.map((item) => {
          return {
            id: item.merchantStoreId,
            title: item.name,
          };
        });
        return newObjArr;
      }
      case FilterOptions.MENUCATEGORIES: {
        const newObjArr = state.restaurantMenuCategories.map((item) => {
          return {
            id: item.restaurantMenuCategoryId,
            title: item.name,
          };
        });
        return newObjArr;
      }
      case FilterOptions.TRANSACTIONSTATUSES: {
        const newObjArr = Object.keys(TransactionStatusesForTableMap).map((item) => {
          return {
            id: item,
            title: TransactionStatusesForTableMap[item] as string,
          };
        });
        return newObjArr;
      }
      case FilterOptions.STAFFSTATUSES: {
        const newObjArr = StaffStatusesForTableArr.map((item) => {
          return {
            id: item.statusId,
            title: item.name,
          };
        });
        return newObjArr;
      }

      case FilterOptions.ORDERSTATUSES: {
        const newObjArr = OrderStatusesForTableArr.map((item) => {
          return {
            id: item.statusId,
            title: item.name,
          };
        });
        return newObjArr;
      }

      case FilterOptions.REWARDSTATUSES: {
        const newObjArr = state.restaurantMenuCategories.map((item) => {
          return {
            id: item.restaurantMenuCategoryId,
            title: item.name,
          };
        });
        return newObjArr;
      }

      case FilterOptions.USERSTATUSES: {
        const newObjArr = UserStatusesForTableArr.map((item) => {
          return {
            id: item.statusId,
            title: item.name,
          };
        });
        return newObjArr;
      }
    }
  });

export const getImportingStock = createSelector(merchantState, (state: IMerchantState) => state.importingStock);

export const getStoreTheme = createSelector(merchantState, (state: IMerchantState) => state.theme);

export const getAddonItems = createSelector(merchantState, (state: IMerchantState) => state.addonItems);

export const getPrinters = createSelector(merchantState, (state: IMerchantState) => state.printers);

export const hasPrinters = createSelector(getPrinters, (printers) => printers?.length > 0);

export const getPrintingConfig = createSelector(merchantState, (state: IMerchantState) => state.printingConfig);
