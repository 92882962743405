import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, exhaustMap, take, switchMap } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { CommonHttpErrorResponse } from '@app/state/app-state/actions';
import {
  GetStoreDataIntervalsSuccess,
  GetStoreDataIntervals,
  GetStoreByIdSuccess,
  GetStoreById,
  GetStoresSuccess,
  GetStores,
  GetStoreStats,
  GetStoreStatsSuccess,
  RemoveStore,
  GetDefaultStoreByMerchantId,
} from '@app/state/merchant-state/actions/merchant.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { Store } from '@ngrx/store';
import { NotificationService } from '@common/notifications/services/notification.service';
import { MerchantStoreApiService } from '@features/merchant/services/merchant-store-api/merchant-store-api.service';
import { IStore } from '../interfaces';
import { getCurrentRole } from '@app/state/app-state/selectors';
import { StatisticsTypes } from '@utils/constants';
import { getAdminSelectedMerchantId } from '@app/state/admin-state/admin.selectors';
import { IStoreStat } from '@app/state/app-state/interfaces';

// NOTE: This query now only returns one store as the concept of merchants having multiple stores is no longer required
const getStoresQuery = gql`
  query stores($id: UUID, $organization: Boolean, $statisticsType: String) {
    merchantStores(id: $id, organization: $organization, statisticsType: $statisticsType, orderBy: DATE_CREATED_DESC, first: 1) {
      nodes {
        merchantStoreId
        merchantId
        name
        totalAmount
        transactionsCount
        customersCount
        dateCreated
        location
      }
    }
  }
`;

const getStoreStatsQuery = gql`
  query getStoreStats($id: UUID, $type: String) {
    storeStats(id: $id, type: $type) {
      nodes {
        merchantId
        merchantStoreId
        customersCount
        totalAmount
        transactionsCount
        statType
      }
    }
  }
`;

const deleteStore = gql`
  mutation deleteMerchantStore($merchantStoreId: UUID) {
    deleteMerchantStore(input: { merchantStoreId: $merchantStoreId }) {
      boolean
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class StoreEffects {
  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store,
    private notification: NotificationService,
    private merchantStoreApiService: MerchantStoreApiService
  ) {}

  getStores$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStores),
      exhaustMap(() => {
        return combineLatest([this.store.select(getCurrentRole), this.store.select(getAdminSelectedMerchantId)]).pipe(
          take(1),
          exhaustMap(([currentRole, selectedMerchantId]) => {
            const variables: { id?: string; organization?: boolean; statisticsType?: string } = {};
            if (selectedMerchantId) {
              variables.id = selectedMerchantId;
              variables.organization = false;
            } else if (currentRole?.organizationId) {
              variables.id = currentRole?.organizationId;
              variables.organization = true;
            } else {
              variables.id = currentRole?.merchantId;
              variables.organization = false;
            }
            variables.statisticsType = StatisticsTypes.Weekly;
            return this.apollo.query<{ [id: string]: IStore[] }>({ query: getStoresQuery, variables: variables }).pipe(
              map((data: ApolloQueryResult<{ [id: string]: IStore[] }>) => {
                if (data.errors) throw data.errors;
                return data.data['merchantStores']['nodes'];
              }),
              map((payload: IStore[]) => GetStoresSuccess({ payload })),
              catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
            );
          })
        );
      })
    )
  );

  getStoreById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStoreById),
      switchMap((action) =>
        this.merchantStoreApiService.getStore(action.payload).pipe(
          map((store) => GetStoreByIdSuccess({ payload: store })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  getDefaultStoreByMerchantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDefaultStoreByMerchantId),
      switchMap((action) =>
        this.merchantStoreApiService.getDefaultStore(action.merchantId).pipe(
          map((store) => GetStoreByIdSuccess({ payload: store })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  getStoreDataIntervals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStoreDataIntervals),
      exhaustMap(() => {
        return of(
          GetStoreDataIntervalsSuccess({
            payload: [
              {
                id: '1',
                display: 'Weekly',
              },
              {
                id: '2',
                display: 'Monthly',
              },
              {
                id: '3',
                display: 'Yearly',
              },
            ],
          })
        );
      })
    )
  );

  getStoreStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStoreStats),
      exhaustMap((action) => {
        return this.apollo
          .query<IStoreStat>({ query: getStoreStatsQuery, variables: { id: action.payload.id, type: action.payload.type } })
          .pipe(
            map((data: ApolloQueryResult<IStoreStat>) => {
              if (data.errors?.length) throw data.errors;
              return data.data['storeStats']['nodes'][0];
            }),
            map((payload: IStoreStat) => GetStoreStatsSuccess({ payload: payload })),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  deleteStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RemoveStore),
      exhaustMap((action) => {
        return this.apollo.mutate<IStore>({ mutation: deleteStore, variables: { merchantStoreId: action.payload.merchantStoreId } }).pipe(
          map((data: ApolloQueryResult<IStore>) => {
            if (data.errors?.length) throw data.errors;
            return data;
          }),
          map(() => GetStores()),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );
}
