import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImportWizardComponent } from '@shared/import/containers/import-wizard/import-wizard.component';
import { Observable } from 'rxjs';
import { ImportWizardConfig } from '@shared/import/types/import-wizard-config.interface';

@Injectable({
  providedIn: 'root',
})
export class ImportService {
  constructor(private matDialog: MatDialog) {}

  openWizard(data: ImportWizardConfig): Observable<boolean> {
    const dialog = this.matDialog.open<ImportWizardComponent, ImportWizardConfig, boolean>(ImportWizardComponent, {
      width: '800px',
      panelClass: ['kody-dialog-container', 'kody-dialog--no-padding', 'kody-dialog--integrations'],
      data,
    });
    return dialog.beforeClosed();
  }
}
