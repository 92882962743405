<div *ngIf="value" class="image-upload__preview">
  <img [src]="imageSrc" [width]="width" [height]="height" class="image-upload__image" />
  <div class="image-upload__controls">
    <button mat-icon-button type="button" (click)="redo()"><fa-icon [icon]="icons.faRedo"></fa-icon></button>
    <button mat-icon-button type="button" (click)="clear()"><fa-icon [icon]="icons.faTrash"></fa-icon></button>
  </div>
</div>

<kody-file-upload
  #fileUpload
  [ngClass]="{ 'image-upload__input--set': value }"
  [placeholder]="placeholder"
  [fileTypes]="fileTypes"
  (fileSelected)="onFileSelected($event)"
  (fileLoaded)="onFileLoaded($event)"
></kody-file-upload>
