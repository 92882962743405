/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, exhaustMap, take, mergeMap } from 'rxjs/operators';
import { of, zip } from 'rxjs';
import { CommonHttpErrorResponse, UpdateTableState } from '@app/state/app-state/actions';
import { GetAdminNotifications, GetAdminNotificationsSuccess, CreateNotification } from '@app/state/admin-state/admin.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { IAdminNotification } from './interfaces';
import { getCurrentRole, getTableState } from '../app-state/selectors';
import { Store } from '@ngrx/store';

const getNotificationsQuery = gql`
  query getNotifications($filter: PushNotificationFilter, $first: Int, $offset: Int, $organizationId: UUID) {
    organizationNotifications(
      filter: $filter
      offset: $offset
      first: $first
      organizationId: $organizationId
      orderBy: DATE_CREATED_DESC
    ) {
      nodes {
        dateCreated
        dateTouched
        payload
        pushNotificationId
        status
        userId
      }
      totalCount
    }
  }
`;

const createPushNotification = gql`
  mutation createPushNotification($req: OrganizationNotificationModelInput) {
    addOrganizationNotification(input: { req: $req }) {
      pushNotification {
        userId
        status
        pushNotificationId
        pushDeviceId
        payload
        dateTouched
        dateCreated
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class AdminNotificationsEffects {
  constructor(private actions$: Actions, private apollo: Apollo, private store: Store) {}

  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAdminNotifications),
      exhaustMap(() => {
        return zip(this.store.select(getCurrentRole), this.store.select(getTableState(GetAdminNotifications()))).pipe(
          take(1),
          exhaustMap(([currentRole, tableState]) => {
            const variables: {
              organizationId?: string;
              offset?: number;
              filter?: { [id: string]: unknown };
            } = {
              offset: tableState.offset,
            };
            variables.organizationId = currentRole?.organizationId;
            if (tableState.filterObject) {
              variables.filter = tableState.filterObject;
            }
            return this.apollo.query<IAdminNotification[]>({ query: getNotificationsQuery, variables: variables }).pipe(
              map((data: ApolloQueryResult<IAdminNotification[]>) => {
                if (data.errors?.length) throw data.errors;
                return data;
              }),
              mergeMap((payload: ApolloQueryResult<IAdminNotification[]>) => {
                return [
                  GetAdminNotificationsSuccess({ payload: payload.data['organizationNotifications']['nodes'] as IAdminNotification[] }),
                  UpdateTableState({
                    payload: {
                      ...tableState,
                      length: payload.data['organizationNotifications']['totalCount'] as number,
                    },
                  }),
                ];
              }),
              catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
            );
          })
        );
      })
    )
  );

  createPushNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateNotification),
      exhaustMap((action) => {
        return this.apollo.mutate<IAdminNotification>({ mutation: createPushNotification, variables: { req: action.payload } }).pipe(
          map((data: ApolloQueryResult<IAdminNotification>) => {
            if (data.errors?.length) throw data.errors;
            return data;
          }),
          mergeMap(() => [GetAdminNotifications()]),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );
}
