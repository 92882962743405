import { Roles } from './constants';

export class Util {
  static constructFormData(obj: any): FormData {
    const ret = new FormData();
    for (const key of Object.keys(obj)) {
      if (!(obj[key] == null || typeof obj[key] == 'undefined')) {
        ret.append(key, obj[key]);
      }
    }
    return ret;
  }

  static downloadBlob(blob, fileName) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(new Blob([blob]));
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
}

export function getReduced(action) {
  const type = action?.payload?.roles[0]?.name;
  let roles = [];
  if (type === 'merchant_admin') {
    roles = action?.payload?.roles?.map((item) => {
      const newRole = { ...item };
      let salesVol;
      if (newRole?.salesVolThisMonth?.length > 4) {
        salesVol = newRole?.salesVolThisMonth?.slice(4)?.reduce((a, b, idx, arr) => {
          return {
            amount: a?.amount + b?.amount,
            delta: a?.delta + b?.delta,
            count: arr?.length,
          };
        });
        if (salesVol?.delta) {
          salesVol.delta /= salesVol.count;
        }
      }
      return {
        ...item,
        reducedOthers: salesVol,
      };
    });
  } else {
    roles = action?.payload?.roles?.map((item) => {
      const newRole = { ...item };
      let salesVol;
      if (newRole?.salesVolThisWeek?.length > 4) {
        salesVol = newRole?.salesVolThisWeek?.slice(4)?.reduce((a, b, idx, arr) => {
          return {
            amount: a?.amount + b?.amount,
            delta: a?.delta + b?.delta,
            count: arr?.length,
          };
        });
        if (salesVol?.delta) {
          salesVol.delta /= salesVol.count;
        }
      }
      return {
        ...item,
        reducedOthers: salesVol,
      };
    });
  }
  return roles;
}

export function getMerchantId(currentRole, merchId) {
  const newRole = { ...currentRole };
  if (newRole.merchantId === null) {
    newRole.merchantId = merchId;
  }
  return newRole;
}

export function isNil(value: unknown): boolean {
  return value === null || value === undefined;
}
