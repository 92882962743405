import { createSelector } from '@ngrx/store';
import { MatTableDataSource } from '@angular/material/table';
import { IAdminState, IAdminUser, IAdminNotification, IAdminReward } from './interfaces';
import { IStock, IStore } from '@app/state/merchant-state/interfaces';
import {
  FilterOptions,
  UserStatusesForTable,
  DefaultRewardTypes,
  DefaultRewardStatuses,
  DefaultEarnRewardCategories,
  DefaultMonetaryValueTypes,
  DefaultRewardTriggerLogic,
  DefaultRewardTriggerTypes,
  DefaultRewardTriggerPurchaseTypes,
} from '@utils/constants';

export interface AdminState {
  adminState: IAdminState;
}

const adminState = (state: AdminState) => state.adminState;

export const getAdminMerchantsStock = createSelector(
  adminState,
  (state: IAdminState) => new MatTableDataSource<IStock>(state.merchantsStock)
);
export const getAdminMerchantsStore = createSelector(
  adminState,
  (state: IAdminState) => new MatTableDataSource<IStore>(state.merchantStores)
);

export const getAdminUsers = createSelector(adminState, (state: IAdminState) => new MatTableDataSource<IAdminUser>(state.users));

export const getAdminUserToEdit = createSelector(adminState, (state: IAdminState) => state.userToEdit);

export const getAdminUserToAdd = createSelector(adminState, (state: IAdminState) => (state.addUser ? state.addUser : {}));

export const getOrganizationSettings = createSelector(adminState, (state: IAdminState) => state.organizationSettings);

export const getAdminStoresArr = createSelector(adminState, (state: IAdminState) => state.merchantStores);

export const getAdminMerchants = createSelector(adminState, (state: IAdminState) => state.merchants);

export const getMerchantStats = createSelector(
  adminState,
  (state: IAdminState) => state.merchants /////?
);

export const getAdminUsersStatusById = (item: string) =>
  createSelector(adminState, () => {
    return UserStatusesForTable?.[item];
  });

export const getAdminNotifications = createSelector(
  adminState,
  (state: IAdminState) => new MatTableDataSource<IAdminNotification>(state.notifications)
);

export const getAdminRewards = createSelector(adminState, (state: IAdminState) => new MatTableDataSource<IAdminReward>(state.rewards));

export const getAdminMerchantInfo = createSelector(adminState, (state: IAdminState) => state.merchantInfo);

export const getAdminSelectedMerchant = createSelector(adminState, (state: IAdminState) => state.selectedMerchant);

export const getAdminSelectedMerchantId = createSelector(adminState, (state: IAdminState) => state.selectedMerchantId);

export const getRewardTypes = createSelector(adminState, () => {
  return DefaultRewardTypes;
});

export const getRewardTypeById = (item: string) =>
  createSelector(adminState, () => {
    return DefaultRewardTypes.filter((i) => i.rewardType == item)[0].name;
  });

export const getRewardStatuses = createSelector(adminState, () => {
  return DefaultRewardStatuses;
});

export const getRewardStatusById = (item: string) =>
  createSelector(adminState, () => {
    return DefaultRewardStatuses.filter((i) => i.status == item)[0].name;
  });

export const getEarnRewardCategories = createSelector(adminState, () => {
  return DefaultEarnRewardCategories;
});

export const getEarnRewardCategoryById = (item: string) =>
  createSelector(adminState, () => {
    return DefaultEarnRewardCategories.filter((i) => i.rewardCategory == item)[0].name;
  });

export const getMonetaryValueTypes = createSelector(adminState, () => {
  return DefaultMonetaryValueTypes;
});

export const getRewardTriggerLogic = createSelector(adminState, () => {
  return DefaultRewardTriggerLogic;
});

export const getRewardTriggerTypes = createSelector(adminState, () => {
  return DefaultRewardTriggerTypes;
});

export const getRewardTriggerPurchaseTypes = createSelector(adminState, () => {
  return DefaultRewardTriggerPurchaseTypes;
});

export const getAdminStatuses = createSelector(adminState, (state: IAdminState) => state.adminStatuses);

export const getRewardToEdit = createSelector(adminState, (state: IAdminState) => (state.rewardToEdit ? state.rewardToEdit : {}));

export const getRewardCategoriesArr = createSelector(adminState, (state: IAdminState) => state.rewardCategories);

export const getNotificationPayloadTitle = (item: IAdminNotification) => createSelector(adminState, () => item?.payload?.title);

export const getNotificationPayloadBody = (item: IAdminNotification) => createSelector(adminState, () => item?.payload?.body);

export const getFilteringOptions = (filterOpts: FilterOptions) =>
  createSelector(adminState, (state: IAdminState) => {
    switch (filterOpts) {
      case FilterOptions.REWARDSTATUSES: {
        const newObjArr = state.rewardStatuses.map((item) => {
          return {
            id: item.statusId,
            title: item.name,
          };
        });
        return newObjArr;
      }
    }
  });

export const getUserStatuses = (user: IAdminUser) =>
  createSelector(adminState, () => {
    if (user.status == 'locked') {
      return 'Locked';
    } else if (user.status == 'unlocked' && user.emailConfirmed == true) {
      return 'Verified';
    } else {
      return 'Unverified';
    }
  });

export const getVersioning = createSelector(adminState, (state: IAdminState) => state?.versioning);
export const get3DSExemptionThreshold = createSelector(adminState, (state: IAdminState) => state?.threeDSExemptionThreshold);
