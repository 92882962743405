import { authHeaders } from './../core/authentication/constants/auth-headers.constant';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { pki, md, util } from 'node-forge';

import { from, throwError, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { v4 } from 'uuid';
const key = 'RSAKeyPair';

@Injectable({ providedIn: 'any' })
export class SecurityManagerService {
  private deviceId = '';
  constructor(private http: HttpClient) {}

  private getKey(): Observable<RSAPair> {
    const pair: string = sessionStorage.getItem(key);
    if (pair) {
      return of(JSON.parse(pair));
    } else {
      return throwError(pair);
    }
  }

  private createKey(): Promise<RSAPair> {
    return new Promise((resolve, reject) => {
      const rsa = pki.rsa;
      rsa.generateKeyPair({ bits: 2048, workers: 2 }, (err, keypair) => {
        if (!err) {
          const pair = {
            publicKeyPem: pki.publicKeyToPem(keypair.publicKey),
            privateKeyPem: pki.privateKeyToPem(keypair.privateKey),
          };
          this.saveKey(key, pair)
            .pipe(take(1))
            .subscribe(() => {
              resolve(pair);
            });
        } else {
          reject(err);
        }
      });
    });
  }

  private saveKey(key, rsaPair: RSAPair): Observable<boolean> {
    const value = JSON.stringify(rsaPair);
    return of(sessionStorage.setItem(key, value)).pipe(
      take(1),
      map(() => {
        return true;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  private getDeviceId(publicKey: string, userAgent: string) {
    return this.http.post<DeviceRegistration>(
      `${environment.baseApiEndpoint}/public/device/register`,
      { publicKey, userAgent },
      {
        headers: {
          [authHeaders.signedHeaderKey]: 'true',
        },
      }
    );
  }

  registerDevice(): Observable<DeviceWithPrivateKey> {
    return this.getKey().pipe(
      map((res) => {
        return res;
      }),
      catchError((err, caught) => {
        return from(this.createKey()).pipe(
          tap((pair) => {
            return caught;
          })
        );
      }),
      exhaustMap((res) => {
        return this.getDeviceId(res.publicKeyPem, window.navigator.userAgent).pipe(
          map((deviceReg) => {
            this.deviceId = deviceReg.deviceId;
            return {
              deviceId: deviceReg.deviceId,
              privateKey: res.privateKeyPem,
            };
          })
        );
      })
    );
  }

  signRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    // let signString = req.urlWithParams.replace(/(http:\/\/|https:\/\/)/, '');
    let signString = '';
    let headers: HttpHeaders;
    headers = req.headers.append('Nonce', `${v4()}`);
    if (this.deviceId) {
      headers = headers.append('DeviceId', this.deviceId);
    }
    const headerKeys = headers.keys().sort();
    for (const key of headerKeys) {
      signString += key.toLowerCase() + headers.get(key);
    }
    if (req.body) {
      signString += JSON.stringify(req.body);
    }
    return this.getKey().pipe(
      map((key) => {
        const privateKey = pki.privateKeyFromPem(key.privateKeyPem);
        const mdkey = md.sha256.create();
        mdkey.update(signString, 'utf8');
        const signature = util.encode64(privateKey.sign(mdkey));
        const signedHeaders = headers.append('Signature', signature);
        return req.clone({ headers: signedHeaders });
      }),
      catchError((err) => {
        return of(req);
      })
    );
  }
}

export type RSAPair = {
  publicKeyPem: string;
  privateKeyPem: string;
};

export type DeviceRegistration = {
  deviceId: string;
};

export type DeviceWithPrivateKey = {
  deviceId: string;
  privateKey: string;
};
