import { Component, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { WizardStep } from '@shared/wizard/types/wizard-step.type';
import { takeUntil } from 'rxjs/operators';
import { ImportWizardConfig } from '@shared/import/types/import-wizard-config.interface';
import { FileImportError, ImportError } from '@shared/import/types/import-error.interface';
import { FormControl } from '@ngneat/reactive-forms';

@Component({
  selector: 'kody-import-wizard',
  templateUrl: './import-wizard.component.html',
  styleUrls: ['./import-wizard.component.scss'],
})
export class ImportWizardComponent {
  step$ = new BehaviorSubject<WizardStep>('configuration');
  error$ = new BehaviorSubject<FileImportError>(null);

  fileControl = new FormControl<File>();

  constructor(@Inject(MAT_DIALOG_DATA) public config: ImportWizardConfig, private dialogRef: MatDialogRef<any>) {}

  import(): void {
    if (!this.fileControl.value) return;
    this.step$.next('verifying');

    const file = this.fileControl.value;
    const formData = new FormData();
    formData.append('file', file, file.name);

    this.config
      .import$(formData)
      .pipe(takeUntil(this.dialogRef.beforeClosed()))
      .subscribe({
        next: () => this.step$.next('success'),
        error: (error: HttpErrorResponse) => {
          const importError: ImportError = error.error.errors;
          const fileError = importError[file.name];

          this.error$.next(fileError);
          this.step$.next('failure');
        },
      });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  done(): void {
    this.dialogRef.close(true);
  }

  reconfigure(): void {
    this.step$.next('configuration');
  }
}
