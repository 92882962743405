import { Injectable } from '@angular/core';
import { ITableDisplayedColumn } from '@app/state/app-state/interfaces';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomCurrencyMaskConfig } from '../interfaces/custom-currency-mask-config.interface';
import { MerchantFacadeService } from './merchant-facade.service';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  constructor(private merchantFacadeService: MerchantFacadeService) {}

  getPathPrefix(): Observable<string> {
    return this.merchantFacadeService.getCurrentRole().pipe(
      take(1),
      map((role) => `/main/${this.merchantFacadeService.isAdminRole(role) ? `admin/merchant/${role?.organizationId}` : 'merchant'}`)
    );
  }

  getCustomCurrencyMaskConfig(): CustomCurrencyMaskConfig {
    return {
      align: 'left',
      allowNegative: false,
      allowZero: false,
      decimal: '.',
      precision: 2,
      prefix: '£ ',
      suffix: '',
      thousands: ',',
      nullable: true,
      min: 0.01,
      max: 5.0,
      inputMode: CurrencyMaskInputMode.NATURAL,
    };
  }
}
