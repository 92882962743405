import { createAction, props } from '@ngrx/store';
import { IStore } from '@app/state/merchant-state/interfaces';
import { TimeSlot } from '@features/merchant/interfaces/time-slot.interface';

const updateStore = createAction('[Merchant Store] Update store', props<{ storeId: string; store: Partial<IStore> }>());

const updateStoreSuccess = createAction('[Merchant Store API] Update store Success', props<{ payload: IStore }>());

const getTimeSlots = createAction('[Merchant Store] Get time slots', props<{ storeId: string }>());

const getTimeSlotsSuccess = createAction('[Merchant Store API] Get time slots Success', props<{ timeSlots: TimeSlot[] }>());

const updateTimeSlots = createAction('[Merchant Store] Update time slots', props<{ storeId: string; timeSlots: TimeSlot[] }>());

const updateTimeSlotsSuccess = createAction('[Merchant Store API] Update time slots Success', props<{ timeSlots: TimeSlot[] }>());

export const merchantStoreActions = {
  updateStore,
  updateStoreSuccess,
  getTimeSlots,
  getTimeSlotsSuccess,
  updateTimeSlots,
  updateTimeSlotsSuccess,
};
