import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntercomModule } from '@core/intercom/intercom.module';
import { FeatureFlagsModule } from '@core/feature-flags/feature-flags.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, IntercomModule, FeatureFlagsModule],
})
export class CoreModule {}
