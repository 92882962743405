import { AppVersioning, IAdminRewardTrigger, IAppSettings, Version } from './interfaces';
import { createAction, props } from '@ngrx/store';
import { IAdminUser, IAdminNotification, IAdminReward, IRewardTypes, Card } from '@app/state/admin-state/interfaces';
import { IStock, IStore, IStatus, IMenuCategory, IOfferType } from '@app/state/merchant-state/interfaces';
import { IMerchant, IMerchantStat } from '@app/state/app-state/interfaces';

export enum KodypayInnerActionAdminTypes {
  GetAdminTransactionsSuccess = '[Admin] Get Admin Transations Success',
  GetAdminMerchantsStockSuccess = '[Admin] Get Admin Merchants Stock Success',
  GetAdminMerchantsStoreSuccess = '[Admin] Get Admin Merchants Store Success',
  GetAdminUsersSuccess = '[Admin] Get Admin Users Success',
  UpdateAdminUserSuccess = '[Admin] Update Admin User Success',
  UpdateAdminUserStatusSuccess = '[Admin] Update Admin User Status Success',
  VerifyAdminUserSuccess = '[Admin] Verify Admin User Success',
  GetAdminUserByIdSuccess = '[Admin] Get Admin User By Id Success',
  GetAdminRewardByIdSuccess = '[Admin] Get Admin User By Id',
  GetAdminNotificationsSuccess = '[Admin] Get Admin Notifications Success',
  DeleteAdminNotificationsSuccess = '[Admin] Delete Admin Notifications Success ',
  GetAdminRewardsSuccess = '[Admin] Get Admin Rewards Success',
  UpdateAdminRewardsSuccess = '[Admin] Update Admin Rewards Success',
  DeleteAdminRewardsSuccess = '[Admin] Delete Admin Rewards Success',
  GetAdminMerchantsSuccess = '[Admin] Get Admin Merchants Success',
  GetMerchantStatsSuccess = '[Admin] Get Merchant Stats',
  GetAdminStockStatusesSuccess = '[Admin] Get Admin Stock Statuses Success',
  GetAdminRestaurantCategoriesSuccess = '[Admin] Get Admin Restaurant Categories Success',
  GetAdminMerchantStockByIdSuccess = '[Admin] Get Admin Merchant Stock By Id Success',
  GetAdminOfferTypesSuccess = '[Admin] Get Admin Offer Types Success',
  CreateUpdateAdminStockSuccess = '[Admin] Create/Update Admin Stock Success Success',
  GetRewardTypesSuccess = '[Admin] Get Reward Types Success',
  GetRewardStatusesSuccess = '[Admin] Get Reward Statuses Success',
  AddTriggerToReward = '[Admin] Add Reward Trigger Success',
  RemoveTriggerFromReward = '[Admin] Remove Reward Trigger Success',
  CreateUpdateRewardSuccess = '[Admin] Create/Update Reward Success',
  GetAdminUsersStatusesSuccess = '[Admin] Get Admin Users Statuses Success',
  GetOrganizationSettingsSuccess = '[Admin] GetOrganizationSettingsSuccess',
  UpdateOrganizationSettingsSuccess = '[Admin] UpdateOrganizationSettingsSuccess',
  ApproveMerchantKycSuccess = '[Admin] ApproveMerchantKycSuccess',
  DeclineMerchantKycSuccess = '[Admin] DeclineMerchantKycSuccess',
  GetAdminMerchantByIdSuccess = '[Admin] GetAdminMerchantByIdSuccess',
  GetVersioningSuccess = '[Admin] Get versioning Success',
  GetVersioningFailure = '[Admin] Get versioning Failure',
  UpdateVersionSuccess = '[Admin] Update version Success',
  Get3DSExemptionThresholdSuccess = '[Admin] Get 3DS exemption threshold Success',
  Get3DSExemptionThresholdFailure = '[Admin] Get 3DS exemption threshold Failure',
  Update3DSExemptionThresholdSuccess = '[Update] Get 3DS exemption threshold Success',
  Update3DSExemptionThresholdFailure = '[Update] Get 3DS exemption threshold Failure',
}

export enum KodypayHttpActionAdminTypes {
  GetAdminTransactions = '[Admin] GetAdminTransactions',
  GetAdminMerchantsStock = '[Admin] GetAdminMerchantsStock',
  GetAdminMerchantsStore = '[Admin] GetAdminMerchantsStore',
  GetAdminUsers = '[Admin] GetAdminUsers',
  GetAdminUserById = '[Admin] GetAdminUserById',
  GetAdminRewardById = '[Admin] GetAdminRewardById',
  UpdateAdminUser = '[Admin] UpdateAdminUser',
  CreateAdminUser = '[Admin] CreateAdminUser',
  UpdateAdminUserStatus = '[Admin] UpdateAdminUserStatus',
  VerifyAdminUser = '[Admin] VerifyAdminUser',
  UpdateAdminUserCard = '[Admin] UpdateAdminUserCard',
  GetAdminNotifications = '[Admin] GetAdminNotifications',
  DeleteAdminNotifications = '[Admin] DeleteAdminNotifications',
  GetAdminRewards = '[Admin] GetAdminRewards',
  UpdateAdminRewards = '[Admin] UpdateAdminRewards',
  DeleteAdminRewards = '[Admin] DeleteAdminRewards',
  GetAdminMerchants = '[Admin] GetAdminMerchants',
  GetMerchantStats = '[Admin] GetMerchantStats',
  GetAdminMerchantById = '[Admin] GetAdminMerchantById',
  GetAdminStockStatuses = '[Admin] GetAdminStockStatuses',
  GetAdminRestaurantCategories = '[Admin] GetAdminRestaurantCategories',
  GetAdminMerchantStockById = '[Admin] GetAdminMerchantStockById',
  GetAdminOfferTypes = '[Admin] GetAdminOfferTypes',
  CreateUpdateAdminStock = '[Admin] CreateUpdateAdminStock',
  GetAdminStores = '[Admin] GetAdminStores',
  GetAdminServiceChargeAmount = '[Admin] GetAdminServiceChargeAmount',
  GetRewardTypes = '[Admin] GetRewardTypes',
  GetRewardStatuses = '[Admin] GetRewardStatuses',
  CreateUpdateReward = '[Admin] CreateUpdateReward',
  GetAdminUsersStatuses = '[Admin] GetAdminUsersStatuses',
  GetOrganizationSettings = '[Admin] GetOrganizationSettings',
  UpdateOrganizationSettings = '[Admin] UpdateOrganizationSettings',
  ApproveMerchantKyc = '[Admin] ApproveMerchantKyc',
  ApproveMerchantSignup = '[Admin] ApproveMerchantSignup',
  RejectMerchantSignup = '[Admin] RejectMerchantSignup',
  DeclineMerchantKyc = '[Admin] DeclineMerchantKyc',
  CreateNotification = '[Admin] CreateNotification',
  AccessUserData = '[Admin] AccessUserData',
  GetVersioning = '[Admin] Get versioning',
  UpdateVersion = '[Admin] Update version',
  Get3DSExemptionThreshold = '[Admin] Get 3DS exemption threshold',
  Update3DSExemptionThreshold = '[Admin] Update 3DS exemption threshold',
}

//HTTP
export const GetAdminTransactions = createAction(KodypayHttpActionAdminTypes.GetAdminTransactions);
export const GetAdminMerchantsStock = createAction(KodypayHttpActionAdminTypes.GetAdminMerchantsStock);
export const GetAdminMerchantsStore = createAction(KodypayHttpActionAdminTypes.GetAdminMerchantsStore);
export const GetAdminUsers = createAction(KodypayHttpActionAdminTypes.GetAdminUsers);
export const GetAdminUserById = createAction(KodypayHttpActionAdminTypes.GetAdminUserById, props<{ payload: string }>());
export const GetAdminRewardById = createAction(KodypayHttpActionAdminTypes.GetAdminRewardById, props<{ payload: string }>());
export const UpdateAdminUser = createAction(KodypayHttpActionAdminTypes.UpdateAdminUser, props<{ payload: IAdminUser }>());
export const CreateAdminUser = createAction(KodypayHttpActionAdminTypes.CreateAdminUser, props<{ payload: IAdminUser }>());
export const AccessUserData = createAction(KodypayHttpActionAdminTypes.AccessUserData, props<{ userId: string; postCode: string }>());
export const UpdateAdminUserStatus = createAction(
  KodypayHttpActionAdminTypes.UpdateAdminUserStatus,
  props<{ payload: string[]; status: string }>()
);
export const VerifyAdminUser = createAction(KodypayHttpActionAdminTypes.VerifyAdminUser, props<{ payload: string[] }>());
export const UpdateAdminUserCard = createAction(
  KodypayHttpActionAdminTypes.UpdateAdminUserCard,
  props<{ payload: Card; status: string }>()
);
export const GetAdminNotifications = createAction(KodypayHttpActionAdminTypes.GetAdminNotifications);
export const DeleteAdminNotifications = createAction(
  KodypayHttpActionAdminTypes.DeleteAdminNotifications,
  props<{ payload: string[]; adminId: string }>()
);
export const GetAdminRewards = createAction(KodypayHttpActionAdminTypes.GetAdminRewards);
export const UpdateAdminRewards = createAction(
  KodypayHttpActionAdminTypes.UpdateAdminRewards,
  props<{ payload: string[]; status: string }>()
);
export const DeleteAdminRewards = createAction(KodypayHttpActionAdminTypes.DeleteAdminRewards, props<{ payload: string[] }>());
export const GetAdminMerchants = createAction(KodypayHttpActionAdminTypes.GetAdminMerchants);
export const GetMerchantStats = createAction(KodypayHttpActionAdminTypes.GetMerchantStats, props<{ payload: { id; type } }>());
export const GetAdminMerchantById = createAction(KodypayHttpActionAdminTypes.GetAdminMerchantById, props<{ payload: string }>());
export const GetAdminStockStatuses = createAction(KodypayHttpActionAdminTypes.GetAdminStockStatuses);
export const GetAdminRestaurantCategories = createAction(KodypayHttpActionAdminTypes.GetAdminRestaurantCategories);
export const GetAdminMerchantStockById = createAction(KodypayHttpActionAdminTypes.GetAdminMerchantStockById, props<{ payload: string }>());
export const GetAdminOfferTypes = createAction(KodypayHttpActionAdminTypes.GetAdminOfferTypes);
export const CreateUpdateAdminStock = createAction(KodypayHttpActionAdminTypes.CreateUpdateAdminStock, props<{ payload: IStock }>());
export const GetRewardTypes = createAction(KodypayHttpActionAdminTypes.GetRewardTypes);
export const AddTriggerToReward = createAction(KodypayInnerActionAdminTypes.AddTriggerToReward, props<{ payload: IAdminRewardTrigger }>());
export const RemoveTriggerFromReward = createAction(KodypayInnerActionAdminTypes.RemoveTriggerFromReward, props<{ payload: number }>());
export const GetRewardStatuses = createAction(KodypayHttpActionAdminTypes.GetRewardStatuses);
export const CreateUpdateReward = createAction(KodypayHttpActionAdminTypes.CreateUpdateReward, props<{ payload: IAdminReward }>()); //////
//export const CreateUpdateStore = createAction(KodypayHttpActionMerchantTypes.CreateUpdateStore, props<{ payload: IStore }>()); ///////
export const GetAdminUsersStatuses = createAction(KodypayHttpActionAdminTypes.GetAdminUsersStatuses);
export const GetOrganizationSettings = createAction(KodypayHttpActionAdminTypes.GetOrganizationSettings);
export const UpdateOrganizationSettings = createAction(
  KodypayHttpActionAdminTypes.UpdateOrganizationSettings,
  props<{ payload: IAppSettings }>()
);
export const ApproveMerchantKyc = createAction(
  KodypayHttpActionAdminTypes.ApproveMerchantKyc,
  props<{ payload: string; merchantId: string }>()
);
export const DeclineMerchantKyc = createAction(
  KodypayHttpActionAdminTypes.DeclineMerchantKyc,
  props<{ payload: string; merchantId: string }>()
);
export const ApproveMerchantSignup = createAction(KodypayHttpActionAdminTypes.ApproveMerchantSignup, props<{ merchant: IMerchant }>());
export const RejectMerchantSignup = createAction(KodypayHttpActionAdminTypes.RejectMerchantSignup, props<{ merchant: IMerchant }>());
export const CreateNotification = createAction(KodypayHttpActionAdminTypes.CreateNotification, props<{ payload: IAdminNotification }>());

export const GetAdminStores = createAction(KodypayHttpActionAdminTypes.GetAdminStores);
export const GetAdminServiceChargeAmount = createAction(KodypayHttpActionAdminTypes.GetAdminServiceChargeAmount);

export const GetVersioning = createAction(KodypayHttpActionAdminTypes.GetVersioning);
export const UpdateVersion = createAction(KodypayHttpActionAdminTypes.UpdateVersion, props<{ version: Version }>());

export const Get3DSExemptionThreshold = createAction(KodypayHttpActionAdminTypes.Get3DSExemptionThreshold);
export const Update3DSExemptionThreshold = createAction(
  KodypayHttpActionAdminTypes.Update3DSExemptionThreshold,
  props<{ threeDSExemptionThreshold: number }>()
);

//DATA
export const GetAdminMerchantsStockSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminMerchantsStockSuccess,
  props<{ payload: IStock[] }>()
);
export const GetAdminMerchantsSToreSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminMerchantsStoreSuccess,
  props<{ payload: IStore[] }>()
);
export const GetAdminUsersSuccess = createAction(KodypayInnerActionAdminTypes.GetAdminUsersSuccess, props<{ payload: IAdminUser[] }>());
export const GetAdminUserByIdSuccess = createAction(KodypayInnerActionAdminTypes.GetAdminUserByIdSuccess, props<{ payload: IAdminUser }>());
export const GetAdminRewardByIdSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminRewardByIdSuccess,
  props<{ payload: IAdminReward }>()
);
export const UpdateAdminUserSuccess = createAction(KodypayInnerActionAdminTypes.UpdateAdminUserSuccess, props<{ payload: IAdminUser }>());
export const UpdateAdminUserStatusSuccess = createAction(
  KodypayInnerActionAdminTypes.UpdateAdminUserStatusSuccess,
  props<{ payload: string[]; status: string }>()
);
export const VerifyAdminUserSuccess = createAction(KodypayInnerActionAdminTypes.VerifyAdminUserSuccess, props<{ payload: string[] }>());
export const GetAdminNotificationsSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminNotificationsSuccess,
  props<{ payload: IAdminNotification[] }>()
);
export const DeleteAdminNotificationsSuccess = createAction(
  KodypayInnerActionAdminTypes.DeleteAdminNotificationsSuccess,
  props<{ payload: string[]; adminID: string }>()
);
export const GetAdminRewardsSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminRewardsSuccess,
  props<{ payload: IAdminReward[] }>()
);
export const UpdateAdminRewardsSuccess = createAction(
  KodypayInnerActionAdminTypes.UpdateAdminRewardsSuccess,
  props<{ payload: string[]; status: string }>()
);
export const DeleteAdminRewardsSuccess = createAction(
  KodypayInnerActionAdminTypes.DeleteAdminRewardsSuccess,
  props<{ payload: string[] }>()
);
export const GetAdminMerchantsSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminMerchantsSuccess,
  props<{ payload: IMerchant[] }>()
);
export const GetMerchantStatsSuccess = createAction(
  KodypayInnerActionAdminTypes.GetMerchantStatsSuccess,
  props<{ payload: IMerchantStat }>()
);
export const GetAdminMerchantByIdSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminMerchantByIdSuccess,
  props<{ payload: IMerchant }>()
);
export const GetAdminStockStatusesSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminStockStatusesSuccess,
  props<{ payload: IStatus[] }>()
);
export const GetAdminRestaurantCategoriesSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminRestaurantCategoriesSuccess,
  props<{ payload: IMenuCategory[] }>()
);
export const GetAdminMerchantStockByIdSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminMerchantStockByIdSuccess,
  props<{ payload: IStock }>()
);
export const GetAdminOfferTypesSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminOfferTypesSuccess,
  props<{ payload: IOfferType[] }>()
);
export const CreateUpdateAdminStockSuccess = createAction(
  KodypayInnerActionAdminTypes.CreateUpdateAdminStockSuccess,
  props<{ payload: IStock }>()
);
export const GetRewardTypesSuccess = createAction(KodypayInnerActionAdminTypes.GetRewardTypesSuccess, props<{ payload: IRewardTypes[] }>());
export const GetRewardStatusesSuccess = createAction(
  KodypayInnerActionAdminTypes.GetRewardStatusesSuccess,
  props<{ payload: IStatus[] }>()
);
export const AddTriggerToRewardSuccess = createAction(
  KodypayInnerActionAdminTypes.AddTriggerToReward,
  props<{ payload: IAdminRewardTrigger }>()
);
export const RemoveTriggerFromRewardSuccess = createAction(
  KodypayInnerActionAdminTypes.RemoveTriggerFromReward,
  props<{ payload: number }>()
);
export const CreateUpdateRewardSuccess = createAction(
  KodypayInnerActionAdminTypes.CreateUpdateRewardSuccess,
  props<{ payload: IAdminReward }>()
);
export const GetAdminUsersStatusesSuccess = createAction(
  KodypayInnerActionAdminTypes.GetAdminUsersStatusesSuccess,
  props<{ payload: IStatus[] }>()
);
export const GetOrganizationSettingsSuccess = createAction(
  KodypayInnerActionAdminTypes.GetOrganizationSettingsSuccess,
  props<{ payload: IAppSettings }>()
);
export const UpdateOrganizationSettingsSuccess = createAction(
  KodypayInnerActionAdminTypes.UpdateOrganizationSettingsSuccess,
  props<{ payload: IAppSettings }>()
);
export const ApproveMerchantKycSuccess = createAction(
  KodypayInnerActionAdminTypes.ApproveMerchantKycSuccess,
  props<{ payload: boolean }>()
);
export const DeclineMerchantKycSuccess = createAction(
  KodypayInnerActionAdminTypes.DeclineMerchantKycSuccess,
  props<{ payload: boolean }>()
);

export const GetVersioningSuccess = createAction(KodypayInnerActionAdminTypes.GetVersioningSuccess, props<{ versioning: AppVersioning }>());
export const GetVersioningFailure = createAction(KodypayInnerActionAdminTypes.GetVersioningFailure);

export const UpdateVersionSuccess = createAction(KodypayInnerActionAdminTypes.UpdateVersionSuccess, props<{ version: Version }>());

export const Get3DSExemptionThresholdSuccess = createAction(
  KodypayInnerActionAdminTypes.Get3DSExemptionThresholdSuccess,
  props<{ threeDSExemptionThreshold: number }>()
);
export const Get3DSExemptionThresholdFailure = createAction(
  KodypayInnerActionAdminTypes.Get3DSExemptionThresholdFailure,
  props<{ message: string }>()
);
export const Update3DSExemptionThresholdSuccess = createAction(
  KodypayInnerActionAdminTypes.Update3DSExemptionThresholdSuccess,
  props<{ threeDSExemptionThreshold: number }>()
);
export const Update3DSExemptionThresholdFailure = createAction(
  KodypayInnerActionAdminTypes.Update3DSExemptionThresholdFailure,
  props<{ message: string }>()
);
