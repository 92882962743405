import { MerchantAppState, MerchantState, merchantStoreKey, stockTableKey } from '@features/merchant/store/models/merchant-state.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { stateTableSelectors } from '@shared/state-table/selectors/state-table.selectors';

const selectMerchantState = createFeatureSelector<MerchantAppState, MerchantState>(merchantStoreKey);
const selectStockTableState = createSelector(selectMerchantState, (state) => state[stockTableKey]);

const tableSelectors = stateTableSelectors(selectStockTableState, 'name');

const canReorder = createSelector(
  tableSelectors.items,
  tableSelectors.total,
  tableSelectors.filters,
  tableSelectors.search,
  (items, total, filters, search) => {
    // enable item sorting for entire sets of results that are filtered by menu category, and not by a search query
    return items?.length === total && total > 1 && filters?.some(({ key, value }) => key === 'menuCategoryId' && value) && !search;
  }
);

export const stockTableSelectors = {
  ...tableSelectors,
  canReorder,
};
