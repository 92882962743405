import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons/faSyncAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { WizardStatus } from '@shared/wizard/types/wizard-status.type';

@Component({
  selector: 'kody-wizard-status',
  templateUrl: './wizard-status.component.html',
  styleUrls: ['./wizard-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardStatusComponent {
  @Input() status: WizardStatus;
  @Input() title: string;
  @Input() message: string;
  @Input() actionText: string;

  @Output() action = new EventEmitter<void>();

  icons: Record<WizardStatus, IconProp> = {
    verifying: faSyncAlt,
    success: faCheck,
    failure: faTimes,
  };
}
