/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AccessUserData, CreateAdminUser, VerifyAdminUser } from './admin.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  GetAdminUsers,
  GetAdminUsersSuccess,
  GetAdminUserById,
  GetAdminUserByIdSuccess,
  UpdateAdminUser,
  UpdateAdminUserStatus,
} from '@app/state/admin-state/admin.actions';
import { map, catchError, exhaustMap, mergeMap, take } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { CommonHttpErrorResponse, ResetPassword, UpdateTableState } from '@app/state/app-state/actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { IAdminUser } from './interfaces';
import { getTableState, getCurrentRole } from '../app-state/selectors';
import { Store } from '@ngrx/store';
import { getAdminSelectedMerchantId } from './admin.selectors';
import { Router } from '@angular/router';

const getAdminUsersQuery = gql`
  query adminUsers($filter: UsersWithCardFilter, $first: Int, $offset: Int) {
    usersWithCards(filter: $filter, offset: $offset, first: $first) {
      nodes {
        addressLineOne
        addressLineTwo
        cards
        city
        dateOfBirth
        email
        firstName
        fullName
        isClickCollect
        isAliPay
        isTransactionBatching
        lastName
        phone
        postCode
        status
        userId
        totalAmount
        phoneConfirmed
        emailConfirmed
        cardsCount
      }
      totalCount
    }
  }
`;

const updateAdminUserQuery = gql`
  mutation updateAdminUser($req: UsersWithCardInput) {
    updateAdminUser(input: { req: $req }) {
      usersWithCard {
        fullName
      }
    }
  }
`;

const updateAdminUserStatus = gql`
  mutation updateAdminUserStatus($userIds: [UUID], $userStatus: String) {
    updateAdminUserStatus(input: { userIds: $userIds, userStatus: $userStatus }) {
      usersWithCards {
        status
        userId
      }
    }
  }
`;

const verifyAdminUser = gql`
  mutation verifyAdminUser($userIds: [UUID]) {
    verifyAdminUser(input: { userIds: $userIds }) {
      usersWithCards {
        emailConfirmed
        phoneConfirmed
        userId
      }
    }
  }
`;

const createAdminUserQuery = gql`
  mutation createAdminUser($req: AdminUserModelInput) {
    createAdminUser(input: { req: $req }) {
      user {
        addressLineOne
        addressLineTwo
        dateOfBirth
        email
        firstName
        lastName
        phone
        postCode
        userId
        city
      }
    }
  }
`;

const accessUserData = gql`
  mutation accessUserData($userId: UUID, $postCode: String) {
    accessUserData(input: { userId: $userId, postCode: $postCode }) {
      usersWithCard {
        userId
        dateOfBirth
        firstName
        lastName
        phone
        postCode
        cards
        fullName
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class AdminUsersEffects {
  constructor(private actions$: Actions, private apollo: Apollo, private router: Router, private store: Store) {}

  getStaff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAdminUsers),
      exhaustMap(() => {
        return combineLatest([
          this.store.select(getCurrentRole),
          this.store.select(getTableState(GetAdminUsers())),
          this.store.select(getAdminSelectedMerchantId),
        ]).pipe(
          take(1),
          exhaustMap(([currentRole, tableState, selectedMerchantId]) => {
            const variables: {
              id?: string;
              organization?: boolean;
              offset?: number;
              first?: number;
              filter?: { [id: string]: unknown };
            } = {
              offset: tableState.offset,
              first: tableState.first,
            };
            if (selectedMerchantId) {
              variables.id = selectedMerchantId;
              variables.organization = false;
            } else if (currentRole?.organizationId) {
              variables.id = currentRole?.organizationId;
              variables.organization = true;
            } else {
              variables.id = currentRole?.merchantId;
              variables.organization = false;
            }
            if (tableState.filterObject) {
              variables.filter = tableState.filterObject;
            }
            return this.apollo.query<IAdminUser[]>({ query: getAdminUsersQuery, variables: variables }).pipe(
              map((data: ApolloQueryResult<IAdminUser[]>) => {
                if (data.errors?.length) throw data.errors;
                return data;
              }),
              mergeMap((payload: ApolloQueryResult<IAdminUser[]>) => [
                GetAdminUsersSuccess({ payload: payload?.data['usersWithCards']?.['nodes'] as IAdminUser[] }),
                UpdateTableState({
                  payload: {
                    ...tableState,
                    length: payload?.data['usersWithCards']?.['totalCount'] as number,
                  },
                }),
              ]),
              catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
            );
          })
        );
      })
    )
  );

  getAdminUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAdminUserById),
      exhaustMap((action) => {
        return this.apollo
          .mutate<IAdminUser>({ mutation: getAdminUsersQuery, variables: { filter: { userId: { equalTo: action.payload } } } })
          .pipe(
            map((data: ApolloQueryResult<IAdminUser>) => {
              if (data.errors?.length) throw data.errors;
              return data.data['usersWithCards']?.['nodes']?.[0] as IAdminUser;
            }),
            map((payload: IAdminUser) => GetAdminUserByIdSuccess({ payload })),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  updateAdminUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateAdminUser),
      exhaustMap((action) => {
        return this.apollo.query<IAdminUser>({ query: updateAdminUserQuery, variables: { req: action.payload } }).pipe(
          map((data: ApolloQueryResult<IAdminUser>) => {
            if (data.errors?.length) throw data.errors;
            return data.data['usersWithCards']?.['nodes']?.[0] as IAdminUser;
          }),
          map(() => {
            void this.router.navigate(['/main', 'admin', 'users']);
            return GetAdminUserByIdSuccess({ payload: {} });
          }),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  createAdminUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateAdminUser),
      exhaustMap((action) => {
        return this.apollo.mutate<IAdminUser>({ mutation: createAdminUserQuery, variables: { req: action.payload } }).pipe(
          map((data: ApolloQueryResult<IAdminUser>) => {
            if (data.errors?.length) throw data.errors;
            return data;
          }),
          mergeMap((payload: ApolloQueryResult<IAdminUser>) => {
            void this.router.navigate(['/main', 'admin', 'users']);
            return [
              GetAdminUserById({ payload: payload?.data?.['createAdminUser']?.['user']?.userId as string }),
              ResetPassword({ payload: action.payload?.email, navigate: false }),
            ];
          }),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  accessUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccessUserData),
      exhaustMap((action) => {
        return this.apollo
          .mutate<IAdminUser>({ mutation: accessUserData, variables: { userId: action.userId, postCode: action.postCode } })
          .pipe(
            map((data: ApolloQueryResult<IAdminUser>) => {
              if (data.errors?.length) throw data.errors;
              return data;
            }),
            mergeMap((payload: ApolloQueryResult<IAdminUser>) => {
              return [GetAdminUserByIdSuccess({ payload: payload?.data?.['accessUserData']?.['usersWithCard'] as IAdminUser })];
            }),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  updateAdminUserStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateAdminUserStatus),
      exhaustMap((action) => {
        return this.apollo
          .mutate<{ [id: string]: string[] }>({
            mutation: updateAdminUserStatus,
            variables: { userIds: action.payload, userStatus: action.status },
          })
          .pipe(
            map((data: ApolloQueryResult<{ [id: string]: string[] }>) => {
              if (data.errors) throw data.errors;
              return data.data['updateAdminUserStatus']?.['usersWithCards'] as IAdminUser;
            }),
            map(() => GetAdminUsers()),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  verifyAdminUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VerifyAdminUser),
      exhaustMap((action) => {
        return this.apollo.mutate<{ [id: string]: string[] }>({ mutation: verifyAdminUser, variables: { userIds: action.payload } }).pipe(
          map((data: ApolloQueryResult<{ [id: string]: string[] }>) => {
            if (data.errors) throw data.errors;
            return data.data['verifyAdminUser']?.['usersWithCards'] as IAdminUser;
          }),
          map(() => GetAdminUsers()),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );
}
