<mat-form-field class="w-50 pr-1">
  <mat-label>{{ column?.displayName }} - From</mat-label>
  <input matInput [matDatepicker]="dpFrom" (dateChange)="writeValue()" [(ngModel)]="from" />
  <mat-datepicker-toggle matSuffix [for]="dpFrom"></mat-datepicker-toggle>
  <mat-datepicker #dpFrom></mat-datepicker>
</mat-form-field>
<mat-form-field class="w-50 pl-1">
  <mat-label>{{ column?.displayName }} - To</mat-label>
  <input matInput [matDatepicker]="dpTo" [(ngModel)]="to" (dateChange)="writeValue()" />
  <mat-datepicker-toggle matSuffix [for]="dpTo"></mat-datepicker-toggle>
  <mat-datepicker #dpTo></mat-datepicker>
</mat-form-field>
