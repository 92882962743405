import { createAction, props } from '@ngrx/store';
import { FeatureFlags } from '@core/feature-flags/types/feature-flags.type';

const startWatching = createAction('[Feature Flags] Start watching', props<{ initialFlags: FeatureFlags }>());
const userIdentified = createAction('[Feature Flags] User Identified');
const userForgotten = createAction('[Feature Flags] User Forgotten');
const flagsChanged = createAction('[Feature Flags] Flags Changed', props<{ flags: FeatureFlags }>());

export const featureFlagsActions = {
  userIdentified,
  userForgotten,
  startWatching,
  flagsChanged,
};
