import { Component, ChangeDetectionStrategy, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ITableDisplayedColumn } from '@app/state/app-state/interfaces';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'filter-exists-control',
  templateUrl: './filter-exists-control.component.html',
  styleUrls: ['./filter-exists-control.component.scss'],
  host: { class: 'd-flex w-100 align-items-baseline' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterExistsControlComponent {
  @Input()
  column: ITableDisplayedColumn;
  @Output() changed: EventEmitter<any> = new EventEmitter();

  writeValue(value: boolean): void {
    const isNullObj = {};
    isNullObj[this.column.id] = { isNull: true };
    const equalToObj = {};
    equalToObj[this.column.id] = { equalTo: '' };
    if (value) {
      const val = {
        not: {
          or: [isNullObj, equalToObj],
        },
      };
      this.changed.emit(val);
    } else {
      const val = {
        or: [isNullObj, equalToObj],
      };
      this.changed.emit(val);
    }
  }
}
