import { authHeaders } from './../core/authentication/constants/auth-headers.constant';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, exhaustMap, filter } from 'rxjs/operators';
import { currentSession } from '@app/state/app-state/selectors';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Session } from '@app/state/app-state/interfaces';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'any' })
export class SessionService {
  constructor(private store: Store, private http: HttpClient) {}

  currentSession(): Observable<Session> {
    return this.store.select(currentSession).pipe(
      take(1),
      exhaustMap((session) => {
        const now = Math.round(new Date().getTime() / 1000) + 35;
        if (session?.accessTokenExp <= now) {
          return this.http
            .get<Session>(`${environment.apiEndpoint}/refreshToken`, {
              headers: {
                'refresh-token': session.refreshToken,
                [authHeaders.signedHeaderKey]: 'true',
              },
            })
            .pipe(
              exhaustMap((ret) =>
                this.store.select(currentSession).pipe(
                  filter((session) => {
                    const now = Math.round(new Date().getTime() / 1000) + 35;
                    return session?.accessTokenExp > now;
                  })
                )
              )
            );
        } else {
          return of(session);
        }
      })
    );
  }
}
