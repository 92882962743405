/// <reference types="jwt-decode" />
import { IAppState, ITableState, SessionData } from '@app/state/app-state/interfaces';
import { createReducer, on, Action } from '@ngrx/store';
import {
  LoginSuccess,
  ChangeMainPath,
  PingSuccess,
  LoginError,
  UpdateTableState,
  ResetTableState,
  Logout,
  UpdateToken,
  UpdateCurrentRole,
  SetSessionUser,
  SetSession,
  PrintQr,
  Ping,
} from '@app/state/app-state/actions';
import { DataTypes } from '@utils/constants';
import { getReduced } from '@utils/utils';

const initalAppState: IAppState = {
  session: {},
  selectedMainPath: null,
  tableStates: {},
  roles: [],
  selectedRole: null,
};

function getInitalState(): IAppState {
  const savedState = getSavedState();
  if (savedState?.user) {
    return savedState;
  } else {
    return initalAppState;
  }
}

function getSavedState(): IAppState {
  const savedState = sessionStorage.getItem('appState');
  if (savedState) {
    return JSON.parse(savedState) as IAppState;
  }
  return null;
}

const appReducer = createReducer(
  getInitalState(),
  on(LoginSuccess, (state, action) => {
    return {
      ...state,
      sessionToken: action.payload as string,
    };
  }),
  on(ChangeMainPath, (state, action) => {
    return {
      ...state,
      selectedMainPath: action.payload,
    };
  }),
  on(Ping, (state, action) => {
    return {
      ...state,
      pingPassed: false,
    };
  }),
  on(PingSuccess, (state, action) => {
    const filteredRoles = action.payload.roles.filter((el) => {
      if (el.name == 'merchant_employee') return false;
      if (el.name == 'user') return false;
      return true;
    });
    const innerAction = {
      ...action,
      payload: {
        ...action.payload,
        roles: filteredRoles,
      },
    };
    const roles = getReduced(innerAction);
    return {
      ...state,
      roles: roles,
      selectedRole: state?.selectedRole ? { ...state?.selectedRole } : roles?.[0],
      pingPassed: true,
    };
  }),

  on(LoginError, (state) => {
    return {
      ...state,
      sessionData: new SessionData(),
    };
  }),
  on(ResetTableState, (state, { payload }) => {
    return {
      ...state,
      tableStates: payload,
    };
  }),
  on(UpdateTableState, (state, action) => {
    const newTableStates: { [id: string]: ITableState } = {
      ...state.tableStates,
    };
    let filterObj = null;
    const queryObjects: { [id: string]: any }[] = action.payload.searchableColumns?.map((item) => {
      const ret = {};
      if (item.dataType == DataTypes.UUID) {
        ret[item.id] = { equalTo: action.payload.searchQuery };
      } else {
        ret[item.id] = { includesInsensitive: action.payload.searchQuery };
      }
      return ret;
    });
    const filterObjects: { [id: string]: any }[] = [];
    for (const key in action?.payload?.filterValuesMap) {
      filterObjects.push(action?.payload?.filterValuesMap[key]);
    }
    if (queryObjects?.length > 0 && action.payload.searchQuery) {
      let andArr = [];
      andArr.push({ or: queryObjects });
      if (filterObjects?.length) {
        andArr = [...andArr, ...filterObjects];
      }
      filterObj = {
        and: andArr,
      };
    } else {
      if (filterObjects?.length) {
        filterObj = {
          and: filterObjects,
        };
      }
    }
    newTableStates[action.payload.id.type] = {
      ...action.payload,
      filterObject: filterObj,
      filterValuesMap: action.payload.filterValuesMap,
    };
    return {
      ...state,
      tableStates: newTableStates,
    };
  }),
  on(Logout, () => {
    sessionStorage.removeItem('appState');
    return initalAppState;
  }),
  on(PrintQr, (state, action) => {
    return {
      ...state,
      qrCodes: action.payload,
    };
  }),

  on(UpdateToken, (state, action) => {
    return {
      ...state,
      sessionToken: action.payload,
    };
  }),
  on(UpdateCurrentRole, (state, action) => {
    const role = state.roles.find((item) => item.userRoleId == action?.payload?.userRoleId);
    return {
      ...state,
      selectedRole: role,
    };
  }),
  on(SetSessionUser, (state, action) => {
    const filteredRoles = action.payload.roles.filter((el) => {
      if (el.name == 'merchant_employee') return false;
      if (el.name == 'user') return false;
      return true;
    });
    const innerAction = {
      ...action,
      payload: {
        ...action.payload,
        roles: filteredRoles,
      },
    };
    const roles = getReduced(innerAction);

    return {
      ...state,
      user: {
        ...action.payload,
        roles: roles,
      },
      roles: roles,
      selectedRole: state?.selectedRole ? { ...state?.selectedRole } : roles?.[0],
    };
  }),
  on(SetSession, (state, action) => {
    const session = { ...state.session };
    session.accessToken = action?.payload?.accessToken ? action?.payload?.accessToken : session.accessToken;
    session.accessTokenExp = action?.payload?.accessTokenExp ? action?.payload?.accessTokenExp : session.accessTokenExp;
    session.refreshToken = action?.payload?.refreshToken ? action?.payload?.refreshToken : session.refreshToken;
    return {
      ...state,
      session: session,
    };
  })
);

export function reducer(state: IAppState | undefined, action: Action): IAppState {
  return appReducer(state, action);
}
