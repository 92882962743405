import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, exhaustMap, withLatestFrom, tap, mergeMap, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '@env/environment';
import {
  Ping,
  UpdateCurrentRole,
  GetMerchantByRoleSuccess,
  CommonHttpErrorResponse,
  PingSuccess,
  PingError,
  GetMerchantByRole,
  SetSession,
  SetSessionUser,
  PrintQr,
  ResetPassword,
  SetPassword,
  ResetPasswordCMS,
} from '@app/state/app-state/actions';
import { IMerchant, IPingResult } from '@app/state/app-state/interfaces';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { Store } from '@ngrx/store';
import { appState } from './selectors';
import { Router } from '@angular/router';
import { SecurityManagerService } from '@providers/security-manager.service';

const getMerchantByIdQuery = gql`
  query getMerchantById($merchantId: UUID) {
    allMerchants(filter: { merchantId: { equalTo: $merchantId } }) {
      nodes {
        merchantId
        name
        baseTransactionOnOpeningHours
        dateCreated
        enableStockCounts
        isRestaurant
        isRetail
        separateTakeOutEatIn
        serviceCharge
        serviceChargeAmount
        salesVolumeMonthly
        salesVolThisMonth
        customersCount
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class ApiEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store,
    private router: Router,
    private securityManagerService: SecurityManagerService
  ) {}

  getMerchantById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCurrentRole, GetMerchantByRole),
      exhaustMap((action) => {
        if (action.payload.merchantId) {
          return this.apollo
            .query<{ [id: string]: IMerchant }>({ query: getMerchantByIdQuery, variables: { merchantId: action.payload.merchantId } })
            .pipe(
              map((data: ApolloQueryResult<{ [id: string]: IMerchant }>) => {
                if (data.errors?.length) throw data.errors;
                return data.data['allMerchants']['nodes'][0] as IMerchant;
              }),
              map((payload: IMerchant) => GetMerchantByRoleSuccess({ payload })),
              catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
            );
        } else {
          return of(GetMerchantByRoleSuccess({ payload: null }));
        }
      })
    )
  );

  ping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Ping),
      switchMap(() => {
        return this.securityManagerService.registerDevice().pipe(
          take(1),
          exhaustMap(() => {
            return this.http.post(environment.apiEndpoint + '/ping', {}).pipe(
              mergeMap((payload: IPingResult) => [PingSuccess({ payload })]),
              catchError((error) => of(PingError(error)))
            );
          })
        );
      })
    )
  );

  resetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResetPassword),
        switchMap((action) => {
          return this.http.post(environment.baseApiEndpoint + '/public/password/requestResetLink', { email: action.payload }).pipe(
            map(() => (action.navigate ? this.router.navigate(['/login']) : null)),
            catchError((error) => of(PingError(error)))
          );
        })
      ),
    { dispatch: false }
  );

  resetPasswordCms$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ResetPasswordCMS),
        switchMap((action) => {
          return this.http
            .post(environment.baseApiEndpoint + '/public/password/requestCmsResetLink', { email: action.payload as string })
            .pipe(
              map(() => (action.navigate ? this.router.navigate(['/login']) : null)),
              catchError((error) => of(PingError(error)))
            );
        })
      ),
    { dispatch: false }
  );

  print$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PrintQr),
        map((action) => {
          if (action?.payload?.length) {
            setTimeout(() => {
              window.print();
            }, 500);
          }
        })
      ),
    { dispatch: false }
  );

  storeState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Ping, UpdateCurrentRole, SetSession, SetSessionUser),
        withLatestFrom(this.store.select(appState)),
        tap(([, state]) => {
          const stateToSave = { ...state };
          delete stateToSave.tableStates;
          sessionStorage.setItem('appState', JSON.stringify(stateToSave));
        })
      ),
    { dispatch: false }
  );
}
