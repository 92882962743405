<div class="mat-elevation-z0 mb-3 w-100 d-flex flex-column">
  <div class="pb-4 d-flex justify-content-between">
    <div class="d-flex">
      <mat-form-field class="search-input" *ngIf="searchable">
        <input #filter matInput (keyup)="keyUpSubject.next($event)" placeholder="Search" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <div class="h-100 d-flex align-items-center" *ngIf="filterable">
        <kody-flat-button class="mr-4" [buttonConfig]="filterBtnConfig" [matMenuTriggerFor]="appMenu"></kody-flat-button>

        <mat-menu #appMenu="matMenu">
          <ng-template matMenuContent>
            <mat-selection-list #shoes [ngModel]="tableState?.selectedFilters" (ngModelChange)="changeSelectedFilters($event)">
              <mat-list-option
                [disabled]="tableState?.selectedFilters?.length == 3 && !tableState?.selectedFilters.includes(filter)"
                *ngFor="let filter of tableState?.filters"
                [value]="filter"
              >
                {{ filter?.displayName }}
              </mat-list-option>
            </mat-selection-list>
          </ng-template>
        </mat-menu>
      </div>
    </div>

    <div class="h-100 d-flex align-items-center">
      <ng-container *ngTemplateOutlet="tableActionsTemplate"></ng-container>
      <div class="w-25" *ngIf="exportable">
        <kody-flat-button [buttonConfig]="exportBtnConfig" (click)="downloadFile(dataSource.filteredData)"> </kody-flat-button>
      </div>
    </div>
  </div>

  <div class="d-flex flex-wrap">
    <div *ngFor="let selectedFilter of tableState?.selectedFilters" class="d-flex mb-4">
      <button mat-icon-button (click)="removeFilter(selectedFilter)">
        <mat-icon>clear</mat-icon>
      </button>
      <p class="filter-title">{{ selectedFilter.displayName }}:</p>
      <ng-container [ngSwitch]="selectedFilter?.filterType">
        <ng-template [ngSwitchCase]="filterTypes.EXISTS">
          <filter-exists-control [column]="selectedFilter" (changed)="filterChanged($event, selectedFilter)"> </filter-exists-control>
        </ng-template>
        <ng-template [ngSwitchCase]="filterTypes.IN">
          <filter-multiple-select-control [column]="selectedFilter" (changed)="filterChanged($event, selectedFilter)">
          </filter-multiple-select-control>
        </ng-template>
        <ng-template [ngSwitchCase]="filterTypes.CONTAINEDBY">
          <filter-multiple-select-control [column]="selectedFilter" (changed)="filterChanged($event, selectedFilter)">
          </filter-multiple-select-control>
        </ng-template>
        <ng-template [ngSwitchCase]="filterTypes.FROMTO">
          <filter-from-to-control [column]="selectedFilter" (changed)="filterChanged($event, selectedFilter)"> </filter-from-to-control>
        </ng-template>
        <ng-template [ngSwitchCase]="filterTypes.DATERANGE">
          <filter-date-range-control [column]="selectedFilter" (changed)="filterChanged($event, selectedFilter)">
          </filter-date-range-control>
        </ng-template>
        <ng-template [ngSwitchCase]="filterTypes.DATETIMERANGE">
          <filter-date-range-control [column]="selectedFilter" (changed)="filterChanged($event, selectedFilter)">
          </filter-date-range-control>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <div class="d-block bg-white bdr-10">
    <table
      mat-table
      #list="cdkDropList"
      [dataSource]="dataSource"
      class="w-100 drop-list"
      multiTemplateDataRows
      (cdkDropListDropped)="drop($event)"
      cdkDropList
      [cdkDropListData]="dataSource.data"
    >
      <ng-container [matColumnDef]="column.id" *ngFor="let column of tableState?.displayedColumns">
        <ng-template [ngIf]="!column?.hideColumn">
          <ng-template [ngIf]="column.custom" [ngIfElse]="builtIn">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container *ngTemplateOutlet="customHeaderCellTemplate; context: { $implicit: column }"> </ng-container>
            </th>

            <td mat-cell *matCellDef="let element">
              <ng-container *ngTemplateOutlet="customCellTemplate; context: { $implicit: column, element: element }"> </ng-container>
            </td>

            <td mat-footer-cell *matFooterCellDef="let element">
              <ng-container *ngTemplateOutlet="customFooterCellTemplate; context: { $implicit: column }"> </ng-container>
            </td>
          </ng-template>

          <ng-template [ngIf]="column.footer" [ngIfElse]="builtIn">
            <td mat-footer-cell *matFooterCellDef="let element">
              <ng-container *ngTemplateOutlet="customFooterCellTemplate; context: { $implicit: column }"> </ng-container>
            </td>
          </ng-template>

          <ng-template #builtIn>
            <ng-template [ngIf]="column.currency" [ngIfElse]="default">
              <th mat-header-cell *matHeaderCellDef>{{ column.displayName }}</th>
              <td mat-cell *matCellDef="let element">
                {{ element[column.id] | currency: 'GBP':'symbol-narrow':'1.2-2' }}
              </td>
              <td mat-footer-cell *matFooterCellDef="let element"></td>
            </ng-template>

            <ng-template #default>
              <ng-template [ngIf]="column.select">
                <!-- Table master/header checkbox -->
                <th mat-header-cell *matHeaderCellDef class="pt-2">
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                  >
                  </mat-checkbox>
                </th>

                <!-- Table body checkbox -->
                <td mat-cell *matCellDef="let row" class="pt-2">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)"
                  >
                  </mat-checkbox>
                </td>
                <td mat-footer-cell *matFooterCellDef="let element"></td>
              </ng-template>

              <!-- Draggable -->
              <ng-template [ngIf]="draggable" [ngIfElse]="plain">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-icon *ngIf="column.drag">drag_handle</mat-icon>
                  {{ column.displayName }}
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  cdkDragLockAxis="y"
                  cdkDragHandle
                  cdkDrag
                  [cdkDragData]="element"
                  cdkDragRootElement=".mat-row"
                >
                  <mat-icon *ngIf="column.drag">drag_handle</mat-icon>
                  <span [ngClass]="{ ellipsis: column.ellipsis }">
                    {{ element[column.id] }}
                  </span>
                  <span
                    class="d-inline-block overflow-hidden font-weight-bold"
                    (click)="copyToClipboard($event, element[column.id])"
                    *ngIf="column.ellipsis"
                  >
                    Copy
                    <fa-icon [icon]="faCopy"></fa-icon>
                  </span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let element"></td>
              </ng-template>

              <ng-template #plain>
                <th mat-header-cell *matHeaderCellDef>{{ column.displayName }}</th>
                <td mat-cell *matCellDef="let element">
                  <span [ngClass]="{ ellipsis: column.ellipsis }">
                    {{ element[column.id] }}
                  </span>
                  <span
                    class="d-inline-block overflow-hidden font-weight-bold"
                    (click)="copyToClipboard($event, element[column.id])"
                    *ngIf="column.ellipsis"
                  >
                    Copy
                    <fa-icon [icon]="faCopy"></fa-icon>
                  </span>
                </td>
                <td mat-footer-cell *matFooterCellDef="let element"></td>
              </ng-template>
            </ng-template>
          </ng-template>
        </ng-template>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableState?.displayedColumnNames"></tr>
      <tr mat-row *matRowDef="let row; columns: tableState?.displayedColumnNames" (click)="onHandleRowClick(row)"></tr>

      <ng-container *ngIf="footer">
        <tr mat-footer-row *matFooterRowDef="tableState?.displayedColumnNames"></tr>
      </ng-container>
    </table>

    <mat-paginator
      class="bdr-20"
      *ngIf="pagination"
      [pageSizeOptions]="[5, 10, 20, 100]"
      (page)="pageChanged($event)"
      showFirstLastButtons
      [length]="tableState?.length"
      [pageSize]="tableState?.first"
    >
    </mat-paginator>
  </div>
</div>
