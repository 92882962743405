<div class="button-container">
  <div class="spinner-container" *ngIf="loading">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
  <button mat-button class="ml-2" type="button" color="light" (click)="fileUpload.click()" [disabled]="loading">Import</button>
</div>

<form class="d-none">
  <input #fileUpload type="file" name="file" [accept]="fileTypes" (change)="onFileSelected($event)" />
</form>
