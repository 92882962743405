import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

interface IMEError {
  message: string;
  httpResponseCode: number;
  errorCode: number;
}

@Component({
  selector: 'error-modal',
  templateUrl: 'error-modal.component.html',
})
export class ErrorModalComponent {
  message = 'Please try again later!';
  constructor(public dialogRef: MatDialogRef<ErrorModalComponent>, @Inject(MAT_DIALOG_DATA) public error: Error) {
    if (this.error && this.error instanceof HttpErrorResponse) {
      if (this.error.error && this.error.error.message) {
        this.message = this.error.error.message;
        console.error(this.message);
      } else if (this.error.status == 401) {
        this.message = 'Your session has expired. Please sign in again!';
      }
      console.error(this.message);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
