import { IAppState } from '@app/state/app-state/interfaces';
import { TimeSlot } from '@features/merchant/interfaces/time-slot.interface';
import { PrintingTemplate } from '@features/integrations/interfaces/printer/printing-template.interface';
import { IMenuCategory, IStock } from '@app/state/merchant-state/interfaces';
import { PrintingIntegration } from '@features/integrations/interfaces/integration.interface';
import { StateTableState } from '@shared/state-table/interfaces/state-table.interfaces';
import { MerchantStaff } from '@features/merchant/interfaces/merchant-staff.interface';

export const merchantStoreKey = 'merchant';
export const merchantStaffTableKey = 'merchantStaffTable';
export const printingTemplateTableKey = 'printingTemplateTable';
export const stockTableKey = 'stockTable';

export interface MerchantState {
  store: MerchantStoreState;
  printingTemplateDetails: PrintingTemplateDetailsState;
  [merchantStaffTableKey]: StateTableState<MerchantStaff>;
  [printingTemplateTableKey]: StateTableState<PrintingTemplate>;
  [stockTableKey]: StateTableState<IStock>;
}

export interface MerchantAppState extends IAppState {
  [merchantStoreKey]: MerchantState;
}

export interface MerchantStoreState {
  timeSlots?: TimeSlot[];
}

export interface PrintingTemplateDetailsState {
  initialising: boolean;
  template: PrintingTemplate | null;
  menuCategories: IMenuCategory[];
  stockItems: IStock[];
  printingIntegrations: PrintingIntegration[];
}
