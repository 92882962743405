import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Logout, PingSuccess } from '@app/state/app-state/actions';
import { map, switchMap } from 'rxjs/operators';
import { FeatureFlagService } from '@core/feature-flags/services/feature-flag/feature-flag.service';
import { featureFlagsActions } from '@core/feature-flags/store/actions/feature-flags.actions';

@Injectable()
export class FeatureFlagsEffects {
  constructor(private actions$: Actions, private featureFlagService: FeatureFlagService) {}

  watch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureFlagsActions.startWatching),
      switchMap(() => this.featureFlagService.flagChanges$()),
      map((flags) => featureFlagsActions.flagsChanged({ flags }))
    )
  );

  identify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PingSuccess),
      switchMap(({ payload }) =>
        this.featureFlagService.identify({
          name: payload.fullName,
          key: `cms:${payload.userId}`,
          custom: {
            merchantId: payload.roles[0].merchantId,
          },
        })
      ),
      map(() => featureFlagsActions.userIdentified())
    )
  );

  forget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Logout),
      switchMap(() => this.featureFlagService.identify({ anonymous: true })),
      map(() => featureFlagsActions.userForgotten())
    )
  );
}
