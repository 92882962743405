import { Component, Input } from '@angular/core';

@Component({
  selector: 'snackbar',
  template: `<span>
    {{ text }}
  </span>`,
})
export class SnackbarComponent {
  @Input() text: string;
}
