<ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
<router-outlet></router-outlet>

<ng-container *ngFor="let qr of qrCodes | async">
  <div class="printme">
    <div class="scanner-barcode-container page-break">
      <kody-qr-preview [url]="qr"></kody-qr-preview>
    </div>
  </div>
  <div class="pagebreak"></div>
</ng-container>
