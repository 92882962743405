import { Component, ContentChild, TemplateRef, Input, ChangeDetectionStrategy, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, of, Subject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { UpdateTableState } from '@app/state/app-state/actions';
import { ITableState, ITableDisplayedColumn } from '@app/state/app-state/interfaces';
import { map, take, exhaustMap, debounceTime, distinctUntilChanged, takeUntil, toArray } from 'rxjs/operators';
import { CustomCellDirective } from './kodypay-custom-cell.directive';
import { CustomHeaderDirective } from './kodypay-custom-header-cell.directive';
import { TableActionsDirective } from './kodypay-table-actions.directive';
import { CustomFooterDirective } from './kodypay-custom-footer-cell.directive';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { FilterTypes } from '@utils/constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { IFlatBtnConfig } from '@common/kodypay-flat-button/kodypay-flat-button.component';
import { faFilter, faShareSquare, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'kodypay-table',
  templateUrl: './kodypay-table.component.html',
  styleUrls: ['./kodypay-table.component.scss'],
  host: { class: 'd-flex w-100' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KodypayTableComponent implements OnDestroy {
  readonly faCopy = faCopy;
  filterTypes = FilterTypes;
  keyUpSubject = new Subject<Event>();
  private onDestroySubject = new Subject<void>();
  exportBtnConfig: IFlatBtnConfig = {
    color: 'basic',
    text: 'Export',
    icon: faShareSquare,
    responsive: true,
  };
  filterBtnConfig: IFlatBtnConfig = {
    color: 'basic',
    icon: faFilter,
  };
  wrapFilters: boolean;
  @ContentChild(CustomCellDirective, { read: TemplateRef }) customCellTemplate;
  @ContentChild(CustomHeaderDirective, { read: TemplateRef })
  customHeaderCellTemplate;
  @ContentChild(TableActionsDirective, { read: TemplateRef })
  tableActionsTemplate;
  @ContentChild(CustomFooterDirective, { read: TemplateRef })
  customFooterCellTemplate;
  @Input() tableState: ITableState;
  @Input() dataSource: MatTableDataSource<any>;
  @Input() searchable = true;
  @Input() filterable = true;
  @Input() draggable = false;
  @Input() pagination = true;
  @Input() dateRange = true;
  @Input() footer = false;
  @Input() exportable = false;
  selection = new SelectionModel<any>(true, []);
  @Output() rowClickOutput: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor(public store: Store, private clipboard: Clipboard) {
    this.keyUpSubject
      .pipe(
        map((event) => (event.target as HTMLInputElement).value),
        debounceTime(300),
        distinctUntilChanged(),
        map((search) => {
          this.store.dispatch(
            UpdateTableState({
              payload: {
                ...this.tableState,
                offset: 0,
                searchQuery: search,
              },
            })
          );
          this.store.dispatch(this.tableState.id);
        }),
        takeUntil(this.onDestroySubject)
      )
      .subscribe();
  }

  onHandleRowClick(row: any): void {
    if (this.tableState.hasClickableRows.length) {
      this.rowClickOutput.emit(row);
    }
  }

  pageChanged(pageEvent: PageEvent): void {
    this.store.dispatch(
      UpdateTableState({
        payload: {
          ...this.tableState,
          id: this.tableState.id,
          length: pageEvent.length,
          offset: pageEvent.pageIndex * pageEvent.pageSize,
          first: pageEvent.pageSize,
          displayedColumnNames: this.tableState.displayedColumnNames,
          displayedColumns: this.tableState.displayedColumns,
        },
      })
    );

    this.store.dispatch(this.tableState.id);
  }

  ngOnDestroy(): void {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  copyToClipboard(event: Event, id: string): void {
    event.stopPropagation();
    this.clipboard.copy(id);
  }

  changeSelectedFilters(event: ITableDisplayedColumn[]): void {
    let shouldReload = false;
    if (event?.length < this.tableState?.selectedFilters?.length) shouldReload = true;
    if (event.length < 4) {
      this.store.dispatch(
        UpdateTableState({
          payload: {
            ...this.tableState,
            selectedFilters: event,
            filterValuesMap: this.cleanUpFilterValuesMap(event),
          },
        })
      );
      if (shouldReload) this.store.dispatch(this.tableState.id);
    }
  }

  removeFilter(filter: ITableDisplayedColumn): void {
    const newFilters = this.tableState.selectedFilters.filter((item) => item.id != filter.id);
    this.store.dispatch(
      UpdateTableState({
        payload: {
          ...this.tableState,
          selectedFilters: newFilters,
          filterValuesMap: this.cleanUpFilterValuesMap(newFilters),
        },
      })
    );
    this.store.dispatch(this.tableState.id);
  }

  filterChanged(event, selectedFilter: ITableDisplayedColumn): void {
    const newMap = { ...this.tableState?.filterValuesMap };
    if (!event) {
      delete newMap[selectedFilter?.id];
    } else {
      newMap[selectedFilter?.id] = event;
    }
    this.store.dispatch(
      UpdateTableState({
        payload: {
          ...this.tableState,
          filterValuesMap: newMap,
        },
      })
    );
    this.store.dispatch(this.tableState.id);
  }

  private cleanUpFilterValuesMap(filters: ITableDisplayedColumn[]): {
    [id: string]: any;
  } {
    const newMap = {};

    for (const col of filters) {
      if (this.tableState?.filterValuesMap?.[col.id]) newMap[col.id] = this.tableState.filterValuesMap[col.id];
    }

    return newMap;
  }

  drop(event: CdkDragDrop<any>): void {
    const newArr = [...event.container.data];
    moveItemInArray(newArr, event.previousIndex, event.currentIndex);
    this.dataSource.data = newArr;
    this.store.dispatch({
      type: this.tableState.orderActionId,
      payload: newArr,
    });
  }

  async downloadFile(data: any): Promise<void> {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = [];
    const headerDisplayName = [];
    this.tableState.exportableColumns.map((item) => {
      header.push(item.id);
    });
    this.tableState.exportableColumns.map((item) => {
      headerDisplayName.push(item.displayName);
    });

    from<any[]>(data)
      .pipe(
        exhaustMap((row) => {
          return from(header).pipe(
            map((fieldName) => {
              if (this.tableState.exportableColumns[header.indexOf(fieldName)].exportableOpts.exportableDataFunction) {
                return this.tableState.exportableColumns[header.indexOf(fieldName)].exportableOpts
                  .exportableDataFunction(row)
                  .pipe(take(1));
              } else {
                return of(JSON.stringify(row[fieldName], replacer)).pipe(take(1));
              }
            }),
            exhaustMap((v) => v),
            toArray(),
            map((cols) => cols.join(','))
          );
        }),
        toArray(),
        map((rows) => {
          rows.unshift(headerDisplayName.join(','));
          const csvArray = rows.join('\r\n');
          const blob = new Blob([csvArray], { type: 'text/csv' });
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);

          a.href = url;
          a.download = 'myFile.csv';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
          return null;
        }),
        take(1)
      )
      .subscribe();
  }
}
