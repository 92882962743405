/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action } from '@ngrx/store';
import { FilterTypes, FilterOptions, QRCodeTypes } from '@utils/constants';
import { Observable } from 'rxjs';
import {
  IMerchantSalesVolumeMonthly,
  IMerchantSalesVolumeThisMonth,
  IOrganizationSalesVolumeToday,
  IOrganizationSalesVolumeThisWeek,
} from '../merchant-state/interfaces';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export interface LoginResponse {
  jwtToken: string;
  userData: User;
}

export interface SidenavItem {
  id: string;
  label: string;
  icon: IconDefinition;
  iconColor?: string;
  route?: string;
  action?: Action;
  restaurant?: boolean;
  children?: SidenavItem[];
  featureFlag?: string;
}

export interface SideMenu {
  basePath: string;
  items: SidenavItem[];
  commonItems?: SidenavItem[];
}

export interface IRole {
  isRestaurant?: boolean;
  isRetail?: boolean;
  merchantId?: string;
  merchantName?: string;
  name?: string;
  organizationId?: string;
  organizationName?: string;
  storeId?: string;
  storeName?: string;
  userId?: string;
  userRoleId?: string;
  salesVolumeMonthly?: IMerchantSalesVolumeMonthly[];
  salesVolThisMonth?: IMerchantSalesVolumeThisMonth[];
  salesVolumeToday?: IOrganizationSalesVolumeToday[];
  salesVolThisWeek?: IOrganizationSalesVolumeThisWeek[];
  bestSellingMerchantThisWeel?: string;
  amountThisWeel?: number;
  avgAmountThisWeek?: number;
  transactionsThisWeekDelta?: number;
  numberOfCustomersThisMonth?: number;
  customersCount?: number;
  numberOfCustomersThisMonthDelta?: number;
  totalNumberOfStores?: number;
  reducedOthers?: IOrganizationSalesVolumeThisWeek;
}

export interface User {
  userId?: string;
  deviceId?: string;
  fullName?: string;
  roles?: IRole[];
  id?: string;
  firstName?: string;
  email?: string;
  emailVerified?: boolean;
  lastName?: string;
  birthDate?: string;
  phone?: string;
  phoneVerified?: boolean;
}

export class SessionData {}

export interface NavItem {
  path: string;
  label: string;
}

export interface IAppState {
  session?: Session;
  user?: User;
  selectedMainPath?: NavItem;
  tableStates?: { [id: string]: ITableState };
  roles?: IRole[];
  selectedRole?: IRole;
  pingPassed?: boolean;
  qrCodes?: string[];
}

export interface ITableState {
  id: Action;
  orderActionId?: string;
  searchQuery?: string;
  length: number;
  offset: number;
  hasClickableRows?: ITableDisplayedColumn[];
  first: number;
  displayedColumns: ITableDisplayedColumn[];
  displayedColumnNames: string[];
  filters?: ITableDisplayedColumn[];
  searchableColumns?: ITableDisplayedColumn[];
  selectedFilters?: ITableDisplayedColumn[];
  filterValuesMap?: { [id: string]: any };
  filterObject?: { [id: string]: any };
  droppable: boolean;
  exportableColumns?: ITableDisplayedColumn[];
}

export interface IExportableOptions {
  exportable?: boolean;
  exportableDataFunction?: (data: any) => Observable<string>;
}

export interface ITableDisplayedColumn {
  id: string;
  displayName?: string;
  footer?: boolean;
  custom?: boolean;
  clickable?: boolean;
  currency?: boolean;
  ellipsis?: boolean;
  select?: boolean;
  filterable?: boolean;
  filterType?: FilterTypes;
  dataType?: string;
  searchable?: boolean;
  options?: FilterOptions;
  drag?: boolean;
  hideColumn?: boolean;
  exportableOpts?: IExportableOptions;
}

export interface LoginRequest {
  phone: string;
  password: string;
}

export interface IMerchant {
  merchantId?: string;
  name?: string;
  dateCreated?: Date;
  description?: string;
  enableStockCounts?: boolean;
  baseTransactionOnOpeningHours?: boolean;
  separateTakeOutEatIn?: boolean;
  serviceCharge?: boolean;
  serviceChargeAmount?: string;
  isRestaurant?: boolean;
  isRetail?: boolean;
  merchantKyc?: IMerchantKyc;
  salesVolumeMonthly?: IMerchantSalesVolumeMonthly[];
  salesVolThisMonth?: IMerchantSalesVolumeThisMonth[];
  salesVolumeToday?: IOrganizationSalesVolumeToday[];
  salesVolThisWeek?: IOrganizationSalesVolumeThisWeek[];
  customersCount?: number;
  transactionsCount?: number;
  totalAmount?: number;
  statType?: string;
  numberOfKycs?: number;
  email?: string;
  approved?: boolean;
  bestSellingMerchantThisWeek?: string;
  amountThisWeek?: number;
  avgAmountThisWeek?: number;
  transactionsThisWeekDelta?: number;
  numberOfCustomersThisMonth?: number;
  totalNumberOfCustomers?: number;
  numberOfCustomersThisMonthDelta?: number;
  totalNumberOfStores?: number;
  enableVatClickCollect?: boolean;
  enableBagCharge?: boolean;
  bagChargePrice?: string;
  bagChargeNetPrice?: string;
  enableVatExemption?: boolean;
  location?: string;
  organizationId?: string;
  supportedPaymentMethods?: string[];
  themingEnabled?: boolean;
}

export interface IPingResult {
  deviceId: string;
  fullName: string;
  roles: IRole[];
  userId: string;
}

export interface Session {
  accessToken?: string;
  accessTokenExp?: number;
  refreshToken?: string;
}

export interface IUploadFile {
  dataBase64: string;
  fileName: string;
  mimeType: string;
}

export interface IMerchantKyc {
  merchantId?: string;
  merchantKycId?: string;
  isApproved?: boolean;
  isLatest?: boolean;
  dateCreated?: Date;
  companyName?: string;
  companyRegistrationNumber?: string;
  directorName?: string;
  directorDateOfBirth?: string;
  directorAddress?: string;
  proofOfIdentification?: IUploadFile;
  bankAccountSortCode?: string;
  bankAccountNumber?: string;
  proofOfFinancialControl?: IUploadFile;
  businessModelSummarySheet?: IUploadFile;
  businessModelSummary?: string;
  companiesHouseDocumentationLink?: string;
  companyContactName?: string;
  companyContactEmail?: string;
  isPendingReview?: boolean;
}

export interface IMerchantStat {
  merchantId?: string;
  transactionsCount?: number;
  customersCount?: number;
  totalAmount?: number;
  statType?: string;
}

export interface IStoreStat {
  merchantStoreId?: string;
  transactionsCount?: number;
  customersCount?: number;
  totalAmount?: number;
  statType?: string;
}

export interface QRCode {
  type: QRCodeTypes;
  data: QrData;
}

export interface QrData {
  table?: string;
  storeId: string;
}

export interface SetPasswordRequest {
  email: string;
  password: string;
  resetKey: string;
}
