import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientType, Version } from '@app/state/admin-state/interfaces';
import { Observable } from 'rxjs';
import { EnvironmentService } from '@core/environment/services/environment.service';

@Injectable({
  providedIn: 'root',
})
export class AdminApiService {
  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) {}

  getClientVersion(client: ClientType): Observable<Version> {
    const url = `${this.environmentService.apiEndpoint}${adminApiEndpoints.version(client)}`;
    return this.httpClient.get<Version>(url);
  }

  updateClientVersion(version: Version): Observable<Version> {
    const url = `${this.environmentService.apiEndpoint}${adminApiEndpoints.versioning}`;
    return this.httpClient.post<Version>(url, version);
  }

  get3DSExemptionThreshold(): Observable<number> {
    const url = `${this.environmentService.apiEndpoint}${adminApiEndpoints.threeDSExemption}`;
    return this.httpClient.get<number>(url);
  }

  update3DSExemptionThreshold(threeDSExemption: number): Observable<number> {
    const url = `${this.environmentService.apiEndpoint}${adminApiEndpoints.threeDSExemption}`;
    return this.httpClient.post<number>(url, { threeDSExemption });
  }
}

const adminApiEndpoints = {
  versioning: '/minimum-app-version',
  threeDSExemption: '/three-ds-exemption',
  version: (client: ClientType) => `/minimum-app-version/${client}`,
};
