<div class="working-hours-form" *ngFor="let workingHoursFormGroup of workingHoursFormArray.controls; index as i">
  <ng-container [formGroup]="workingHoursFormGroup">
    <div class="working-hours-form__label">
      {{ workingHoursFormGroup.get('label')?.value }}
    </div>
    <div class="working-hours-form__inputs">
      <kody-form-group [label]="i === 0 ? 'Open' : ''" [control]="workingHoursFormGroup.controls.start">
        <input class="working-hours-form__input" type="time" step="1800" formControlName="start" />
      </kody-form-group>
      <kody-form-group [label]="i === 0 ? 'Close' : ''" [control]="workingHoursFormGroup.controls.end">
        <input class="working-hours-form__input" type="time" step="1800" formControlName="end" />
      </kody-form-group>
      <p class="working-hours-form__error" *ngIf="workingHoursFormGroup.hasError('propertyValueIsHigher') && workingHoursFormGroup.touched">
        Close time cannot be earlier than open time
      </p>
    </div>
  </ng-container>
</div>
