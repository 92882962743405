import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'staffStatusPill',
})
export class StaffStatusPillPipe implements PipeTransform {
  transform(element: string): { color: string; text: string } {
    switch (element?.toLowerCase()) {
      case 'verified':
      case 'unlocked':
        return { color: 'success', text: 'Verified' };
      case 'locked':
        return { color: 'warn', text: 'Locked' };
      case 'unverified':
        return { color: 'orange', text: 'Unverified' };
      default:
        return { color: 'primary', text: 'N/A' };
    }
  }
}
