import { Component, forwardRef, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kody-form-colour-input',
  templateUrl: './form-colour-input.component.html',
  styleUrls: ['./form-colour-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormColourInputComponent),
      multi: true,
    },
  ],
})
export class FormColourInputComponent extends ControlValueAccessor<string> {
  @ViewChild('colourInput') colourInput: HTMLInputElement;
  colour: string;

  writeValue(value: string): void {
    this.colour = value;
    this.onChange(this.colour);
  }
}
