import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStore } from '@app/state/merchant-state/interfaces';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { Observable } from 'rxjs';
import { TimeSlot } from '@features/merchant/interfaces/time-slot.interface';
import { PrintingTemplate, PrintingTemplateForm } from '@features/integrations/interfaces/printer/printing-template.interface';
import { pluck } from 'rxjs/operators';
import { PrintingTemplateTest } from '@features/integrations/interfaces/printer/printing-template-test.interface';

@Injectable({
  providedIn: 'root',
})
export class MerchantStoreApiService {
  constructor(private httpClient: HttpClient, private environment: EnvironmentService) {}

  private getEndpoint(merchantStoreId: string): string {
    return `${this.environment.apiEndpoint}/stores/${merchantStoreId}`;
  }

  getStore(merchantStoreId: string): Observable<IStore> {
    return this.httpClient.get<IStore>(this.getEndpoint(merchantStoreId));
  }

  getDefaultStore(merchantId: string): Observable<IStore> {
    return this.httpClient.get<IStore>(`${this.environment.apiEndpoint}/merchants/${merchantId}/default-store`);
  }

  updateStore(merchantStoreId: string, store: Partial<IStore>): Observable<IStore> {
    return this.httpClient.patch<IStore>(this.getEndpoint(merchantStoreId), store);
  }

  getTimeSlots(merchantStoreId: string): Observable<TimeSlot[]> {
    return this.httpClient.get<TimeSlot[]>(`${this.getEndpoint(merchantStoreId)}/timeslots`);
  }

  updateTimeSlots(merchantStoreId: string, timeslots: TimeSlot[]): Observable<TimeSlot[]> {
    return this.httpClient.patch<TimeSlot[]>(`${this.getEndpoint(merchantStoreId)}/timeslots`, timeslots);
  }

  createPrintingTemplate(merchantStoreId: string, printingTemplate: PrintingTemplateForm): Observable<PrintingTemplate> {
    const url = `${this.environment.apiEndpoint}/stores/${merchantStoreId}/printing-templates`;
    return this.httpClient.post<PrintingTemplate>(url, printingTemplate);
  }

  getPrintingTemplates(merchantStoreId: string): Observable<PrintingTemplate[]> {
    const url = `${this.environment.apiEndpoint}/stores/${merchantStoreId}/printing-templates`;
    return this.httpClient.get<{ printingTemplates: PrintingTemplate[] }>(url).pipe(pluck('printingTemplates'));
  }

  getPrintingTemplate(merchantStoreId: string, printingTemplateId: string): Observable<PrintingTemplate> {
    const url = `${this.environment.apiEndpoint}/stores/${merchantStoreId}/printing-templates/${printingTemplateId}`;
    return this.httpClient.get<PrintingTemplate>(url);
  }

  updatePrintingTemplate(
    merchantStoreId: string,
    printingTemplateId: string,
    printingTemplate: PrintingTemplateForm
  ): Observable<PrintingTemplate> {
    const url = `${this.environment.apiEndpoint}/stores/${merchantStoreId}/printing-templates/${printingTemplateId}`;
    return this.httpClient.patch<PrintingTemplate>(url, printingTemplate);
  }

  deletePrintingTemplate(merchantStoreId: string, printingTemplateId: string): Observable<void> {
    const url = `${this.environment.apiEndpoint}/stores/${merchantStoreId}/printing-templates/${printingTemplateId}`;
    return this.httpClient.delete<void>(url);
  }

  testPrintingTemplate(merchantStoreId: string, printingTemplateId: string, printingTemplateTest: PrintingTemplateTest): Observable<void> {
    const url = `${this.environment.apiEndpoint}/stores/${merchantStoreId}/printing-templates/${printingTemplateId}/test`;
    return this.httpClient.put<void>(url, printingTemplateTest);
  }
}
