import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kody-file-upload-button',
  templateUrl: './file-upload-button.component.html',
  styleUrls: ['./file-upload-button.component.scss'],
})
export class ButtonFileUploadComponent {
  @Input() fileTypes: string[] = [];
  @Input() merchantId: string;
  @Input() loading = false;

  @Output() uploadFile: EventEmitter<FormData> = new EventEmitter<FormData>();

  fileName: string;

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('merchantId', this.merchantId);
      this.uploadFile.emit(formData);
    }
  }
}
