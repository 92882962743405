import { Injectable } from '@angular/core';
import { IMerchant, IMerchantKyc, IRole } from '@app/state/app-state/interfaces';
import { getCurrentRole } from '@app/state/app-state/selectors';
import {
  getMerchantKycToEdit,
  getMerchantToEdit,
  getPrintingConfig,
  getServiceChargeAmounts,
  getStoreTheme,
  hasPrinters,
  getDefaultStore,
  getStoreToEdit,
} from '@app/state/merchant-state/selectors/merchant.selectors';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { IServiceChargeAmount, IStore } from '@app/state/merchant-state/interfaces';
import { getAdminMerchantInfo, getAdminSelectedMerchantId } from '@app/state/admin-state/admin.selectors';
import { filter, map, pluck } from 'rxjs/operators';
import { Theme } from '@merchant/merchant/interfaces/theme.interface';
import { PrintingConfig } from '../interfaces/printing-config.interface';
import { merchantStaffTableSelectors } from '@features/merchant/store/selectors/merchant-staff-table.selectors';
import { printingTemplateTableSelectors } from '@features/merchant/store/selectors/printing-template-table.selectors';
import { stockTableSelectors } from '@app/state/merchant-state/selectors/stock-table.selectors';

@Injectable({
  providedIn: 'root',
})
export class MerchantFacadeService {
  constructor(private store: Store) {}

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  isAdminRole(role: IRole): boolean {
    return role?.name?.toLowerCase() === 'kodypay_admin';
  }

  isOrganizationAdmin(): Observable<boolean> {
    return this.getCurrentRole().pipe(
      filter((role) => !!role),
      map((role) => role?.organizationId && role.name === 'organization_admin')
    );
  }

  // gets the merchant id for both regular mode and admin mode (when admin user selects a merchant)
  getMerchantId(): Observable<string> {
    return combineLatest([this.getCurrentRole(), this.getAdminSelectedMerchantId()]).pipe(
      filter(([role]) => !!role),
      map(([currentRole, selectedMerchantId]) => selectedMerchantId ?? currentRole.organizationId ?? currentRole.merchantId)
    );
  }

  // TODO: This should be moved to a user facade service once it's been created
  getCurrentRole(): Observable<IRole> {
    return this.store.select(getCurrentRole);
  }

  getMerchant(): Observable<IMerchant> {
    return this.store.select(getMerchantToEdit);
  }

  getMerchantKyc(): Observable<IMerchantKyc> {
    return this.store.select(getMerchantKycToEdit);
  }

  getDefaultMerchantStore(): Observable<IStore> {
    return this.store.select(getDefaultStore);
  }

  getStoreToEdit(): Observable<IStore> {
    return this.store.select(getStoreToEdit);
  }

  getStoreTheme(): Observable<Theme> {
    return this.store.select(getStoreTheme);
  }

  getServiceChargeAmounts(): Observable<IServiceChargeAmount[]> {
    return this.store.select(getServiceChargeAmounts);
  }

  // TODO: Move to admin merchant facade once created
  // TODO: Check is admin selector is needed
  getAdminSelectedMerchantId(): Observable<string> {
    return this.store.select(getAdminSelectedMerchantId);
  }

  getAdminMerchantInfo(): Observable<IMerchant> {
    return this.store.select(getAdminMerchantInfo);
  }

  // Combines the merchant admin merchant and kodypay admin merchant view
  getMerchantAdmin(): Observable<Partial<IMerchant>> {
    return combineLatest([this.getAdminMerchantInfo(), this.getMerchant()]).pipe(
      filter(([adminInfo, merchantInfo]) => !!adminInfo && !!merchantInfo),
      map(([adminInfo, merchantInfo]) => ({ ...adminInfo, ...merchantInfo }))
    );
  }

  isKodypayAdmin(): Observable<boolean> {
    return this.store.select(getCurrentRole).pipe(map((role) => this.isAdminRole(role)));
  }

  getMerchantStoreId(): Observable<string> {
    return this.getDefaultMerchantStore().pipe(
      filter((store) => !!store),
      pluck('merchantStoreId')
    );
  }

  hasPrinters(): Observable<boolean> {
    return this.store.select(hasPrinters);
  }

  getPrintingConfig(): Observable<PrintingConfig | null> {
    return this.store.select(getPrintingConfig);
  }

  getStaffTableQuery(): Observable<any> {
    return this.store.select(merchantStaffTableSelectors.query);
  }

  getPrintingTemplateTableQuery(): Observable<any> {
    return this.store.select(printingTemplateTableSelectors.query);
  }

  getStockTableQuery(): Observable<any> {
    return this.store.select(stockTableSelectors.query);
  }
}
