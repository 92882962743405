import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stockStatusPill',
})
export class StockStatusPillPipe implements PipeTransform {
  transform(element: string): { color: string; text: string } {
    switch (element?.toLowerCase()) {
      case 'active':
        return { color: 'success', text: 'Active' };
      case 'disabled':
        return { color: 'warn', text: 'Disabled' };
      case 'out of stock':
        return { color: 'orange', text: 'Out of stock' };
      case 'on offer':
        return { color: 'primary', text: 'On offer' };
      default:
        return { color: 'success', text: 'Active' };
    }
  }
}
