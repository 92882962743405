import { Action, createReducer, on } from '@ngrx/store';
import { FeatureFlagsState } from '@core/feature-flags/store/models/feature-flags-state.model';
import { featureFlagsActions } from '@core/feature-flags/store/actions/feature-flags.actions';

const _featureFlagsReducer = createReducer<FeatureFlagsState>(
  undefined,
  on(featureFlagsActions.startWatching, (state, { initialFlags }) => ({ ...state, flags: initialFlags })),
  on(featureFlagsActions.userIdentified, (state) => ({
    ...state,
    userIdentified: true,
  })),
  on(featureFlagsActions.userForgotten, (state) => ({
    ...state,
    userIdentified: false,
  })),
  on(featureFlagsActions.flagsChanged, (state, { flags }) => ({ ...state, flags: { ...state.flags, ...flags } }))
);

export function featureFlagsReducer(state: FeatureFlagsState, action: Action): FeatureFlagsState {
  return _featureFlagsReducer(state, action);
}
