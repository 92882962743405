import { Router } from '@angular/router';
import { Logout } from '@app/state/app-state/actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects'
import { map, switchMap } from 'rxjs/operators';
import { authenticationGuardActions } from '../actions/authentication-guard.actions';
import { authenticationInterceptorActions } from '../actions/authentication-interceptor.actions';
import { NEVER, of } from 'rxjs';

@Injectable()
export class AuthenticationEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
  ) { }

  logoutUnauthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        authenticationGuardActions.userNotAuthenticated,
        authenticationInterceptorActions.deviceRegistrationError,
      ),
      map(() => Logout())
    ),
  )

  logoutUserIfAuthenticationRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationInterceptorActions.notAuthenticatedError),
      switchMap(({ error }) => {
        const isLoginEndpoint = error.url?.includes('/login');
        const isLoginRoute = this.router.url.includes('login');

        if (isLoginEndpoint || isLoginRoute) {
          return NEVER;
        } else {
          return of(Logout());
        }
      }),
    ),
  )
}
