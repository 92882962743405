import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  readonly baseUrl = environment.apiEndpoint;

  constructor(private http: HttpClient) {}

  importStockItems(formData: FormData): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/merchantItemsCsvImport`, formData, {
      reportProgress: false,
      observe: 'events',
      responseType: 'text',
    });
  }
}
