import { SidenavItem, SideMenu } from '@app/state/app-state/interfaces';
import {
  faBell,
  faExchangeAlt,
  faMobileAlt,
  faShoppingCart,
  faCartPlus,
  faStore,
  faTachometerAlt,
  faUser,
  faBadgePercent,
} from '@fortawesome/pro-solid-svg-icons';

export const defaultSideItems: SidenavItem[] = [
  { id: 'dashboard', label: 'Dashboard', icon: faTachometerAlt, route: 'dashboard' },
  { id: 'transactions', label: 'Transactions', icon: faExchangeAlt, route: 'transactions' },
  { id: 'stock', label: 'Stock', icon: faShoppingCart, iconColor: 'white', route: 'stock' },
  { id: 'addons', label: 'Add-ons & extras', icon: faCartPlus, iconColor: 'white', route: 'addons' },
  {
    id: 'addons',
    label: 'Discounts',
    icon: faBadgePercent,
    iconColor: 'white',
    route: 'discounts',
  },
  { id: 'merchant', label: 'Merchant', icon: faStore, route: 'merchant' },
];

export const adminSideItems: SidenavItem[] = [
  { id: 'dashboard', label: 'Dashboard', icon: faTachometerAlt, route: 'dashboard' },
  { id: 'transactions', label: 'Transactions', icon: faExchangeAlt, route: 'transactions' },
  { id: 'merchant', label: 'Merchant', icon: faStore, route: 'merchant' },
];

export const kodySideItems: SidenavItem[] = [
  { id: 'dashboard', label: 'Dashboard', icon: faTachometerAlt, route: 'dashboard' },
  { id: 'transactions', label: 'Transactions', icon: faExchangeAlt, route: 'transactions' },
  { id: 'merchant', label: 'Merchant', icon: faStore, route: 'merchant' },
  { id: 'users', label: 'Users', icon: faUser, route: 'users' },
  { id: 'notifications', label: 'Notifications', icon: faBell, route: 'notifications' },
  { id: 'kodypay-app', label: 'Kody Merchant App', icon: faMobileAlt, route: 'kodypay-app' },
];

export const defaultSideMenu: SideMenu = {
  basePath: 'merchant',
  items: defaultSideItems,
};

export const adminSideMenu: SideMenu = {
  basePath: 'admin',
  items: adminSideItems,
};

export const kodySideMenu: SideMenu = {
  basePath: 'admin',
  items: kodySideItems,
};

export const enum Roles {
  Retail = 'retail',
  Restaurant = 'restaurant',
  Admin = 'admin',
}

export const sideMenusByRole = {
  default: defaultSideMenu,
  admin: adminSideMenu,
  kodypayAdmin: kodySideMenu,
};

export enum FilterTypes {
  EXISTS = 'exists',
  EQUALTO = 'equalTo',
  FROMTO = 'fromTo',
  DATERANGE = 'dateRange',
  DATETIMERANGE = 'dateTimeRange',
  IN = 'in',
  CONTAINEDBY = 'containedby',
}

export enum DataTypes {
  STRING = 'string',
  UUID = 'uuid',
}

export enum FilterOptions {
  STORES = 'stores',
  MENUCATEGORIES = 'menucategories',
  ORDERSTATUSES = 'orderstatuses',
  TRANSACTIONSTATUSES = 'transactionstatuses',
  REWARDSTATUSES = 'rewardstatuses',
  REWARDCATEGORIES = 'rewardcategories',
  USERSTATUSES = 'userstatuses',
  STAFFSTATUSES = 'staffstatuses',
}

export const DefaultOrderStatuses = [
  {
    statusId: 'new order',
    name: 'New Order',
  },
  {
    statusId: 'pending',
    name: 'Accept',
  },
  {
    statusId: 'complete',
    name: 'Complete',
  },
  {
    statusId: 'declined',
    name: 'Decline',
  },
];

export const DefaultRewardTypes = [
  {
    rewardType: 'earn', //rewardTypeId: 'earn',
    name: 'Earn',
  },
  {
    rewardType: 'spend',
    name: 'Spend',
  },
];

export const DefaultRewardStatuses = [
  {
    status: 'enabled',
    name: 'Enabled',
  },
  {
    status: 'disabled',
    name: 'Disabled',
  },
];

export const DefaultEarnRewardCategories = [
  {
    rewardCategory: 'earn', //earnRewardCategoryId: 'earn',
    name: 'Earn',
  },
  {
    rewardCategory: 'environment',
    name: 'Environment',
  },
  {
    rewardCategory: 'social',
    name: 'Social',
  },
  {
    rewardCategory: 'homeslessness',
    name: 'Homeslessness',
  },
  {
    rewardCategory: 'water',
    name: 'Water',
  },
];

export const DefaultMonetaryValueTypes = [
  {
    monetaryValueType: 'reduce cost of items to', //monetaryValueTypeId
    name: 'Reduce cost of items to',
  },
  {
    monetaryValueType: 'reduce cost of items by',
    name: 'Reduce cost of items by',
  },
];

export const DefaultRewardTriggerLogic = [
  {
    rewardTriggerLogicId: 'or',
    name: 'Or',
  },
  {
    rewardTriggerLogicId: 'and',
    name: 'And',
  },
];

export const DefaultRewardTriggerTypes = [
  {
    rewardTriggerTypeId: 'purchase product',
    name: 'Purchase product',
  },
  {
    rewardTriggerTypeId: 'min spend',
    name: 'Min spend',
  },
];

export const DefaultRewardTriggerPurchaseTypes = [
  {
    rewardTriggerPurchaseTypeId: 'single purchase',
    name: 'Single-purchase',
  },
  {
    rewardTriggerPurchaseTypeId: 'cross purchase',
    name: 'Cross-purchase',
  },
];

export const ClickCollectOrderStatuses = [
  {
    statusId: 'pending',
    name: 'Pending',
  },
  {
    statusId: 'picked',
    name: 'Picked',
  },
  {
    statusId: 'collected',
    name: 'Mark as collected',
  },
  {
    statusId: 'not collected',
    name: 'Mark as not collected',
  },
];

export const OrderStatusesForTable = {
  'new order': 'New Order',
  pending: 'Pending',
  complete: 'Complete',
  declined: 'Declined',
  picked: 'Picked',
  collected: 'Collected',
  'not collected': 'Not Collected',
};

export const OrderStatusesForTableArr = [
  {
    statusId: 'new order',
    name: 'New Order',
  },
  {
    statusId: 'pending',
    name: 'Pending',
  },
  {
    statusId: 'complete',
    name: 'Complete',
  },
  {
    statusId: 'declined',
    name: 'Declined',
  },
  {
    statusId: 'picked',
    name: 'Picked',
  },
  {
    statusId: 'collected',
    name: 'Collected',
  },
  {
    statusId: 'not collected',
    name: 'Not Collected',
  },
];

export const OrderTableNumber = {
  manual_payment: 'Manual Payment',
  order_at_counter: 'Manual Payment',
  order_click_collect: 'C&C',
};

export enum CollectStatus {
  None = 'none',
  Picked = 'picked',
  NoItem = 'noitem',
  Alternative = 'alternative',
}

export enum StatisticsTypes {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export const CollectStatusLabels = {
  noitem: 'N',
  picked: 'Y',
  alternative: 'Y',
  none: 'N/A',
};

export const TransactionMethodsForTable = {
  cybersource: 'Card',
  alipay: 'Alipay',
  googlepay: 'Google Pay',
  applepay: 'Apple Pay',
};

export const UserStatuses = [
  {
    statusId: 'verified',
    name: 'Verified',
  },
  {
    statusId: 'unverified',
    name: 'Unverified',
  },
  {
    statusId: 'locked',
    name: 'Locked',
  },
  {
    statusId: 'no account',
    name: 'No Account',
  },
];

export const UserStatusesForTable = {
  verified: 'Verified',
  unverified: 'Unverified',
  locked: 'Locked',
};

export const UserStatusesForTableArr = [
  {
    statusId: 'unlocked',
    name: 'Verified',
  },
  {
    statusId: 'unlocked',
    name: 'Unverified',
  },
  {
    statusId: 'locked',
    name: 'Locked',
  },
];

export const TransactionStatusesForTableMap = {
  successful: 'Successful',
  pending: 'Pending',
  hold: 'Hold',
  failed: 'Failed',
  refunded: 'Refunded',
  partially_refunded: 'Partially Refunded',
};

export const StaffStatusesForTableArr = [
  {
    statusId: 'enabled',
    name: 'Enabled',
  },
  {
    statusId: 'disabled',
    name: 'Disabled',
  },
];

export enum SignUpMerchantTypes {
  RETAIL = 'retail',
  RESTAURANT = 'restaurant',
  DUAL = 'dualFunction',
}

export enum QRCodeTypes {
  MANUAL_PAYMENT = 'manual_payment',
  RESTAURANT_TABLE = 'restaurant_table',
  RESTAURANT_COUNTER = 'restaurant_counter',
  CLICK_COLLECT = 'click_collect',
  ORDER_DETAILS = 'order_details',
  SELF_CHECKOUT = 'self_checkout',
}

export enum OrderStatuses {
  NEW_ORDER = 'new order',
  PENDING = 'pending',
  COMPLETE = 'complete',
  DECLINED = 'declined',
  PICKED = 'picked',
  COLLECTED = 'collected',
  NOT_COLLECTED = 'not collected',
}

export const imageFileTypes = ['image/jpeg', 'image/png'];
export const documentFileTypes = ['text/plain', 'application/pdf'];
export const inventoryFileTypes = ['text/csv'];
