import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { StateTableFilter, StateTableFilterValue } from '@shared/state-table/interfaces/state-table-filter.interface';
import { Params } from '@angular/router';

export const stateTableActions = <T>(type: string) => ({
  initialise: createAction(`[${type}] Initialise`, props<{ queryParams?: Params; searchKey?: string }>()),
  search: createAction(`[${type}] Search`, props<{ search: string }>()),
  fetchDataSuccess: createAction(`[${type}] Fetch Data Success`, props<{ items: T[]; total: number }>()),
  fetchDataError: createAction(`[${type}] Fetch Data Error`, props<{ error: HttpErrorResponse }>()),
  reorderItems: createAction(`[${type}] Reorder items`, props<{ items: T[] }>()),
  destroy: createAction(`[${type}] Destroy`),
  applyFilter: createAction(`[${type}] Apply Filter`, props<{ filterKey: string; value?: StateTableFilterValue<T> }>()),
  removeFilter: createAction(`[${type}] Remove Filter`, props<{ filterKey: string }>()),
  editFilters: createAction(
    `[${type}] Edit Filters`,
    props<{ addedFilters: StateTableFilter<T>[]; removedFilterKeys: (string | number | symbol)[] }>()
  ),
  paginate: createAction(`[${type}] Paginate`, props<{ first: number; offset: number }>()),
  refresh: createAction(`[${type}] Refresh`),
});

export type StateTableActions = ReturnType<typeof stateTableActions>;
