import { authHeaders } from '@core/authentication/constants/auth-headers.constant';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Theme, ThemePayload } from '@merchant/merchant/interfaces/theme.interface';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { catchError, pluck } from 'rxjs/operators';
import { GoogleFont } from '@merchant/merchant/interfaces/google-font.interface';
import { PrintingConfig } from '@merchant/merchant/interfaces/printing-config.interface';

@Injectable({
  providedIn: 'root',
})
export class MerchantApiService {
  constructor(private httpClient: HttpClient, private environment: EnvironmentService) {}

  getPrintingConfig(merchantStoreId: string): Observable<PrintingConfig | null> {
    const url = `${this.environment.apiEndpoint}${merchantEndpoints.printingConfig(merchantStoreId)}`;
    return this.httpClient.get<PrintingConfig | null>(url);
  }

  updatePrintingConfig(merchantStoreId: string, printingConfig: PrintingConfig): Observable<void> {
    const url = `${this.environment.apiEndpoint}${merchantEndpoints.printingConfig(merchantStoreId)}`;
    return this.httpClient.post<void>(url, printingConfig);
  }

  getTheme(merchantStoreId: string): Observable<Theme | null> {
    const url = `${this.environment.apiEndpoint}${merchantEndpoints.theme(merchantStoreId)}`;
    return this.httpClient.get<Theme>(url).pipe(catchError(() => of<null>(null)));
  }

  updateTheme(merchantStoreId: string, theme: ThemePayload): Observable<Theme> {
    const url = `${this.environment.apiEndpoint}${merchantEndpoints.theme(merchantStoreId)}`;
    return this.httpClient.post<Theme>(url, theme);
  }

  getGoogleFonts(): Observable<GoogleFont[]> {
    const baseUrl = 'https://www.googleapis.com/webfonts/v1/webfonts?key=';
    const url = `${baseUrl}${this.environment.googleFontsApiKey}`;

    return this.httpClient
      .get<{ kind: string; items: GoogleFont[] }>(url, {
        headers: { [authHeaders.simple]: 'true' },
      })
      .pipe(pluck('items'));
  }
}

const merchantEndpoints = {
  theme: (merchantStoreId: string) => `/merchantStores/${merchantStoreId}/theme`,
  printingConfig: (merchantStoreId: string) => `/merchantStores/${merchantStoreId}/printingConfig`,
};
