<!-- Basic Flat Button -->
<!--
  Use case:
  <kody-flat-button [buttonConfig]="btnConfig"></kody-flat-button>

  btnConfig: IFlatBtnConfig = {
    color: 'basic',
    text: 'Flat button'
    method?: () => {}
  };
-->

<!-- Render the template in this outlet -->
<ng-container [ngTemplateOutlet]="buttonTemplate">
  <button
    *ngIf="buttonConfig.text"
    mat-flat-button
    class="ml-2"
    [color]="buttonConfig.color"
    (click)="onButtonClick(buttonConfig.method)"
    [ngClass]="{ 'k-lg-block': buttonConfig.responsive }"
  >
    {{ buttonConfig.text }}
  </button>

  <button
    *ngIf="buttonConfig.icon"
    mat-flat-button
    class="ml-2"
    [color]="buttonConfig.color"
    (click)="onButtonClick(buttonConfig.method)"
    [ngClass]="{ 'k-lg-none': buttonConfig.responsive }"
  >
    <fa-icon [icon]="buttonConfig.icon"></fa-icon>
  </button>
</ng-container>
