/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createReducer, on, Action } from '@ngrx/store';
import {
  CreateUpdateRestaurantCategorySuccess,
  GetCategoryByIdSuccess,
  GetRestaurantCategoriesSuccess,
  GetMerchantByIdSuccess,
  GetOrderByIdSuccess,
  GetOrdersSuccess,
  GetStoreByIdSuccess,
  GetStoreDataIntervalsSuccess,
  UpdateOrderStatusSuccess,
  CreateUpdateStockSuccess,
  GetStockStatusesSuccess,
  GetOfferTypesSuccess,
  GetStoresSuccess,
  GetStockByIdSuccess,
  GetStockSuccess,
  GetStoreStatsSuccess,
  UploadStockCSVImport,
  UploadStockCSVImportSuccess,
  GetStoreThemeSuccess,
  GetAddonItemsSuccess,
  ResetCategoryById,
  GetAddonGroupsSuccess,
  LeaveStockDetailsPage,
  GetPrintersSuccess,
  LeaveStorePage,
  GetPrintingConfigSuccess,
} from '@app/state/merchant-state/actions/merchant.actions';
import { IMerchantState, IOrder, IStoreTimeSlot } from '@app/state/merchant-state/interfaces';
import { Logout } from '../../app-state/actions';
import { CommonHttpErrorResponse } from '@app/state/app-state/actions';
import { merchantInformationActions } from '@features/merchant/store/actions/merchant-information.actions';
import { merchantStoreActions } from '@features/merchant/store/actions/merchant-store.actions';

const initalMerchantState: IMerchantState = {};

function getInitalState(): IMerchantState {
  const savedState = getSavedState();
  if (savedState) {
    return savedState;
  } else {
    return initalMerchantState;
  }
}

function getSavedState(): IMerchantState {
  const savedState = sessionStorage.getItem('merchantState');
  if (savedState) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedState = JSON.parse(savedState);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (parsedState?.merchantState) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return parsedState.merchantState as IMerchantState;
    }
  }
  return null;
}

const merchantReducer = createReducer(
  getInitalState(),
  on(GetStockSuccess, (state, { payload }) => {
    return {
      ...state,
      stock: payload,
    };
  }),
  on(Logout, () => {
    sessionStorage.removeItem('appState');
    return initalMerchantState;
  }),
  on(GetStockByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      stockToEdit: payload,
    };
  }),
  on(GetStoresSuccess, (state, { payload }) => {
    const storesArr = payload.map((store) => {
      return { ...store, statType: 'weekly' };
    });
    const map = {};
    storesArr.forEach((item) => {
      map[item.merchantStoreId] = item;
    });

    return {
      ...state,
      stores: storesArr,
      storeById: { ...(state.storeById || {}), ...map },
      defaultStore: storesArr[0],
    };
  }),
  on(GetOfferTypesSuccess, (state, { payload }) => {
    return {
      ...state,
      offerTypes: payload,
    };
  }),
  on(GetStockStatusesSuccess, (state, { payload }) => {
    const map = {};
    payload.forEach((item) => {
      map[item.statusId] = item;
    });
    return {
      ...state,
      statuses: payload,
      statusesById: {
        ...(state.statusesById || {}),
        ...map,
      },
    };
  }),
  on(merchantStoreActions.updateStoreSuccess, (state, { payload }) => {
    return {
      ...state,
      storeToEdit: payload,
      stores: [...(state.stores || []), payload],
    };
  }),
  on(CreateUpdateStockSuccess, (state) => {
    return {
      ...state,
      stockToEdit: {},
    };
  }),
  on(UpdateOrderStatusSuccess, (state, { payload }) => {
    const updatedOrder: IOrder = payload;
    const orders = [...(state?.orders || [])];

    let index = -1;
    orders.find((value, i) => {
      if (value.restaurantOrderId == updatedOrder.restaurantOrderId) index = i;
    });

    orders.splice(index, 1, updatedOrder);
    return {
      ...state,
      orderToEditForm: payload,
      orders: orders,
    };
  }),
  on(GetStoreDataIntervalsSuccess, (state, { payload }) => {
    return {
      ...state,
      dataIntervals: payload,
    };
  }),
  on(GetStoreByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      storeToEdit: payload,
    };
  }),
  on(GetStoreStatsSuccess, (state, { payload }) => {
    return {
      ...state,
      stores: state.stores
        .map((store) => ({ ...store }))
        .map((store) => {
          if (store.merchantStoreId == payload.merchantStoreId) {
            store.totalAmount = payload.totalAmount;
            store.transactionsCount = payload.transactionsCount;
            store.customersCount = payload.customersCount;
            store.statType = payload.statType;
            return {
              ...store,
            };
          } else {
            return store;
          }
        }),
    };
  }),
  on(GetOrdersSuccess, (state, { payload }) => {
    return {
      ...state,
      orders: payload,
    };
  }),
  on(GetOrderByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      orderToEdit: payload,
    };
  }),
  on(GetMerchantByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      merchantToEdit: payload,
    };
  }),
  on(merchantInformationActions.updateMerchantSuccess, (state, { payload }) => {
    return {
      ...state,
      defaultStore: {
        ...state.defaultStore,
      },
      stores: [
        {
          ...state.defaultStore,
        },
      ],
      storeById: {
        [state.defaultStore.merchantStoreId]: {
          ...state.defaultStore,
        },
      },
      merchantToEdit: {
        ...state.merchantToEdit,
        ...payload.merchant,
      },
    };
  }),
  on(GetRestaurantCategoriesSuccess, (state, { payload }) => {
    const map = {};
    payload.forEach((item) => {
      map[item.restaurantMenuCategoryId] = item;
    });
    return {
      ...state,
      restaurantMenuCategories: payload,
      restaurantMenuCategoryId: {
        ...(state.restaurantMenuCategoryId || {}),
        ...map,
      },
    };
  }),
  on(GetCategoryByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      categoryToEdit: payload,
    };
  }),
  on(ResetCategoryById, (state) => ({
    ...state,
    categoryToEdit: undefined,
  })),
  on(CreateUpdateRestaurantCategorySuccess, (state, { payload }) => {
    return {
      ...state,
      categoryToEdit: payload,
    };
  }),
  on(UploadStockCSVImport, (state) => {
    return {
      ...state,
      importingStock: true,
    };
  }),
  on(UploadStockCSVImportSuccess, (state) => {
    return {
      ...state,
      importingStock: false,
    };
  }),
  on(CommonHttpErrorResponse, (state) => {
    return {
      ...state,
      importingStock: false,
    };
  }),
  on(GetStoreThemeSuccess, (state, { theme }) => ({
    ...state,
    theme,
  })),
  on(GetAddonItemsSuccess, (state, { addonItems }) => ({
    ...state,
    addonItems,
  })),
  on(GetAddonGroupsSuccess, (state, { addonGroups }) => ({
    ...state,
    addonGroups,
  })),
  on(LeaveStockDetailsPage, (state) => ({
    ...state,
    stockToEdit: undefined,
  })),
  on(GetPrintersSuccess, (state, { printers }) => ({
    ...state,
    printers,
  })),
  on(GetPrintingConfigSuccess, (state, { printingConfig }) => ({
    ...state,
    printingConfig,
  })),
  on(LeaveStorePage, (state) => ({
    ...state,
    printers: undefined,
    printingConfig: undefined,
  }))
);

export function merchantReducerCreator(state: IMerchantState | undefined, action: Action): IMerchantState {
  return merchantReducer(state, action);
}
