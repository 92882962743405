import { Component, forwardRef, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kody-file-upload-binary',
  templateUrl: '../file-upload-input/file-upload.component.html',
  styleUrls: ['../file-upload-input/file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadBinaryComponent),
      multi: true,
    },
  ],
})
export class FileUploadBinaryComponent implements ControlValueAccessor {
  @ViewChild('upload') uploadInputRef: ElementRef;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() fileName = '';
  @Input() fileTypes: string[] = [];
  get value(): File {
    return this._value;
  }
  set value(newValue: File) {
    this._value = newValue;
    this._changeDetector.markForCheck();
  }
  private _value: File;
  constructor(private _changeDetector: ChangeDetectorRef) {}

  _onTouched: () => void = () => {};
  private _onChange: (value: any) => void = (_: any) => {};

  writeValue(value: File): void {}

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  handleFiles(filelist: FileList) {
    if (filelist.length > 0) {
      const files: File = filelist.item(0);
      this._updateInputValue(files);
      this._resetInputFile();
      this._onChange(files);
    }
  }

  private _resetInputFile() {
    this.uploadInputRef.nativeElement.value = '';
    this._changeDetector.markForCheck();
  }

  private _updateInputValue(file: File) {
    this.fileName = file.name != null ? file.name : null;
    this._changeDetector.markForCheck();
  }
}
