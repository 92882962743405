<button
  *ngFor="let option of themeColours | keyvalue : compareFn"
  class="theme-picker__theme-circle"
  [style.--theme-circle-primary]="option.value.primary"
  [style.--theme-circle-secondary]="option.value.secondary"
  [style.--theme-circle-selected]="option.value.borderSelected"
  [style.--theme-circle-highlight]="option.value.borderHighlight"
  [ngClass]="{ 'theme-picker__theme-circle--selected': theme === option.key }"
  type="button"
  (click)="writeValue(option.key)"
  [matTooltip]="option.key | titlecase"
></button>
