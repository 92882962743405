import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrinterModel } from '@features/integrations/interfaces/printer/printer-model.interface';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { HttpClient } from '@angular/common/http';
import { CreateIntegration, Integration } from '@features/integrations/interfaces/integration.interface';
import { IntegrationVendor } from '@features/integrations/interfaces/integration-vendor.interface';
import { IntegrationConfiguration } from '@features/integrations/interfaces/integration-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class IntegrationApiService {
  constructor(private httpClient: HttpClient, private environmentService: EnvironmentService) {}

  getPrinterModels(): Observable<PrinterModel[]> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.printerModels}`;
    return this.httpClient.get<PrinterModel[]>(url);
  }

  getIntegrationVendors(): Observable<IntegrationVendor[]> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.integrationVendors}`;
    return this.httpClient.get<IntegrationVendor[]>(url);
  }

  createIntegration(integration: CreateIntegration): Observable<Integration> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.integrations}`;
    return this.httpClient.post<Integration>(url, integration);
  }

  updateIntegration(integrationId: string, configuration: IntegrationConfiguration): Observable<IntegrationConfiguration> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.integration(integrationId)}`;
    return this.httpClient.patch<IntegrationConfiguration>(url, { configuration });
  }

  deleteIntegration(integrationId: string): Observable<void> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.integration(integrationId)}`;
    return this.httpClient.delete<void>(url);
  }

  getIntegrations(merchantStoreId: string): Observable<Integration[]> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.storeIntegrations(merchantStoreId)}`;
    return this.httpClient.get<Integration[]>(url);
  }

  getIntegration(integrationId: string): Observable<Integration> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.integration(integrationId)}`;
    return this.httpClient.get<Integration>(url);
  }

  syncRevelInventory(merchantStoreId: string): Observable<void> {
    const url = `${this.environmentService.apiEndpoint}${endpoints.syncRevelInventory(merchantStoreId)}`;
    return this.httpClient.post<void>(url, {});
  }
}

const endpoints = {
  printerModels: '/printer-models',
  integrationVendors: '/integration-vendors',
  integrations: '/integrations',
  storeIntegrations: (merchantStoreId: string) => `/merchant-stores/${merchantStoreId}/integrations`,
  integration: (integrationId: string) => `/integrations/${integrationId}`,
  syncRevelInventory: (merchantStoreId: string) => `/merchant-stores/${merchantStoreId}/inventory/sync/revel`,
};
