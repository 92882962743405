import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { CommonHttpErrorResponse } from '@app/state/app-state/actions';
import {
  CreateStockItem,
  CreateStockSuccess,
  DeleteStockItem,
  DeleteStockItemSuccess,
  DeleteStockItems,
  DeleteStockItemsSuccess,
  GetAddonGroups,
  GetAddonGroupsSuccess,
  GetAddonItems,
  GetAddonItemsSuccess,
  GetOfferTypes,
  GetOfferTypesSuccess,
  GetStockById,
  GetStockByIdSuccess,
  GetStockStatuses,
  GetStockStatusesSuccess,
  UpdateStockItem,
  UpdateStockSuccess,
  ReorderStockItems,
  ReorderStockItemsSuccess,
  UploadStockCSVImport,
  UploadStockCSVImportPending,
  UploadStockCSVImportSuccess,
} from '@app/state/merchant-state/actions/merchant.actions';
import { Store } from '@ngrx/store';
import { getCurrentRole } from '@app/state/app-state/selectors';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { IOfferType, IStatus, IStock } from '../../interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@common/notifications/services/notification.service';
import { notificationFailureMessage, notificationSuccessMessage } from '@common/notifications/constants/notification-message.const';
import { FileUploadService } from '@common/file-upload/services/file-upload.service';
import { HttpHeaderResponse } from '@angular/common/http';
import { Addon } from 'src/app/features/addons/interfaces/addon.interface';
import { AddonsApiService } from '@features/addons/services/addons-api/addons-api.service';
import { MerchantFacadeService } from '@merchant/merchant/services/merchant-facade.service';
import { StockDataService } from '@common/stock/services/stock-data/stock-data.service';
import { MerchantService } from '@merchant/merchant/services/merchant.service';
import { stockTableActions } from '@app/state/merchant-state/actions/stock-table.actions';
import { ImportService } from '@shared/import/services/import.service';
import { DownloadService } from '@shared/export/services/download.service';
import { fetchStateTableData$ } from '@shared/state-table/effects/state-table.effects';
import { stockTableKey } from '@features/merchant/store/models/merchant-state.model';

const getOfferTypesQuery = gql`
  query getOfferTypes {
    allOfferTypes {
      nodes {
        name
        offerTypeId
      }
    }
  }
`;

const getStatusesQuery = gql`
  query getStatuses($statusId: UUID) {
    allItemStatuses(filter: { statusId: { distinctFrom: $statusId } }) {
      nodes {
        name
        statusId
      }
    }
  }
`;

const getAddonItemsQuery = gql`
  query CmsMerchantAddonItems($merchantId: UUID) {
    merchantAddonItems(id: $merchantId) {
      nodes {
        addonItemId
        alwaysVat
        barcode
        count
        description
        image {
          data
          fileName
          mimeType
        }
        isClickCollect
        isCustom
        isDeleted
        isEatIn
        isGlutenFree
        isOnOffer
        isTakeout
        isVegan
        isVegetarian
        itemId
        menuCategoryId
        menuCategoryOrderIndex
        merchantId
        merchantItemId
        name
        netPrice
        newPrice
        offerTypeId
        price
        statusId
        vatCode
        vatType
        weight
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class StockEffects {
  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private notificationService: NotificationService,
    private fileUploadService: FileUploadService,
    private addonsApiService: AddonsApiService,
    private merchantFacadeService: MerchantFacadeService,
    private stockDataService: StockDataService,
    private merchantService: MerchantService,
    private importService: ImportService,
    private downloadService: DownloadService
  ) {}

  fetchStock$ = createEffect(() =>
    fetchStateTableData$(stockTableKey, this.router, this.actions$, this.merchantFacadeService.getStockTableQuery(), (query) =>
      this.merchantFacadeService.getMerchantId().pipe(
        take(1),
        switchMap((merchantId) => this.stockDataService.getPaginatedStockItems(merchantId, query))
      )
    )
  );

  getStockById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStockById),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([{ payload: merchantItemId }, merchantId]) => {
        return this.stockDataService.getStockItem(merchantId, merchantItemId).pipe(
          map((payload: IStock) => GetStockByIdSuccess({ payload })),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  createStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateStockItem),
      withLatestFrom(this.merchantFacadeService.getMerchantId(), this.merchantFacadeService.getMerchantStoreId()),
      switchMap(([{ stock }, merchantId, merchantStoreId]) =>
        this.stockDataService
          .createStockItem(merchantId, {
            ...stock,
            merchantId,
            merchantStoreIds: [merchantStoreId],
          })
          .pipe(
            map(({ menuCategoryId }) => CreateStockSuccess({ menuCategoryId })),
            tap(() => this.notificationService.success('Stock item created')),
            catchError((error) => of(CommonHttpErrorResponse({ error })))
          )
      )
    )
  );

  updateStock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateStockItem),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([{ merchantItemId, stock }, merchantId]) => {
        return this.stockDataService.updateStockItem(merchantId, merchantItemId, stock).pipe(
          map(({ menuCategoryId }) => UpdateStockSuccess({ menuCategoryId })),
          tap(() => this.notificationService.success('Stock item updated')),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  deleteStockItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteStockItem),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([{ merchantItemId }, merchantId]) => {
        return this.stockDataService.deleteStockItem(merchantId, merchantItemId).pipe(
          map(() => DeleteStockItemSuccess()),
          tap(() => this.notificationService.success('Stock item deleted')),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  deleteStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteStockItems),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([{ itemIds, comboIds }, merchantId]) => {
        return this.stockDataService.deleteStockItems(merchantId, itemIds, comboIds).pipe(
          map(() => DeleteStockItemsSuccess()),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  handleDeleteStockItemsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteStockItemsSuccess),
      tap(() => {
        this.notificationService.success(notificationSuccessMessage.stockItemRemove);
      }),
      map(() => stockTableActions.refresh())
    )
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeleteStockItemSuccess),
        switchMap(() => this.merchantService.getPathPrefix()),
        tap((pathPrefix) => {
          this.router.navigate([`${pathPrefix}/stock/items`], { queryParamsHandling: 'preserve' });
        })
      ),
    { dispatch: false }
  );

  navigateBackAfterEdit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateStockSuccess, UpdateStockSuccess),
        concatLatestFrom(() => combineLatest([this.merchantService.getPathPrefix(), this.route.queryParams])),
        tap(([{ menuCategoryId }, [pathPrefix, queryParams]]) => {
          this.router.navigate([`${pathPrefix}/stock/items`], {
            // creating/editing category in 'all inventory' mode (ie no category id in query param)
            // does not redirect to the created/updated category
            queryParams: { menuCategoryIdEq: queryParams.menuCategoryIdEq ? menuCategoryId : null },
          });
        })
      ),
    { dispatch: false }
  );

  getOfferTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetOfferTypes),
      exhaustMap(() => {
        return this.apollo.query<{ [id: string]: IOfferType[] }>({ query: getOfferTypesQuery }).pipe(
          map((data: ApolloQueryResult<{ [id: string]: IOfferType[] }>) => {
            if (data.errors) throw data.errors;
            return data.data['allOfferTypes']['nodes'];
          }),
          map((payload: IOfferType[]) => GetOfferTypesSuccess({ payload })),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  getStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStockStatuses),
      exhaustMap((action) => {
        return this.apollo.query<{ [id: string]: IStatus[] }>({ query: getStatusesQuery, variables: { statusId: action.payload } }).pipe(
          map((data: ApolloQueryResult<{ [id: string]: IStatus[] }>) => {
            if (data.errors) throw data.errors;
            return data.data['allItemStatuses']['nodes'];
          }),
          map((payload: IStatus[]) => GetStockStatusesSuccess({ payload })),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  uploadStockCsv$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadStockCSVImport),
      switchMap(({ payload }) => {
        return this.fileUploadService.importStockItems(payload).pipe(
          map((response) => {
            if ((response as HttpHeaderResponse).status === 200) {
              return UploadStockCSVImportSuccess();
            } else {
              return UploadStockCSVImportPending();
            }
          }),
          catchError((error: Error) => {
            this.notificationService.error(notificationFailureMessage.stockCSVImport);
            return of(CommonHttpErrorResponse({ error }));
          })
        );
      })
    )
  );

  uploadStockCSVImportSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UploadStockCSVImportSuccess),
        tap(() => this.notificationService.success(notificationSuccessMessage.stockCSVImport))
      ),
    { dispatch: false }
  );

  getAddonItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAddonItems),
      concatLatestFrom(() => this.store.select(getCurrentRole)),
      switchMap(([, { merchantId }]) =>
        this.apollo
          .query<{ [id: string]: Addon[] }>({
            query: getAddonItemsQuery,
            variables: { merchantId },
          })
          .pipe(
            map((data: ApolloQueryResult<{ [id: string]: Addon[] }>) => {
              if (data.errors) throw data.errors;
              return data.data['merchantAddonItems']['nodes'];
            }),
            map((addonItems: Addon[]) => GetAddonItemsSuccess({ addonItems })),
            catchError((error) => of(CommonHttpErrorResponse({ error })))
          )
      )
    )
  );

  getAddonGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAddonGroups),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([, merchantId]) =>
        this.addonsApiService.getAddonGroups(merchantId).pipe(
          map((addonGroups) => GetAddonGroupsSuccess({ addonGroups })),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  reorderStockItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReorderStockItems),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([{ categoryId, merchantItemIds }, merchantId]) =>
        this.stockDataService.reorderStockItems(merchantId, categoryId, merchantItemIds).pipe(
          map(() => ReorderStockItemsSuccess()),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  import$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stockTableActions.importStock),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([_, merchantId]) =>
        this.importService
          .openWizard({
            title: 'Import inventory',
            uploadHeader: 'Upload CSV',
            uploadHint: 'Please upload your CSV inventory template here',
            import$: (formData) => this.stockDataService.importStockItems(merchantId, formData),
          })
          .pipe(map(() => stockTableActions.refresh()))
      )
    )
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stockTableActions.exportStock),
      concatLatestFrom(() => [this.merchantFacadeService.getMerchantId()]),
      switchMap(([{ params }, merchantId]) => {
        return this.stockDataService.exportStockItems(merchantId, params).pipe(
          tap((blob) => this.downloadService.download(blob, 'Stock.csv')),
          map(() => stockTableActions.exportStockSuccess()),
          catchError((error) => {
            this.notificationService.error('Stock export failed');
            return of(CommonHttpErrorResponse({ error }));
          })
        );
      })
    )
  );

  exportSuccessNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stockTableActions.exportStockSuccess),
        tap(() => this.notificationService.success('Stock exported successfully'))
      ),
    { dispatch: false }
  );
}
