import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDateFormats, MatRippleModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldModule, MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SMSModalComponent } from '@common/sms-modal.component';
import { kodyConnectFormDirective } from '@common/directives/connect-form.directive';
import { TableActionsDirective } from './table/kodypay-table-actions.directive';
import { ErrorModalComponent } from '@common/error-modal.component';
import { SnackbarComponent } from '@common/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { KodypayTableComponent } from '@common/table/kodypay-table.component';
import { KodypayFlatButtonComponent } from './kodypay-flat-button/kodypay-flat-button.component';
import { KodypayLabelComponent } from '@common/kodypay-label/kodypay-label.component';
import { CustomFooterDirective } from './table/kodypay-custom-footer-cell.directive';
import { NgxCurrencyModule } from 'ngx-currency';
import { PreventEnterOnFormDirective } from './directives/prevent-enter-on-form.directive';
import { FilterExistsControlComponent } from './table/filter-controls/filter-exists-control/filter-exists-control.component';
import { FilterMultipleSelectControlComponent } from './table/filter-controls/filter-multiple-select-control/filter-multiple-select-control.component';
import { FilterFromToControlComponent } from './table/filter-controls/from-to-filter-control/filter-from-to-control.component';
import { FilterDateRangeControlComponent } from './table/filter-controls/filter-date-range-control/filter-date-range-control.component';
import { FileUploadBinaryComponent } from '@common/file-upload/components/file-upload-binary/file-upload-binary.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ReactiveComponentModule } from '@ngrx/component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DetailsSectionComponent } from './ui/details-section/details-section.component';
import { DetailsPageComponent } from './ui/details-page/details-page.component';
import { StaffStatusPillPipe } from './pipes/staff-status-pill.pipe';
import { MerchantStaffStatusPillPipe } from './pipes/merchant-staff-status-pill.pipe';
import { StockStatusPillPipe } from './pipes/stock-status-pill.pipe';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { ButtonFileUploadComponent } from './file-upload/components/file-upload-button/file-upload-button.component';
import { FileUploadComponent } from './file-upload/components/file-upload-input/file-upload.component';
import { ImageUploadComponent } from './file-upload/components/image-upload/image-upload.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

const customMatDateFormats: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM-YYYY',
  },
};

const imported = [
  A11yModule,
  ClipboardModule,
  CdkStepperModule,
  CdkTableModule,
  MatPaginatorModule,
  MatTableModule,
  CdkTreeModule,
  DragDropModule,
  MatButtonModule,
  MatCardModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatGridListModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMomentDateModule,
  MatSnackBarModule,
  MatStepperModule,
  PortalModule,
  MatTabsModule,
  MatTooltipModule,
  MatToolbarModule,
  MatFormFieldModule,
  ScrollingModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxCurrencyModule,
  NgApexchartsModule,
  ReactiveComponentModule,
  FontAwesomeModule,
];

const declared = [
  SMSModalComponent,
  kodyConnectFormDirective,
  ErrorModalComponent,
  SnackbarComponent,
  KodypayTableComponent,
  KodypayFlatButtonComponent,
  KodypayLabelComponent,
  // CustomCellDirective,
  // CustomHeaderDirective,
  CustomFooterDirective,
  PreventEnterOnFormDirective,
  FileUploadComponent,
  TableActionsDirective,
  FilterExistsControlComponent,
  FilterMultipleSelectControlComponent,
  FilterFromToControlComponent,
  FilterDateRangeControlComponent,
  FileUploadBinaryComponent,
  DetailsSectionComponent,
  DetailsPageComponent,
  StockStatusPillPipe,
  StaffStatusPillPipe,
  MerchantStaffStatusPillPipe,
  CopyToClipboardComponent,
  ButtonFileUploadComponent,
  ImageUploadComponent,
];

const exported = [...imported, ...declared];

@NgModule({
  declarations: [...declared],
  imports: [...imported],
  exports: [...exported],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
    { provide: MAT_DATE_FORMATS, useValue: customMatDateFormats },
    StockStatusPillPipe,
    StaffStatusPillPipe,
    MerchantStaffStatusPillPipe,
  ],
})
export class KodypayCommonModule {}
