import { Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kody-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckListComponent),
      multi: true,
    },
  ],
})
export class CheckListComponent<E, K extends keyof E> extends ControlValueAccessor<E[K][]> {
  @Input() entities: E[];
  @Input() idField: K;
  @Input() labelTemplate: TemplateRef<any>;

  ids: E[K][] = [];

  optionChecked(entity: E): boolean {
    return this.ids.includes(entity[this.idField]);
  }

  onOptionCheck(entity: E): void {
    const newIds = this.optionChecked(entity)
      ? this.ids.filter((id) => id !== entity[this.idField]) // Remove id
      : [...this.ids, entity[this.idField]]; // Add id

    this.writeValue(newIds);
  }

  writeValue(value: E[K][]) {
    this.ids = value;
    this.onChange(value);
  }
}
