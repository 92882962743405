import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, DefaultOptions } from '@apollo/client/core';
import { HttpBatchLink } from 'apollo-angular/http';
import { environment } from '@env/environment';

export function createApollo(httpLink: HttpBatchLink): ApolloClientOptions<any> {
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
  return {
    link: httpLink.create({ uri: environment.graphQlEndpoint }),
    cache: new InMemoryCache({ resultCaching: false, addTypename: false }),
    defaultOptions: defaultOptions,
    connectToDevTools: environment.production ? true : false,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpBatchLink],
    },
  ],
})
export class GraphQLModule {}
