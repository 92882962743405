import { HttpParams } from '@angular/common/http';
import { IStock } from '@app/state/merchant-state/interfaces';
import { stockTableKey } from '@features/merchant/store/models/merchant-state.model';
import { createAction, props } from '@ngrx/store';
import { stateTableActions } from '@shared/state-table/actions/state-table.actions';

const exportStock = createAction(`[${stockTableKey}] Export`, props<{ params: HttpParams }>());

const exportStockSuccess = createAction(`[${stockTableKey}] Export success`);

const importStock = createAction(`[${stockTableKey}] Import`);

const tableActions = stateTableActions<IStock>(stockTableKey);

export const stockTableActions = {
  ...tableActions,
  exportStock,
  exportStockSuccess,
  importStock,
};
