import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, exhaustMap, mergeMap, tap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '@env/environment';
import { CommonHttpErrorResponse } from '@app/state/app-state/actions';
import {
  GetMerchantByIdSuccess,
  GetMerchantById,
  GetPrinters,
  GetPrintersSuccess,
  UpdateMerchantKyc,
  UpdateMerchantKycSuccess,
  DailyReport,
  UpdateCustomerCharges,
  UpdateCustomerChargesSuccess,
  GetStoreTheme,
  GetStoreThemeSuccess,
  UpdateStoreTheme,
  UpdateStoreThemeSuccess,
  GetPrintingConfig,
  GetPrintingConfigSuccess,
  UpdatePrintingConfig,
  UpdatePrintingConfigSuccess,
} from '@app/state/merchant-state/actions/merchant.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { IMerchant, IMerchantKyc } from '@app/state/app-state/interfaces';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Util } from '@utils/utils';
import { NotificationService } from '@common/notifications/services/notification.service';
import { notificationFailureMessage, notificationSuccessMessage } from '@common/notifications/constants/notification-message.const';
import { MerchantApiService } from '@merchant/merchant/services/merchant-api/merchant-api.service';
import { MerchantFacadeService } from '@merchant/merchant/services/merchant-facade.service';
import { IntegrationApiService } from '@features/integrations/services/integration-api/integration-api.service';
import { PrintingIntegration } from '@features/integrations/interfaces/integration.interface';

const getMerchantByIdQuery = gql`
  query getMerchantInfo($merchantId: UUID) {
    merchantInfo(id: $merchantId) {
      baseTransactionOnOpeningHours
      dateCreated
      description
      enableStockCounts
      isRestaurant
      isRetail
      merchantId
      name
      organizationId
      separateTakeOutEatIn
      serviceCharge
      serviceChargeAmount
      merchantKyc
      location
      enableVatClickCollect
      enableBagCharge
      bagChargePrice
      bagChargeNetPrice
      enableVatExemption
      supportedPaymentMethods
      themingEnabled
    }
  }
`;

const updateMerchantKyc = gql`
  mutation updateMerchantKyc($req: MerchantKycRequestModelInput) {
    createOrUpdateMerchantKyc(input: { item: $req }) {
      merchantKycs {
        bankAccountNumber
        bankAccountSortCode
        businessModelSummary
        businessModelSummarySheet {
          data
          fileName
          mimeType
        }
        proofOfFinancialControl {
          data
          fileName
          mimeType
        }
        proofOfIdentification {
          data
          fileName
          mimeType
        }
        companiesHouseDocumentationLink
        companyContactEmail
        companyContactName
        companyName
        companyRegistrationNumber
        dateCreated
        directorAddress
        directorDateOfBirth
        directorName
        merchantKycId
      }
    }
  }
`;

const updateMerchantInfo = gql`
  mutation updateMerchantInfo($req: MerchantsWithKycInput) {
    updateMerchantInfo(input: { item: $req }) {
      merchantsWithKyc {
        baseTransactionOnOpeningHours
        dateCreated
        description
        enableStockCounts
        isRestaurant
        isRetail
        merchantId
        merchantKyc
        name
        organizationId
        separateTakeOutEatIn
        serviceCharge
        serviceChargeAmount
        location
        enableVatClickCollect
        enableBagCharge
        bagChargePrice
        bagChargeNetPrice
      }
    }
  }
`;

const updateMerchantCustomerCharges = gql`
  mutation updateMerchantCustomerCharges(
    $merchantId: UUID
    $enableStockCounts: Boolean
    $enableVatClickCollect: Boolean
    $baseTransactionOnOpeningHours: Boolean
    $separateTakeOutEatIn: Boolean
    $serviceCharge: Boolean
    $serviceChargeAmount: BigFloat
    $enableBagCharge: Boolean
    $bagChargePrice: BigFloat
    $bagChargeNetPrice: BigFloat
    $enableVatExemption: Boolean
  ) {
    updateMerchantCustomerCharges(
      input: {
        item: {
          merchantId: $merchantId
          serviceCharge: $serviceCharge
          serviceChargeAmount: $serviceChargeAmount
          bagChargeNetPrice: $bagChargeNetPrice
          bagChargePrice: $bagChargePrice
          baseTransactionOnOpeningHours: $baseTransactionOnOpeningHours
          enableBagCharge: $enableBagCharge
          enableStockCounts: $enableStockCounts
          enableVatClickCollect: $enableVatClickCollect
          separateTakeOutEatIn: $separateTakeOutEatIn
          enableVatExemption: $enableVatExemption
        }
      }
    ) {
      merchantsWithKyc {
        baseTransactionOnOpeningHours
        dateCreated
        description
        enableStockCounts
        isRestaurant
        isRetail
        merchantId
        merchantKyc
        name
        organizationId
        separateTakeOutEatIn
        serviceCharge
        serviceChargeAmount
        location
        enableVatClickCollect
        enableBagCharge
        bagChargePrice
        bagChargeNetPrice
        enableVatExemption
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class MerchantEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private apollo: Apollo,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notification: NotificationService,
    private merchantApiService: MerchantApiService,
    private merchantFacadeService: MerchantFacadeService,
    private integrationApiService: IntegrationApiService
  ) {}

  getMerchantById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMerchantById),
      exhaustMap((action) => {
        return this.apollo
          .query<{ [id: string]: IMerchant }>({ query: getMerchantByIdQuery, variables: { merchantId: action.payload } })
          .pipe(
            map((data: ApolloQueryResult<{ [id: string]: IMerchant }>) => {
              if (data.errors) throw data.errors;
              return data.data['merchantInfo'];
            }),
            map((payload: IMerchant) => GetMerchantByIdSuccess({ payload })),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  updateMerchantKyc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateMerchantKyc),
      exhaustMap((action) => {
        return this.apollo.mutate<{ [id: string]: IMerchantKyc }>({ mutation: updateMerchantKyc, variables: { req: action.payload } }).pipe(
          map((data: ApolloQueryResult<{ [id: string]: IMerchantKyc }>) => {
            if (data.errors) throw data.errors;
            return data.data['merchantKycs'];
          }),
          map((payload: IMerchantKyc) => UpdateMerchantKycSuccess({ payload })),
          catchError((error: Error) => {
            this.notification.error(notificationFailureMessage.formSubmission);
            return of(CommonHttpErrorResponse({ error }));
          })
        );
      })
    )
  );

  updateMerchantKycSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateMerchantKycSuccess),
        tap(() => {
          this.notification.success(notificationSuccessMessage.merchantKyc);
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        })
      ),
    { dispatch: false }
  );

  updateCustomerCharges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateCustomerCharges),
      exhaustMap((action) => {
        return this.apollo.mutate<IMerchantKyc>({ mutation: updateMerchantCustomerCharges, variables: action.payload }).pipe(
          map((data: ApolloQueryResult<IMerchantKyc>) => {
            if (data.errors) throw data.errors;
            return data.data?.['updateMerchantCustomerCharges']?.['merchantsWithKyc'];
          }),
          mergeMap(() => [
            UpdateCustomerChargesSuccess({ payload: action.payload }),
            GetMerchantById({ payload: action.payload.merchantId }),
          ]),
          catchError((error: Error) => {
            this.notification.error(notificationFailureMessage.formSubmission);
            return of(CommonHttpErrorResponse({ error }));
          })
        );
      })
    )
  );

  updateCustomerChargesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateCustomerChargesSuccess),
        tap(() => {
          this.notification.success(notificationSuccessMessage.customerCharges);
        })
      ),
    { dispatch: false }
  );

  dailyReport$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DailyReport),
        exhaustMap((action) => {
          const dailyReportReqPayload = { ...action.payload };
          const dailyReportRequestUrl = `${environment.apiEndpoint}/report/merchant`;
          return this.http.post(dailyReportRequestUrl, dailyReportReqPayload, { observe: 'response', responseType: 'blob' }).pipe(
            map((res) => {
              const fileName = res.headers.get('Content-Disposition');
              Util.downloadBlob(res.body, fileName);
            }),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
        })
      ),
    { dispatch: false }
  );

  getTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetStoreTheme),
      switchMap(({ payload }) =>
        this.merchantApiService.getTheme(payload).pipe(
          map((theme) => GetStoreThemeSuccess({ theme })),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  updateTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateStoreTheme),
      withLatestFrom(this.merchantFacadeService.getDefaultMerchantStore()),
      switchMap(([{ theme }, { merchantStoreId }]) =>
        this.merchantApiService.updateTheme(merchantStoreId, theme).pipe(
          map(() => {
            this.notification.success(notificationSuccessMessage.themeUpdated);
            return UpdateStoreThemeSuccess();
          }),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  getPrinters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetPrinters),
      switchMap(({ merchantStoreId }) =>
        this.integrationApiService.getIntegrations(merchantStoreId).pipe(
          map((integrations) => {
            const printers = integrations.filter((integration) => integration.integrationVendor === 'Printing');
            return GetPrintersSuccess({ printers });
          }),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  getPrintingConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetPrintingConfig),
      switchMap(({ merchantStoreId }) =>
        this.merchantApiService.getPrintingConfig(merchantStoreId).pipe(
          map((printingConfig) => GetPrintingConfigSuccess({ printingConfig })),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  updatePrintingConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdatePrintingConfig),
      withLatestFrom(this.merchantFacadeService.getDefaultMerchantStore()),
      switchMap(([{ printingConfig }, { merchantStoreId }]) =>
        this.merchantApiService.updatePrintingConfig(merchantStoreId, printingConfig).pipe(
          map(() => UpdatePrintingConfigSuccess()),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  updatePrintingConfigSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdatePrintingConfigSuccess),
        tap(() => this.notification.success(notificationSuccessMessage.printingConfigUpdated))
      ),
    { dispatch: false }
  );
}
