import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sms-modal',
  templateUrl: 'sms-modal.component.html',
})
export class SMSModalComponent {
  smsCode: number;
  constructor(public dialogRef: MatDialogRef<SMSModalComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    this.dialogRef.close({ smsCode: this.smsCode });
  }
}
