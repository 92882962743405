import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { ITableDisplayedColumn } from '@app/state/app-state/interfaces';
import { Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { CurrencyMaskInputMode } from 'ngx-currency';

@Component({
  selector: 'filter-from-to-control',
  templateUrl: './filter-from-to-control.component.html',
  styleUrls: ['./filter-from-to-control.component.scss'],
  host: {
    class: 'd-flex w-100',
    '(keydown.enter)': '$event.preventDefault()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFromToControlComponent {
  @Input()
  column: ITableDisplayedColumn;
  @Output() changed: EventEmitter<any> = new EventEmitter();
  from;
  to;

  keyUpSubject = new Subject<Event>();
  private onDestroySubject = new Subject<void>();

  constructor() {
    this.keyUpSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.writeValue();
        }),
        takeUntil(this.onDestroySubject)
      )
      .subscribe();
  }

  writeValue(): void {
    const fromToObj = {};
    fromToObj[this.column?.id] = {};
    if (this.isValidFloat(this.to) && this.to != '') fromToObj[this.column?.id]['lessThanOrEqualTo'] = this.to;
    if (this.isValidFloat(this.from) && this.from != '') fromToObj[this.column?.id]['greaterThanOrEqualTo'] = this.from;
    if (Object.keys(fromToObj[this.column?.id])?.length == 0) {
      this.changed.emit(null);
    } else {
      this.changed.emit(fromToObj);
    }
  }

  ngOnDestroy() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
  }

  private isValidFloat(str) {
    return /^-?[\d]*(\.[\d]+)?$/g.test(str);
  }
}
