/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { StatisticsTypes } from '../../utils/constants';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { exhaustMap, map, catchError, take, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { IMerchant, IMerchantStat } from '@app/state/app-state/interfaces';
import {
  GetAdminMerchantsSuccess,
  GetAdminMerchants,
  GetAdminMerchantById,
  GetAdminMerchantByIdSuccess,
  ApproveMerchantKyc,
  DeclineMerchantKyc,
  GetMerchantStats,
  GetMerchantStatsSuccess,
  ApproveMerchantSignup,
  RejectMerchantSignup,
} from './admin.actions';
import { CommonHttpErrorResponse, ResetPasswordCMS } from '../app-state/actions';
import { getCurrentRole } from '../app-state/selectors';
import { Router } from '@angular/router';
import { NotificationService } from '@common/notifications/services/notification.service';

const getMerchantsQuery = gql`
  query getMerchants($organization: UUID, $statisticsType: String) {
    organizationMerchants(organization: $organization, statisticsType: $statisticsType) {
      nodes {
        baseTransactionOnOpeningHours
        dateCreated
        description
        isRestaurant
        enableStockCounts
        isRetail
        merchantId
        name
        organizationId
        separateTakeOutEatIn
        serviceCharge
        serviceChargeAmount
        totalAmount
        transactionsCount
        statType
        customersCount
      }
    }
  }
`;

const getMerchantByIdQuery = gql`
  query getMerchantInfo($merchantId: UUID) {
    adminMerchantInfo(merchantId: $merchantId) {
      description
      isRestaurant
      isRetail
      merchantId
      merchantKyc
      name
      numberOfKycs
      approved
      location
    }
  }
`;

const getMerchantStatsQuery = gql`
  query getMerchantStats($id: UUID, $type: String) {
    merchantStats(id: $id, type: $type) {
      nodes {
        merchantId
        customersCount
        totalAmount
        transactionsCount
        statType
      }
    }
  }
`;

const approveMerchantKyc = gql`
  mutation approveMerchantKyc($merchantKycId: UUID) {
    approveMerchantKyc(input: { merchantKycId: $merchantKycId }) {
      boolean
    }
  }
`;

const declineMerchantKyc = gql`
  mutation declineMerchantKyc($merchantKycId: UUID) {
    declineMerchantKyc(input: { merchantKycId: $merchantKycId }) {
      boolean
    }
  }
`;

const approveMerchantSignup = gql`
  mutation approveMerchantSignup($merchant: MerchantRequestModelInput) {
    approveMerchantSignup(input: { item: $merchant }) {
      merchantsWithKycPending {
        merchantId
      }
    }
  }
`;

const rejectMerchantSignup = gql`
  mutation rejectMerchantSignup($merchantId: UUID) {
    rejectMerchantSignup(input: { merchantId: $merchantId }) {
      boolean
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class AdminMerchantsMerchantEffects {
  constructor(
    private http: HttpClient,
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store,
    private router: Router,
    private notification: NotificationService
  ) {}

  getMerchants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAdminMerchants),
      exhaustMap(() => {
        return this.store.select(getCurrentRole).pipe(
          take(1),
          exhaustMap((role) => {
            return this.apollo
              .query<IMerchant[]>({
                query: getMerchantsQuery,
                variables: { organization: role?.organizationId, statisticsType: StatisticsTypes.Weekly },
              })
              .pipe(
                map((data: ApolloQueryResult<IMerchant[]>) => {
                  if (data.errors?.length) throw data.errors;
                  return data.data['organizationMerchants']['nodes'] as IMerchant[];
                }),
                map((payload: IMerchant[]) => GetAdminMerchantsSuccess({ payload })),
                catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
              );
          })
        );
      })
    )
  );

  getMerchantById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAdminMerchantById),
      exhaustMap((action) => {
        return this.apollo.query<IMerchant>({ query: getMerchantByIdQuery, variables: { merchantId: action.payload } }).pipe(
          map((data: ApolloQueryResult<IMerchant>) => {
            if (data.errors?.length) throw data.errors;
            return data.data['adminMerchantInfo'];
          }),
          map((payload: IMerchant) => GetAdminMerchantByIdSuccess({ payload })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  getMerchantStat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetMerchantStats),
      exhaustMap((action: { payload: { id: string; type: string } }) => {
        return this.apollo
          .query<IMerchantStat>({ query: getMerchantStatsQuery, variables: { id: action.payload.id, type: action.payload.type } })
          .pipe(
            map((data: ApolloQueryResult<IMerchantStat>) => {
              if (data.errors?.length) throw data.errors;
              return data.data['merchantStats']['nodes'][0];
            }),
            map((payload: IMerchantStat) => GetMerchantStatsSuccess({ payload: payload })),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  approveMerchantKyc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApproveMerchantKyc),
      exhaustMap((action) => {
        return this.apollo.mutate<boolean>({ mutation: approveMerchantKyc, variables: { merchantKycId: action.payload } }).pipe(
          map((data: ApolloQueryResult<boolean>) => {
            if (data.errors?.length) throw data.errors;
            return data.data['approveMerchantKyc'];
          }),
          map(() => GetAdminMerchantById({ payload: action.merchantId })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  declineMerchantKyc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeclineMerchantKyc),
      exhaustMap((action) => {
        return this.apollo.mutate<boolean>({ mutation: declineMerchantKyc, variables: { merchantKycId: action.payload } }).pipe(
          map((data: ApolloQueryResult<boolean>) => {
            if (data.errors?.length) throw data.errors;
            return data.data['declineMerchantKyc'];
          }),
          map(() => GetAdminMerchantById({ payload: action.merchantId })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  approveMerchantSignup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApproveMerchantSignup),
      exhaustMap((action) => {
        return this.apollo
          .mutate<{ [id: string]: IMerchant }>({ mutation: approveMerchantSignup, variables: { merchant: action.merchant } })
          .pipe(
            map((data: ApolloQueryResult<{ [id: string]: IMerchant }>) => {
              if (data.errors?.length) throw data.errors;
              return data.data['merchantsWithKycPending'];
            }),
            mergeMap((payload: IMerchant) => {
              void this.router.navigate(['/main', 'admin', 'merchant']);
              return [
                GetAdminMerchantById({ payload: payload?.merchantId }),
                ResetPasswordCMS({ payload: action.merchant?.email, navigate: false }),
              ];
            }),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  rejectMerchantSignup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RejectMerchantSignup),
      exhaustMap((action) => {
        return this.apollo
          .mutate<IMerchant>({ mutation: rejectMerchantSignup, variables: { merchantId: action.merchant.merchantId } })
          .pipe(
            map((data: ApolloQueryResult<IMerchant>) => {
              if (data.errors?.length) throw data.errors;
              return data;
            }),
            map(() => {
              void this.router.navigate(['/main', 'admin', 'merchant']);
              return GetAdminMerchants();
            }),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );
}
