import { APP_INITIALIZER } from '@angular/core';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { FeatureFlagService } from '@core/feature-flags/services/feature-flag/feature-flag.service';
import { FeatureFlagFacadeService } from '@core/feature-flags/services/feature-flag-facade/feature-flag-facade.service';
import { featureFlagsActions } from '@core/feature-flags/store/actions/feature-flags.actions';

export const featureFlagInitializerFactory = (
  featureFlagService: FeatureFlagService,
  { launchDarklyClientId }: EnvironmentService,
  featureFlagFacadeService: FeatureFlagFacadeService
) => {
  return async (): Promise<void> => {
    try {
      await featureFlagService.initialize(launchDarklyClientId);

      const initialFlags = featureFlagService.flags();
      featureFlagFacadeService.dispatch(featureFlagsActions.startWatching({ initialFlags }));
    } catch (error) {
      // TODO handle error using proper error handler
      console.error(error);
    }
  };
};

export const FEATURE_FLAG_INITIALIZER = {
  provide: APP_INITIALIZER,
  useFactory: featureFlagInitializerFactory,
  multi: true,
  deps: [FeatureFlagService, EnvironmentService, FeatureFlagFacadeService],
};
