import { createAction, props } from '@ngrx/store';
import {
  ITableState,
  User,
  NavItem,
  IRole,
  IMerchant,
  IPingResult,
  Session,
  QRCode,
  SetPasswordRequest,
} from '@app/state/app-state/interfaces';
import { KodypayInnerActionAdminTypes } from '../admin-state/admin.actions';

export enum KodyPayInnerActionTypes {
  ChangeMainPath = '[App] Change Main Path',
  LoginSuccess = '[User] Login Success',
  LoginError = '[User] Login Error',
  Logout = '[User] Logout',
  GetUserSuccess = '[User] Get User Success',
  GetUserError = '[User] Get User Error',
  UpdateUserSuccess = '[User] Update User Success',
  CommonHttpErrorResponse = '[App] Common http error response',
  UpdateUserError = '[User] Update User Error',
  UpdateTableState = '[Table] Update Table State',
  ResetTableState = '[Table] Reset Table State',
  UpdateToken = '[User] UpdateToken',
  UpdateCurrentRole = '[User] UpdateCurrentRole',
  GetMerchantByRoleSuccess = '[User] Get Merchant By Role Success',

  PingSuccess = '[User] Ping Success',
  PingError = '[User] Ping Error',

  SetSessionUser = '[User] Set Session User',
  SetSession = '[User] Set Session',
  PrintQr = '[User] PrintQr',
  CognitoHttpErrorResponse = '[Cognito] Cognito http error response',
}

export enum KodyPayHttpActionTypes {
  Login = '[User] Login',
  ResetPassword = '[User] ResetPassword',
  ResetPasswordCMS = '[User] ResetPasswordCMS',
  SetPassword = '[User] SetPassword',
  GetUser = '[User] Get User',
  UpdateUser = '[User] Update User',
  Ping = '[User] Ping',
  GetMerchantByRole = '[User] Get Merchant by Role',
}
//HTTP
export const Login = createAction(KodyPayHttpActionTypes.Login, props<{ payload: any }>());
export const ResetPassword = createAction(KodyPayHttpActionTypes.ResetPassword, props<{ payload: string; navigate?: boolean }>());
export const ResetPasswordCMS = createAction(KodyPayHttpActionTypes.ResetPasswordCMS, props<{ payload: any; navigate?: boolean }>());
export const SetPassword = createAction(KodyPayHttpActionTypes.SetPassword, props<{ payload: SetPasswordRequest }>());
export const GetUser = createAction(KodyPayHttpActionTypes.GetUser, props<{ payload: string }>());
export const UpdateUser = createAction(KodyPayHttpActionTypes.UpdateUser, props<{ payload: User }>());
export const GetMerchantByRole = createAction(KodyPayHttpActionTypes.GetMerchantByRole, props<{ payload: IRole }>());
export const Ping = createAction(KodyPayHttpActionTypes.Ping);

//DATA
export const UpdateTableState = createAction(KodyPayInnerActionTypes.UpdateTableState, props<{ payload: ITableState }>());
export const ResetTableState = createAction(KodyPayInnerActionTypes.ResetTableState, props<{ payload: { [id: string]: ITableState } }>());
export const ChangeMainPath = createAction(KodyPayInnerActionTypes.ChangeMainPath, props<{ payload: NavItem }>());
export const Logout = createAction(KodyPayInnerActionTypes.Logout);
export const LoginSuccess = createAction(KodyPayInnerActionTypes.LoginSuccess, props<{ payload: any }>());
export const LoginError = createAction(KodyPayInnerActionTypes.LoginError, props<{ error: Error }>());
export const GetUserSuccess = createAction(KodyPayInnerActionTypes.GetUserSuccess, props<{ payload: User }>());
export const UpdateUserSuccess = createAction(KodyPayInnerActionTypes.UpdateUserSuccess, props<{ payload: User }>());
export const CommonHttpErrorResponse = createAction(KodyPayInnerActionTypes.CommonHttpErrorResponse, props<{ error: Error }>());
export const UpdateToken = createAction(KodyPayInnerActionTypes.UpdateToken, props<{ payload: string }>());
export const UpdateCurrentRole = createAction(KodyPayInnerActionTypes.UpdateCurrentRole, props<{ payload: IRole }>());
export const PingSuccess = createAction(KodyPayInnerActionTypes.PingSuccess, props<{ payload: IPingResult }>());
export const PingError = createAction(KodyPayInnerActionTypes.PingError, props<{ payload: Error }>());
export const GetMerchantByRoleSuccess = createAction(KodyPayInnerActionTypes.GetMerchantByRoleSuccess, props<{ payload: IMerchant }>());

export const CognitoHttpErrorResponse = createAction(
  KodyPayInnerActionTypes.CognitoHttpErrorResponse,
  props<{ error: any /* make a model to match cognito error response */ }>()
);
export const SetSessionUser = createAction(KodyPayInnerActionTypes.SetSessionUser, props<{ payload: User }>());
export const SetSession = createAction(KodyPayInnerActionTypes.SetSession, props<{ payload: Session }>());
export const PrintQr = createAction(KodyPayInnerActionTypes.PrintQr, props<{ payload: string[] }>());
