import { AuthenticationGuard } from './core/authentication/guards/authentication.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule) },
  { path: 'main', canActivate: [AuthenticationGuard], loadChildren: () => import('./modules/main/main.module').then((m) => m.MainModule) },
  {
    path: 'forgot_password',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then((m) => m.ForgotPasswordModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
