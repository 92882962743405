import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Logout, UpdateCurrentRole } from '@app/state/app-state/actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'any' })
export class NavigationEffects {
  constructor(private actions$: Actions, private router: Router, private store: Store) {}

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Logout),
        map(() => this.router.navigate(['login']))
      ),
    { dispatch: false }
  );

  navigateOnRoleChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpdateCurrentRole),
        map(() => this.router.navigate(['main']))
      ),
    { dispatch: false }
  );
}
