<h1 mat-dialog-title>Hello</h1>
<div mat-dialog-content>
  <p>Please enter your text verification code?</p>
  <mat-form-field>
    <mat-label>Code</mat-label>
    <input matInput [(ngModel)]="smsCode" />
  </mat-form-field>
</div>
<div class="d-flex align-items-center justify-content-between" mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="smsCode" (click)="onYesClick()" cdkFocusInitial>Ok</button>
</div>
