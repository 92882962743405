import { createSelector } from '@ngrx/store';
import { StateTableState } from '@shared/state-table/interfaces/state-table.interfaces';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { RangeFilter, StateTableFilter } from '../interfaces/state-table-filter.interface';

export const stateTableSelectors = <E>(tableState: MemoizedSelector<any, StateTableState<E>>, searchKey: string) => ({
  items: createSelector(tableState, (state) => state.items),
  total: createSelector(tableState, (state) => state.total),
  search: createSelector(tableState, (state) => state.search),
  pagination: createSelector(tableState, (state) => state.pagination),
  query: createSelector(tableState, (state) => stateToQuery(state, searchKey)),
  filters: createSelector(tableState, (state) => state.filters),
  activeFilters: createSelector(tableState, (state) => state.filters.filter((f) => f.active)),
  visibleFilters: createSelector(tableState, (state) => state.filters.filter((f) => !f.hidden)),
});

const stateToQuery = <E>(state: StateTableState<E>, searchKey: string) => ({
  first: state?.pagination?.first,
  offset: state?.pagination?.offset,
  ...(state?.search ? { [`${searchKey}Contains`]: state.search } : {}),
  ...(state?.filters || []).reduce((query, filter) => ({ ...query, ...filterToQuery(filter) }), {}),
});

const filterToQuery = <E>({ key, type, value }: StateTableFilter<E>) => {
  if (value === null || value === undefined || value === '') return {};
  switch (type) {
    case 'equals':
      return { [key]: value };
    case 'range':
    case 'dateRange':
    case 'dateTimeRange': {
      const rangeValue = value as RangeFilter<unknown>;
      if (!rangeValue.to && !rangeValue.from) return {};
      const queryValue = {};
      // Convert local datetime to ISO string for date range filters
      const serializeValue = (v: unknown) => (type.includes('date') ? new Date(v as string).toISOString() : v);
      if (rangeValue.to) queryValue[`${key as string}To`] = serializeValue(rangeValue.to);
      if (rangeValue.from) queryValue[`${key as string}From`] = serializeValue(rangeValue.from);
      return queryValue;
    }
    case 'select': {
      const selectValue = value as string[];
      if (!selectValue.length) return {};
      return { [`${key as string}In`]: selectValue };
    }
  }
};

export type StateTableSelectors = ReturnType<typeof stateTableSelectors>;
