import { Component, ChangeDetectionStrategy, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ITableDisplayedColumn } from '@app/state/app-state/interfaces';
import { getFilteringOptions } from '@app/state/merchant-state/selectors/merchant.selectors';
import { Store } from '@ngrx/store';
import { FilterTypes } from '@utils/constants';

@Component({
  selector: 'filter-multiple-select-control',
  templateUrl: './filter-multiple-select-control.component.html',
  styleUrls: ['./filter-multiple-select-control.component.scss'],
  host: { class: 'd-flex w-100 align-items-baseline' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterMultipleSelectControlComponent {
  constructor(public store: Store) {}
  options$;
  private _column: ITableDisplayedColumn;
  @Input()
  set column(val: ITableDisplayedColumn) {
    this._column = val;
    if (!this.options$) this.options$ = this.store.select(getFilteringOptions(this.column?.options));
  }
  get column(): ITableDisplayedColumn {
    return this._column;
  }

  @Output() changed: EventEmitter<any> = new EventEmitter();

  writeValue(values: { id: string; title: string }[]): void {
    if (values?.length) {
      const filterObjects = values.map((item) => item.id);
      const inObj = {};
      const key = this.column?.id;
      inObj[key] = {};
      if (this.column.filterType == FilterTypes.IN) {
        inObj[key] = {
          in: filterObjects,
        };
      } else if (this.column.filterType == FilterTypes.CONTAINEDBY) {
        inObj[key] = {
          containedBy: filterObjects,
        };
      }
      this.changed.emit(inObj);
    } else {
      this.changed.emit(null);
    }
  }

  compareValues(o1: { id: string; title: string }, o2: { id: string; title: string }) {
    return o1?.id == o2?.id;
  }
}
