<h2 class="dialog__title" matDialogTitle>
  <ng-content select="[title]"></ng-content>
  <button mat-button matDialogClose class="close" tabindex="-1">
    <fa-icon [icon]="icons.faTimes" size="2x"></fa-icon>
  </button>
</h2>

<div class="dialog__divider"></div>

<div class="dialog__content" matDialogContent>
  <ng-content select="[content]"></ng-content>
</div>

<div class="dialog__actions" matDialogActions>
  <ng-content select="[actions]"></ng-content>
</div>
