import { IRole } from '@app/state/app-state/interfaces';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { exhaustMap, map, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  GetAdminRewards,
  GetAdminRewardsSuccess,
  CreateUpdateReward,
  CreateUpdateRewardSuccess,
  DeleteAdminRewards,
  DeleteAdminRewardsSuccess,
  UpdateAdminRewards,
  UpdateAdminRewardsSuccess,
  GetAdminRewardById,
  GetAdminRewardByIdSuccess,
} from '@app/state/admin-state/admin.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { CommonHttpErrorResponse } from '../app-state/actions';
import { IAdminReward } from './interfaces';
import { Router } from '@angular/router';
import { getCurrentRole } from '../app-state/selectors';
import { Store } from '@ngrx/store';

const getRewardByIdQuery = gql`
  query getRewardById($rewardId: UUID) {
    organizationRewards(organizationId: null, filter: { rewardId: { equalTo: $rewardId } }) {
      nodes {
        rewardId
        title
        description
        rewardType
        rewardCategory
        pointsValue
        monetaryValue
        monetaryValueType
        status
        pointsTotal
        triggerLogic
        organizationId
        triggers
      }
    }
  }
`;

const getRewardsQuery = gql`
  query getRewards($organizationId: UUID) {
    organizationRewards(organizationId: $organizationId) {
      nodes {
        rewardId
        title
        description
        rewardType
        rewardCategory
        pointsValue
        monetaryValue
        monetaryValueType
        status
        pointsTotal
        triggerLogic
        organizationId
      }
    }
  }
`;

const createOrUpdateOrganizationReward = gql`
  mutation createOrUpdateOrganizationReward($item: OrganizationRewardModelInput) {
    createOrUpdateOrganizationReward(input: { item: $item }) {
      rewards {
        rewardId
        title
        description
        rewardType
        rewardCategory
        pointsValue
        monetaryValue
        monetaryValueType
        status
        pointsTotal
        triggerLogic
        organizationId
      }
    }
  }
`;

const deleteAdminRewards = gql`
  mutation deleteOrganizationReward($rewards: [UUID]) {
    deleteOrganizationReward(input: { rewardIds: $rewards }) {
      boolean
    }
  }
`;

const updateAdminRewardsStatus = gql`
  mutation updateOrganizationRewardsStatus($rewardIds: [UUID], $rewardStatus: String) {
    updateOrganizationRewardsStatus(input: { rewardIds: $rewardIds, rewardStatus: $rewardStatus }) {
      rewards {
        rewardId
        title
        description
        rewardType
        rewardCategory
        pointsValue
        monetaryValue
        monetaryValueType
        status
        pointsTotal
        triggerLogic
        organizationId
        triggers
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class AdminRewardsEffects {
  constructor(private actions$: Actions, private apollo: Apollo, private store: Store) {}

  getRewards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAdminRewards),
      exhaustMap(() => {
        return this.store.select(getCurrentRole).pipe(
          take(1),
          exhaustMap((role: IRole) => {
            return this.apollo.query<IAdminReward[]>({ query: getRewardsQuery, variables: { organizationId: role?.organizationId } }).pipe(
              map((data: ApolloQueryResult<IAdminReward[]>) => {
                if (data.errors?.length) throw data.errors;
                return data.data['organizationRewards']?.['nodes'] as IAdminReward[];
              }),
              map((payload: IAdminReward[]) => GetAdminRewardsSuccess({ payload })),
              catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
            );
          })
        );
      })
    )
  );

  createOrUpdateReward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateUpdateReward),
      exhaustMap((action) => {
        return this.apollo
          .mutate<{ [id: string]: IAdminReward }>({ mutation: createOrUpdateOrganizationReward, variables: { item: action.payload } })
          .pipe(
            map((data: ApolloQueryResult<{ [id: string]: IAdminReward }>) => {
              if (data.errors) throw data.errors;
              return data.data['createOrUpdateOrganizationReward']?.['rewards']?.[0] as IAdminReward;
            }),
            map((payload: IAdminReward) => CreateUpdateRewardSuccess({ payload })),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  deleteRewards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAdminRewards),
      exhaustMap((action: { payload: string[] }) => {
        return this.apollo.mutate<string[]>({ mutation: deleteAdminRewards, variables: { rewards: action.payload } }).pipe(
          map((data: ApolloQueryResult<string[]>) => {
            if (data.errors) throw data.errors;
            return true;
          }),
          // eslint-disable-next-line
          map((payload: any) => DeleteAdminRewardsSuccess({ payload })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  updateRewards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateAdminRewards),
      exhaustMap((action) => {
        return this.apollo
          .mutate<{ [id: string]: string[] }>({
            mutation: updateAdminRewardsStatus,
            variables: { rewardIds: action.payload, rewardStatus: action.status },
          })
          .pipe(
            map((data: ApolloQueryResult<{ [id: string]: string[] }>) => {
              if (data.errors) throw data.errors;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return data.data['updateOrganizationRewardsStatus']?.['rewards']?.[0];
            }),
            // eslint-disable-next-line
            map((payload) => UpdateAdminRewardsSuccess({ payload, status })),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  getAdminRewardById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAdminRewardById),
      exhaustMap((action) => {
        return this.apollo
          .query<{ [id: string]: IAdminReward }>({ query: getRewardByIdQuery, variables: { rewardId: action.payload } })
          .pipe(
            map((data: ApolloQueryResult<IAdminReward>) => {
              if (data.errors?.length) throw data.errors;
              return data.data['organizationRewards']?.['nodes']?.[0] as IAdminReward;
            }),
            map((payload: IAdminReward) => GetAdminRewardByIdSuccess({ payload })),
            catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );
}
