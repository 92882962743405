import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, exhaustMap, take, mergeMap, switchMap } from 'rxjs/operators';
import { of, combineLatest, forkJoin } from 'rxjs';
import { CommonHttpErrorResponse, UpdateTableState } from '@app/state/app-state/actions';
import {
  UpdateOrderStatusSuccess,
  UpdateOrderStatus,
  GetOrderByIdSuccess,
  GetOrderById,
  GetOrdersSuccess,
  GetOrders,
  RefundRestaurantOrder,
  ChargeNotCollectedOrder,
} from '@app/state/merchant-state/actions/merchant.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { IOrder } from '../interfaces';
import { Store } from '@ngrx/store';
import { getTableState, getCurrentRole } from '@app/state/app-state/selectors';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

const getOrdersQuery = gql`
  query orders($merchantId: UUID, $first: Int, $offset: Int, $filter: OrdersWithMerchantIdFilter) {
    merchantOrders(id: $merchantId, offset: $offset, first: $first, filter: $filter, orderBy: DATE_CREATED_DESC) {
      nodes {
        dateCreated
        merchantStoreId
        restaurantOrderId
        status
        tableNumber
        timeToComplete
        userId
        itemsCount
        orderNumber
        type
      }
      totalCount
    }
  }
`;

const getOrderByIdQuery = gql`
  query ordersById($merchantId: UUID, $restaurantOrderId: UUID) {
    merchantOrders(id: $merchantId, filter: { restaurantOrderId: { equalTo: $restaurantOrderId } }) {
      nodes {
        items {
          count
          description
          isCustom
          isEatIn
          isGlutenFree
          isOnOffer
          isTakeout
          isVegan
          isVegetarian
          itemId
          merchantId
          merchantItemId
          note
          name
          price
          quantity
          statusId
          weight
          offerTypeId
          newPrice
          barcode
          restaurantOrderItemId
          collectStatus
          alwaysVat
        }
        isRead
        itemsCount
        merchantId
        merchantStoreId
        orderNumber
        restaurantOrderId
        status
        tableNumber
        timeToComplete
        totalAmount
        type
        clickCollectAlternative
        isFullyRefunded
        transactionTotalAmount
      }
    }
  }
`;

const getOrderItemsIdQuery = gql`
  mutation getOrderItemsIdQuery($restaurantOrderId: UUID) {
    orderItemsWithRefunds(input: { restaurantOrderId: $restaurantOrderId }) {
      orderItemsWithRefund {
        restaurantOrderId
        refundItems {
          barcode
          collectStatus
          count
          isCustom
          description
          isEatIn
          isGlutenFree
          isVegan
          isTakeout
          isOnOffer
          isVegetarian
          itemId
          merchantId
          merchantItemId
          name
          newPrice
          note
          offerTypeId
          price
          quantity
          restaurantOrderItemId
          statusId
          weight
          alwaysVat
        }
        transactionItems {
          barcode
          collectStatus
          count
          isCustom
          description
          isEatIn
          isGlutenFree
          isOnOffer
          isTakeout
          isVegan
          isVegetarian
          merchantId
          itemId
          name
          merchantItemId
          newPrice
          note
          offerTypeId
          price
          quantity
          restaurantOrderItemId
          statusId
          weight
          alwaysVat
        }
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class OrdersEffects {
  constructor(private http: HttpClient, private actions$: Actions, private apollo: Apollo, private store: Store, private router: Router) {}

  getOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetOrders),
      exhaustMap(() => {
        return combineLatest([this.store.select(getCurrentRole), this.store.select(getTableState(GetOrders()))]).pipe(
          take(1),
          exhaustMap(([currentRole, tableState]) => {
            const variables: {
              merchantId: string;
              offset: number;
              first: number;
              filter?: { [id: string]: any };
            } = { merchantId: currentRole.merchantId, offset: tableState.offset, first: tableState.first };
            if (tableState.filterObject) {
              variables.filter = tableState.filterObject;
            }
            return this.apollo.query<IOrder[]>({ query: getOrdersQuery, variables: variables }).pipe(
              map((data: ApolloQueryResult<IOrder[]>) => {
                if (data.errors?.length) throw data.errors;
                return data;
              }),
              mergeMap((payload: ApolloQueryResult<IOrder[]>) => {
                return [
                  GetOrdersSuccess({ payload: payload.data['merchantOrders']['nodes'] }),
                  UpdateTableState({
                    payload: {
                      ...tableState,
                      length: payload.data['merchantOrders']['totalCount'],
                    },
                  }),
                ];
              }),
              catchError((error) => of(CommonHttpErrorResponse({ error })))
            );
          })
        );
      })
    )
  );

  getOrderById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetOrderById),
      exhaustMap((action) => {
        return this.store.select(getCurrentRole).pipe(
          take(1),
          switchMap((role) => {
            return forkJoin({
              details: this.apollo
                .query<any>({ query: getOrderByIdQuery, variables: { restaurantOrderId: action.payload, merchantId: role.merchantId } })
                .pipe(
                  map((data: ApolloQueryResult<any>) => {
                    if (data.errors?.length) throw data.errors;
                    return data?.data?.['merchantOrders']?.['nodes']?.[0];
                  }),
                  map((payload: IOrder) => {
                    return payload;
                  }),
                  catchError((error) => of(error))
                ),
              items: this.apollo.mutate<any>({ mutation: getOrderItemsIdQuery, variables: { restaurantOrderId: action.payload } }).pipe(
                map((data: ApolloQueryResult<any>) => {
                  if (data.errors?.length) throw data.errors;
                  return data?.data?.['orderItemsWithRefunds']?.['orderItemsWithRefund'];
                }),
                map((payload: any) => {
                  return payload;
                }),
                catchError((error) => of(error))
              ),
            }).pipe(
              map((result) => {
                const order: IOrder = {
                  ...result.details,
                  // items: result?.items?.transactionItems || [],
                  refundItems: result?.items?.refundItems || [],
                };
                return GetOrderByIdSuccess({ payload: order });
              }),
              catchError((error) => of(CommonHttpErrorResponse({ error })))
            );
          })
        );
      })
    )
  );

  updateOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateOrderStatus),
      exhaustMap((action) => {
        return this.http
          .post<IOrder>(`${environment.apiEndpoint}/orders/restaurant/update`, {
            restaurantOrderId: action.payload.restaurantOrderId,
            status: action.payload.status,
          })
          .pipe(
            map((payload: IOrder) => {
              this.router.navigate(['main', 'merchant', 'orders']);
              return UpdateOrderStatusSuccess({ payload }), GetOrders();
            }),
            catchError((error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  chargeNotCollected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChargeNotCollectedOrder),
      exhaustMap((action) => {
        return this.http
          .post<IOrder>(`${environment.apiEndpoint}/orders/restaurant/chargeNotCollected`, {
            restaurantOrderId: action.payload.restaurantOrderId,
            status: action.payload.status,
          })
          .pipe(
            map((payload: IOrder) => {
              this.router.navigate(['main', 'merchant', 'orders']);
              return UpdateOrderStatusSuccess({ payload });
            }),
            catchError((error) => of(CommonHttpErrorResponse({ error })))
          );
      })
    )
  );

  refundRestaurantOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RefundRestaurantOrder),
      exhaustMap((action) => {
        const refundRestaurantOrderReqPayload = { ...action.payload };
        const refundRestaurantOrderRequestUrl = `${environment.apiEndpoint}/refunds`;
        return this.http.post(refundRestaurantOrderRequestUrl, refundRestaurantOrderReqPayload).pipe(
          map(() => {
            this.router.navigate(['main', 'merchant', 'orders']);
            return GetOrderById({ payload: action.payload.restaurantOrderId });
          }),
          catchError((error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );
}
