import { MerchantInformationUpdatePayload } from '@features/merchant/interfaces/merchant-information-update-payload.interface';
import { createAction, props } from '@ngrx/store';

const updateMerchant = createAction('[Merchant] Update merchant', props<{ payload: MerchantInformationUpdatePayload }>());

const updateMerchantSuccess = createAction('[Merchant] Update merchant Success', props<{ payload: MerchantInformationUpdatePayload }>());

export const merchantInformationActions = {
  updateMerchant,
  updateMerchantSuccess,
};
