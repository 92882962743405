// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://api-development.kodypay.com/cms',
  baseApiEndpoint: 'https://api-development.kodypay.com',
  kodyPayWebUrl: 'https://q-dev.kpy.io',
  graphQlEndpoint: 'https://api-development.kodypay.com/cms/graphql',
  intercomAppId: 'r7zht755',
  googleFontsApiKey: 'AIzaSyCbJdYew95rvPjk4xKLmlt5tN_RYkK_8Zc',
  launchDarklyClientId: '62d80d1a2e3d8411b2d225c9',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
