import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Addon, AddonGroup, PaginatedAddons } from 'src/app/features/addons/interfaces';
import { pluck } from 'rxjs/operators';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { authHeaders } from '@core/authentication/constants/auth-headers.constant';

@Injectable({
  providedIn: 'root',
})
export class AddonsApiService {
  constructor(private httpClient: HttpClient, private environment: EnvironmentService) {}

  private getEndpoint(merchantId: string, prefix: string, suffix?: string): string {
    return `${this.environment.apiEndpoint}/merchants/${merchantId}/${prefix}${suffix ? `/${suffix}` : ''}`;
  }

  getPaginatedAddonData(merchantId: string, params: HttpParams = null): Observable<PaginatedAddons> {
    return this.httpClient.get<PaginatedAddons>(this.getEndpoint(merchantId, 'addonItems'), { params });
  }

  getAddons(merchantId: string): Observable<Addon[]> {
    return this.getPaginatedAddonData(merchantId).pipe(pluck('addons'));
  }

  getAddon(merchantId: string, addonItemId: string): Observable<Addon> {
    return this.httpClient.get<Addon>(this.getEndpoint(merchantId, 'addonItems', addonItemId));
  }

  createAddon(merchantId: string, addon: Partial<Addon>): Observable<Addon> {
    return this.httpClient.post<Addon>(this.getEndpoint(merchantId, 'addonItems'), { ...addon, merchantId });
  }

  updateAddon(merchantId: string, addon: Partial<Addon>): Observable<Addon> {
    return this.httpClient.patch<Addon>(this.getEndpoint(merchantId, 'addonItems', addon.addonItemId), addon);
  }

  deleteAddon(merchantId: string, addonItemId: string): Observable<void> {
    return this.httpClient.delete<void>(this.getEndpoint(merchantId, 'addonItems', addonItemId));
  }

  deleteAddons(merchantId: string, addonIds: string[]): Observable<void> {
    return this.httpClient.put<void>(this.getEndpoint(merchantId, 'addonItems', 'deleteItems'), addonIds);
  }

  reorderAddons(merchantId: string, addonGroupId: string, addonIds: string[]): Observable<void> {
    return this.httpClient.put<void>(this.getEndpoint(merchantId, 'addonGroups', `${addonGroupId}/reorderAddonItems`), addonIds);
  }

  getAddonGroups(merchantId: string): Observable<AddonGroup[]> {
    return this.httpClient.get<{ addonGroups: AddonGroup[] }>(this.getEndpoint(merchantId, 'addonGroups')).pipe(pluck('addonGroups'));
  }

  getAddonGroup(merchantId: string, addonGroupId: string): Observable<AddonGroup> {
    return this.httpClient.get<AddonGroup>(this.getEndpoint(merchantId, 'addonGroups', addonGroupId));
  }

  createAddonGroup(merchantId: string, addonGroup: Partial<AddonGroup>): Observable<AddonGroup> {
    return this.httpClient.post<AddonGroup>(this.getEndpoint(merchantId, 'addonGroups'), { ...addonGroup, merchantId });
  }

  updateAddonGroup(merchantId: string, addonGroupId: string, addonGroup: Partial<AddonGroup>): Observable<AddonGroup> {
    return this.httpClient.patch<AddonGroup>(this.getEndpoint(merchantId, 'addonGroups', addonGroupId), addonGroup);
  }

  deleteAddonGroup(merchantId: string, addonGroupId: string): Observable<void> {
    return this.httpClient.delete<void>(this.getEndpoint(merchantId, 'addonGroups', addonGroupId));
  }

  importAddonMappings(merchantId: string, formData: FormData): Observable<void> {
    const url = `${this.environment.apiEndpoint}/merchants/${merchantId}/import/addons`;
    return this.httpClient.post<void>(url, formData, { headers: { [authHeaders.generatedContentType]: 'true' } });
  }
}
