import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import QRCodeStyling from 'qr-code-styling';
import { Options } from 'qr-code-styling/lib/types';

@Component({
  selector: 'kody-qr-preview',
  templateUrl: './qr-preview.component.html',
  styleUrls: ['./qr-preview.component.scss'],
})
export class QrPreviewComponent implements AfterViewInit {
  @ViewChild('QRCanvas', { static: true }) canvas: ElementRef;
  @Input() url: string;

  qrColor = '#212121';
  qrCodeOptions: Options = {
    width: 275,
    height: 275,
    type: 'svg',
    image: '/assets/images/kody-qr-insert.svg',
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.5,
      margin: 0,
    },
    dotsOptions: { type: 'extra-rounded', color: this.qrColor },
    cornersSquareOptions: { type: 'dot', color: this.qrColor },
    cornersDotOptions: { type: 'dot', color: this.qrColor },
    qrOptions: {
      errorCorrectionLevel: 'M',
    },
  };
  qrCode: QRCodeStyling;

  ngAfterViewInit(): void {
    if (!QRCodeStyling || !this.url) {
      return;
    }

    this.qrCodeOptions = {
      ...this.qrCodeOptions,
      data: this.url,
    };

    this.qrCode = new QRCodeStyling(this.qrCodeOptions);
    this.qrCode.append(this.canvas.nativeElement);
  }
}
