import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeatureFlagValue } from '@core/feature-flags/types/feature-flag-value.type';
import { FeatureFlagFacadeService } from '@core/feature-flags/services/feature-flag-facade/feature-flag-facade.service';

@UntilDestroy()
@Directive({
  selector: '[kodyFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  @Input() kodyFeatureFlag: string;
  @Input() kodyFeatureFlagMatchValue: FeatureFlagValue;

  constructor(
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<unknown>,
    private featureFlagFacadeService: FeatureFlagFacadeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.featureFlagFacadeService
      .isActive(this.kodyFeatureFlag, this.kodyFeatureFlagMatchValue)
      .pipe(untilDestroyed(this))
      .subscribe((isActive) => {
        if (isActive) {
          this.vcr.createEmbeddedView(this.tpl);
          this.cdr.detectChanges();
        } else {
          this.vcr.clear();
        }
      });
  }
}
