import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private readonly horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  private readonly verticalPosition: MatSnackBarVerticalPosition = 'top';
  readonly options: MatSnackBarConfig = {
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition,
    duration: 1500,
  };

  constructor(private snackbar: MatSnackBar) {}

  success(message: string): void {
    const options = { ...this.options, panelClass: ['success-notification'] };
    this.snackbar.open(message, null, options);
  }

  error(message: string): void {
    const options = { ...this.options, panelClass: ['error-notification'] };
    this.snackbar.open(message, null, options);
  }
}
