import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from '@common/notifications/services/notification.service';

@Component({
  selector: 'kody-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardComponent {
  readonly faCopy = faCopy;
  @Input() field: string;

  constructor(private clipboard: Clipboard, private notificationService: NotificationService) {}

  copyToClipboard(event: Event, id: string): void {
    event.stopPropagation();
    this.clipboard.copy(id);
    this.notificationService.success('Copied to clipboard');
  }
}
