import { IMenuCategory, IOfferType, IStatus, IStock, IStore } from '@app/state/merchant-state/interfaces';
import { IMerchant } from '@app/state/app-state/interfaces';

export interface IAdminState {
  merchantsStock?: IStock[];
  users?: IAdminUser[];
  userToEdit?: IAdminUser;
  addUser?: IAdminUser;
  notifications?: IAdminNotification[];
  rewards?: IAdminReward[];
  adminStatuses?: IStatus[];
  statusesById?: { [id: string]: IStatus };
  merchantStores?: IStore[];
  merchantsById?: { [id: string]: IMerchant };
  merchants?: IMerchant[];
  adminMerchantStoreById?: { [id: string]: IStore };
  restaurantMenuCategoryId?: { [id: string]: IMenuCategory };
  adminRestaurantMenuCategories?: IMenuCategory[];
  adminStockToEdit?: IStock;
  adminOfferTypes?: IOfferType[];
  merchantInfo?: IMerchant;
  selectedMerchant?: IMerchant;
  selectedMerchantId?: string;
  rewardStatuses?: IStatus[];
  rewardCategories?: IRewardCategory[];
  rewardTypes?: IRewardTypes[];
  rewardToEdit?: IAdminReward;
  organizationSettings?: IAppSettings;
  pendingImages?: IMenuCategory[];
  versioning?: AppVersioning;
  threeDSExemptionThreshold?: number;
}

export interface IAdminUser {
  addressLineOne?: string;
  addressLineTwo?: string;
  cards?: Card[];
  city?: string;
  dateOfBirth?: string;
  email?: string;
  firstName?: string;
  fullName?: string;
  isClickCollect?: boolean;
  isAliPay?: boolean;
  isTransactionBatching?: boolean;
  lastName?: string;
  phone?: string;
  postCode?: string;
  status?: string;
  userId?: string;
  phoneConfirmed?: boolean;
  emailConfirmed?: boolean;
}

export interface Card {
  type?: string;
  expirationMonth?: string;
  expirationYear?: string;
  userCardId?: string;
  number?: string;
  shortNumber?: string;
  firstName?: string;
  lastName?: string;
  status?: string;
}

export interface IAdminNotification {
  pushNotificationId?: string;
  dateCreated?: Date;
  dateTouched?: Date;
  payload?: IAdminNotificationPayload;
  pushDeviceId?: string;
  status?: string;
  userId?: string;
}

export interface IAdminNotificationPayload {
  title?: string;
  body?: string;
}

export interface IAdminReward {
  rewardId?: string;
  title?: string;
  description?: string;
  //image?: boolean,
  rewardType?: string;
  rewardCategory?: string;
  //pointsAssociated?: string,
  pointsValue?: number;
  monetaryValue?: number;
  monetaryValueType?: string;
  status?: string;
  pointsTotal?: number;
  triggerLogic?: string;
  organizationId?: string;
  //gbpEquivalent?: string
  triggers?: IAdminRewardTrigger[];
  //rewardTriggers?: Array<IAdminRewardTrigger>
}

export interface IAdminRewardTrigger {
  rewardTriggerId?: string;
  triggerItemLogicIdentifier?: string;
  associatedItemIds?: string;
  purchaseType?: string;
  spendAmount?: number;
  timeFrom?: string;
  timeTo?: string;
  triggerType?: string;
  rewardId?: string;
}

export interface IRewardTypes {
  name?: string;
  rewardType?: string;
}

export interface IRewardCategory {
  rewardCategoryId?: string;
  name?: string;
}

export interface IAppSettings {
  appSettingsId?: string;
  hasAlipay?: boolean;
  hasClickCollect?: boolean;
  hasPrideIcons?: boolean;
  hasTransactionBatch?: boolean;
}

export interface IMonetaryValueTypes {
  name?: string;
  monetaryValueType?: string;
}

export interface IRewardTriggerLogic {
  name?: string;
  rewardTriggerLogicId?: string;
}

export interface IRewardTriggerTypes {
  name?: string;
  rewardTriggerTypeId?: string;
}

export interface IRewardTriggerPurchaseTypes {
  name?: string;
  rewardTriggerPurchaseTypeId?: string;
}

export enum ClientType {
  ios = 'IOS',
  android = 'ANDROID',
}

export interface Version {
  clientType: ClientType;
  internalVersion: string;
  externalVersion: string;
}

export type AppVersioning = Record<ClientType, Version>;
