import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WorkingHours } from '@app/state/merchant-state/interfaces';
import { FormArray } from '@ngneat/reactive-forms';

@Component({
  selector: 'kody-working-hours-form',
  templateUrl: './working-hours-form.component.html',
  styleUrls: ['./working-hours-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingHoursFormComponent {
  @Input() workingHoursFormArray: FormArray<WorkingHours>;
}
