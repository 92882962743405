export const authHeaders = {
  /**
   * This header forces a request unsigned and with no authentication header.
   */
  unsignedHeaderKey: 'X-no-auth-unsigned',
  /**
   * This header forces a request signed and with no authentication header.
   */
  signedHeaderKey: 'X-no-auth-signed',
  /**
   * This header forces a 'simple' request.
   */
  simple: 'X-simple',
  /**
   * This header allows the content type to be generated. ( Useful when sending multipart/form-data )
   */
  generatedContentType: 'X-generated-content-type',
};
