import { createAction, props } from '@ngrx/store';
import {
  IStock,
  IStore,
  IOfferType,
  IStatus,
  IStoreDataInterval,
  IOrder,
  IMenuCategory,
  IStoreTimeSlot,
  RefundRestaurantOrderRequest,
  DailyReportRequest,
} from '@app/state/merchant-state/interfaces';
import { IMerchant, IMerchantKyc, IStoreStat } from '@app/state/app-state/interfaces';
import { CustomerChargesForm } from '@merchant/merchant/interfaces/customer-charges-form.interface';
import { Theme, ThemePayload } from '@merchant/merchant/interfaces/theme.interface';
import { Addon } from 'src/app/features/addons/interfaces/addon.interface';
import { AddonGroup } from '@features/addons/interfaces';
import { PrintingConfig } from '@merchant/merchant/interfaces/printing-config.interface';
import { Integration } from '@features/integrations/interfaces/integration.interface';

export enum KodypayInnerActionMerchantTypes {
  GetStockSuccess = '[Merchant] Get Stock Success',
  GetStockByIdSuccess = '[Merchant] Get Stock By Id Success',
  GetStoresSuccess = '[Merchant] Get Stores Success',
  GetStoreByIdSuccess = '[Merchant] Get Store By Id Success',
  GetDefaultStoreByMerchantIdSuccess = '[Merchant] Get Defalt Store By Merchant Id Success',
  GetStoreStatsSuccess = '[Merchant] Get Store Stats Success',
  GetOrdersSuccess = '[Merchant] Get Orders Success',
  GetOrderItemsSuccess = '[Merchant] Get OrderItems Success',
  GetOrderByIdSuccess = '[Merchant] Get Order by Id Success',
  CreateUpdateStockSuccess = '[Merchant] Create/Update Stock Success',
  UpdateOrderStatusSuccess = '[Merchant] Update Order Status Success',
  GetOfferTypesSuccess = '[Merchant] Get Offer Types Success',
  GetStockStatusesSuccess = '[Merchant] Get Stock Statuses Success',
  GetStoreDataIntervalsSuccess = '[Merchant] Get Store Data Intervals Success',
  UpdateMerchantKycSuccess = '[Merchant] UpdateMerchantKycSuccess',
  GetMerchantByIdSuccess = '[Merchant] Get Merchant by Id Success',
  GetRestaurantCategoriesSuccess = '[Merchant] GetRestaurantCategories Success',
  GetCategoryByIdSuccess = '[Merchant] GetCategoryById Success',
  ResetCategoryById = '[Merchant] Reset CategoryById',
  CreateUpdateRestaurantCategorySuccess = '[Merchant] CreateUpdateRestaurantCategorySuccess',
  UpdateRestaurantCategoryOrderSuccess = '[Merchant] UpdateRestaurantCategoryOrderSuccess',
  AddSlotToStore = '[Store] Add Slot',
  RemoveSlotFromStore = '[Store] Remove Slot',
  UpdateSlots = '[Store] Update timeslots',
  UpdateCustomerChargesSuccess = '[Merchant] Update customer charges success',
  UploadStockCSVImport = '[Stock] Upload Stock CSV Import',
  UploadStockCSVImportSuccess = '[Stock] Upload Stock CSV Import Success',
  UploadStockCSVImportPending = '[Stock] Upload Stock CSV Import Pending',
  GetTheme = '[Merchant] Get theme',
  GetThemeSuccess = '[Merchant] Get theme Success',
  UpdateTheme = '[Merchant] Update theme',
  UpdateThemeSuccess = '[Merchant] Update theme Success',
  GetAddonItemsSuccess = '[Merchant] Get addon items Success',
  GetAddonGroupsSuccess = '[Merchant] Get addon groups Success',
  DeleteStockItemSuccess = '[Merchant] Delete stock item success',
  DeleteStockItemsSuccess = '[Merchant] Delete stock items success',
  CreateStockSuccess = '[Merchant] Create stock Success',
  UpdateStockSuccess = '[Merchant] Update stock Success',
  LeaveStockDetailsPage = '[Merchant] Leave stock details page',
  ReorderStockItemsSuccess = '[Merchant] Reorder stock items Success',
  LeaveStorePage = '[Merchant] Leave store page',
}

export enum KodypayHttpActionMerchantTypes {
  GetStock = '[Merchant] GetStock',
  GetStockById = '[Merchant] GetStockById',
  CreateUpdateStock = '[Merchant] CreateUpdateStock',
  UpdateOrderStatus = '[Merchant] UpdateOrderStatus',
  ChargeNotCollectedOrder = '[Merchant] ChargeNotCollectedOrder',
  RefundRestaurantOrder = '[Merchant] RefundRestaurantOrder',
  GetStores = '[Merchant] GetStores',
  GetStoreById = '[Merchant] GetStoreById',
  GetDefaultStoreByMerchantId = '[Merchant] GetDefaultStoreByMerchantId',
  GetStoreStats = '[Merchant] GetStoreStats',
  GetStoreDataIntervals = '[Merchant] GetStoreDataIntervals',
  GetOrders = '[Merchant] GetOrders',
  GetOrderItems = '[Merchant] GetOrderItems',
  GetOrderById = '[Merchant] GetOrderById',
  GetOfferTypes = '[Merchant] GetOfferTypes',
  GetStockStatuses = '[Merchant] GetStockStatuses',
  GetServiceChargeAmount = '[Merchant] GetServiceChargeAmount',
  GetMerchantById = '[Merchant] GetMerchantById',
  UpdateMerchantKyc = '[Merchant] UpdateMerchantKyc',
  GetRestaurantCategories = '[Merchant] GetRestaurantCategories',
  GetCategoryById = '[Merchant] GetCategoryById',
  CreateUpdateRestaurantCategory = '[Merchant] CreateUpdateRestaurantCategory',
  UpdateRestaurantCategoryOrder = '[Merchant] UpdateRestaurantCategoryOrder',
  DeleteStockItems = '[Merchant] Delete stock items',
  DeleteCategory = '[Merchant] Delete Category',
  DeleteCategorySuccess = '[Merchant] Delete Category success',
  RemoveStore = '[Merchant] RemoveStore',
  DailyReport = '[Merchant] DailyReport',
  UpdateCustomerCharges = '[Merchant] Update customer charges',
  GetAddonItems = '[Merchant] Get addon items',
  GetAddonGroups = '[Merchant] Get addon groups',
  CreateStockItem = '[Merchant] Create stock item',
  UpdateStockItem = '[Merchant] Update stock item',
  DeleteStockItem = '[Merchant] Delete stock item',
  ReorderStockItems = '[Merchant] Reorder stock items',
  GetPrinters = '[Merchant] Get printers',
  GetPrintersSuccess = '[Merchant] Get printers success',
  GetPrintingConfig = '[Merchant] Get printing config',
  GetPrintingConfigSuccess = '[Merchant] Get printing config success',
  UpdatePrintingConfig = '[Merchant] Update printing config',
  UpdatePrintingConfigSuccess = '[Merchant] Update printing config success',
}

//HTTP
export const GetStockById = createAction(KodypayHttpActionMerchantTypes.GetStockById, props<{ payload: string }>());
export const CreateUpdateStock = createAction(KodypayHttpActionMerchantTypes.CreateUpdateStock, props<{ payload: IStock }>());
export const UpdateOrderStatus = createAction(KodypayHttpActionMerchantTypes.UpdateOrderStatus, props<{ payload: IOrder }>());
export const ChargeNotCollectedOrder = createAction(KodypayHttpActionMerchantTypes.ChargeNotCollectedOrder, props<{ payload: IOrder }>());
export const RefundRestaurantOrder = createAction(
  KodypayHttpActionMerchantTypes.RefundRestaurantOrder,
  props<{ payload: RefundRestaurantOrderRequest }>()
);
export const DailyReport = createAction(KodypayHttpActionMerchantTypes.DailyReport, props<{ payload: DailyReportRequest }>());
export const GetStores = createAction(KodypayHttpActionMerchantTypes.GetStores);
export const GetStoreById = createAction(KodypayHttpActionMerchantTypes.GetStoreById, props<{ payload: string }>());
export const GetDefaultStoreByMerchantId = createAction(
  KodypayHttpActionMerchantTypes.GetDefaultStoreByMerchantId,
  props<{ merchantId: string }>()
);
export const GetStoreStats = createAction(KodypayHttpActionMerchantTypes.GetStoreStats, props<{ payload: { id; type } }>());
export const GetStoreDataIntervals = createAction(KodypayHttpActionMerchantTypes.GetStoreDataIntervals);
export const GetOrders = createAction(KodypayHttpActionMerchantTypes.GetOrders);
export const GetOrderItems = createAction(KodypayHttpActionMerchantTypes.GetOrderItems, props<{ payload: string }>());
export const GetOrderById = createAction(KodypayHttpActionMerchantTypes.GetOrderById, props<{ payload: string }>());
export const GetOfferTypes = createAction(KodypayHttpActionMerchantTypes.GetOfferTypes);
export const GetStockStatuses = createAction(KodypayHttpActionMerchantTypes.GetStockStatuses, props<{ payload: string }>());
export const GetServiceChargeAmount = createAction(KodypayHttpActionMerchantTypes.GetServiceChargeAmount);
export const GetMerchantById = createAction(KodypayHttpActionMerchantTypes.GetMerchantById, props<{ payload: string }>());
export const UpdateMerchantKyc = createAction(KodypayHttpActionMerchantTypes.UpdateMerchantKyc, props<{ payload: IMerchantKyc }>());
export const GetRestaurantCategories = createAction(KodypayHttpActionMerchantTypes.GetRestaurantCategories);
export const GetCategoryById = createAction(KodypayHttpActionMerchantTypes.GetCategoryById, props<{ payload: string }>());
export const CreateUpdateRestaurantCategory = createAction(
  KodypayHttpActionMerchantTypes.CreateUpdateRestaurantCategory,
  props<{ restaurantMenuCategoryId: string; payload: IMenuCategory }>()
);
export const UpdateRestaurantCategoryOrder = createAction(
  KodypayHttpActionMerchantTypes.UpdateRestaurantCategoryOrder,
  props<{ payload: string[] }>()
);
export const DeleteStockItems = createAction(
  KodypayHttpActionMerchantTypes.DeleteStockItems,
  props<{ itemIds: string[]; comboIds: string[] }>()
);
export const DeleteCategory = createAction(KodypayHttpActionMerchantTypes.DeleteCategory, props<{ restaurantMenuCategoryId: string }>());
export const DeleteCategorySuccess = createAction(
  KodypayHttpActionMerchantTypes.DeleteCategorySuccess,
  props<{ restaurantMenuCategoryId: string }>()
);
export const RemoveStore = createAction(KodypayHttpActionMerchantTypes.RemoveStore, props<{ payload: IStore }>());
export const UpdateCustomerCharges = createAction(
  KodypayHttpActionMerchantTypes.UpdateCustomerCharges,
  props<{ payload: CustomerChargesForm }>()
);

export const GetAddonItems = createAction(KodypayHttpActionMerchantTypes.GetAddonItems);
export const GetAddonGroups = createAction(KodypayHttpActionMerchantTypes.GetAddonGroups);
export const CreateStockItem = createAction(KodypayHttpActionMerchantTypes.CreateStockItem, props<{ stock: Partial<IStock> }>());
export const UpdateStockItem = createAction(
  KodypayHttpActionMerchantTypes.UpdateStockItem,
  props<{ merchantItemId: string; stock: Partial<IStock> }>()
);
export const DeleteStockItem = createAction(KodypayHttpActionMerchantTypes.DeleteStockItem, props<{ merchantItemId: string }>());
export const LeaveStockDetailsPage = createAction(KodypayInnerActionMerchantTypes.LeaveStockDetailsPage);
export const ReorderStockItems = createAction(
  KodypayHttpActionMerchantTypes.ReorderStockItems,
  props<{ categoryId: string; merchantItemIds: string[] }>()
);
export const GetPrinters = createAction(KodypayHttpActionMerchantTypes.GetPrinters, props<{ merchantStoreId: string }>());
export const LeaveStorePage = createAction(KodypayInnerActionMerchantTypes.LeaveStorePage);

//DATA
export const GetStockSuccess = createAction(KodypayInnerActionMerchantTypes.GetStockSuccess, props<{ payload: IStock[] }>());
export const GetStockByIdSuccess = createAction(KodypayInnerActionMerchantTypes.GetStockByIdSuccess, props<{ payload: IStock }>());
export const GetStoreByIdSuccess = createAction(KodypayInnerActionMerchantTypes.GetStoreByIdSuccess, props<{ payload: IStore }>());
export const GetDefaultStoreByMerchantIdSuccess = createAction(
  KodypayInnerActionMerchantTypes.GetDefaultStoreByMerchantIdSuccess,
  props<{ store: IStore }>()
);
export const GetStoreStatsSuccess = createAction(KodypayInnerActionMerchantTypes.GetStoreStatsSuccess, props<{ payload: IStoreStat }>());
export const RemoveSlotFromStore = createAction(KodypayInnerActionMerchantTypes.RemoveSlotFromStore, props<{ payload: number }>());
export const GetStoreDataIntervalsSuccess = createAction(
  KodypayInnerActionMerchantTypes.GetStoreDataIntervalsSuccess,
  props<{ payload: IStoreDataInterval[] }>()
);
export const CreateUpdateStockSuccess = createAction(
  KodypayInnerActionMerchantTypes.CreateUpdateStockSuccess,
  props<{ payload: IStock }>()
);
export const UpdateOrderStatusSuccess = createAction(
  KodypayInnerActionMerchantTypes.UpdateOrderStatusSuccess,
  props<{ payload: IOrder }>()
);
export const GetStoresSuccess = createAction(KodypayInnerActionMerchantTypes.GetStoresSuccess, props<{ payload: IStore[] }>());
export const GetOrdersSuccess = createAction(KodypayInnerActionMerchantTypes.GetOrdersSuccess, props<{ payload: IOrder[] }>());
export const GetOrderByIdSuccess = createAction(KodypayInnerActionMerchantTypes.GetOrderByIdSuccess, props<{ payload: IOrder }>());
export const GetOfferTypesSuccess = createAction(KodypayInnerActionMerchantTypes.GetOfferTypesSuccess, props<{ payload: IOfferType[] }>());
export const GetStockStatusesSuccess = createAction(
  KodypayInnerActionMerchantTypes.GetStockStatusesSuccess,
  props<{ payload: IStatus[] }>()
);
export const GetMerchantByIdSuccess = createAction(KodypayInnerActionMerchantTypes.GetMerchantByIdSuccess, props<{ payload: IMerchant }>());
export const UpdateMerchantKycSuccess = createAction(
  KodypayInnerActionMerchantTypes.UpdateMerchantKycSuccess,
  props<{ payload: IMerchantKyc }>()
);
export const GetRestaurantCategoriesSuccess = createAction(
  KodypayInnerActionMerchantTypes.GetRestaurantCategoriesSuccess,
  props<{ payload: IMenuCategory[] }>()
);
export const GetCategoryByIdSuccess = createAction(
  KodypayInnerActionMerchantTypes.GetCategoryByIdSuccess,
  props<{ payload: IMenuCategory }>()
);
export const ResetCategoryById = createAction(KodypayInnerActionMerchantTypes.ResetCategoryById);
export const CreateUpdateRestaurantCategorySuccess = createAction(
  KodypayInnerActionMerchantTypes.CreateUpdateRestaurantCategorySuccess,
  props<{ payload: IMenuCategory }>()
);
export const UpdateRestaurantCategoryOrderSuccess = createAction(
  KodypayInnerActionMerchantTypes.UpdateRestaurantCategoryOrderSuccess,
  props<{ payload: IMenuCategory[] }>()
);
export const UpdateSlots = createAction(KodypayInnerActionMerchantTypes.UpdateSlots, props<{ payload: IStoreTimeSlot[] }>());
export const UpdateCustomerChargesSuccess = createAction(
  KodypayInnerActionMerchantTypes.UpdateCustomerChargesSuccess,
  props<{ payload: IMerchantKyc }>()
);
export const UploadStockCSVImport = createAction(KodypayInnerActionMerchantTypes.UploadStockCSVImport, props<{ payload: FormData }>());
export const UploadStockCSVImportSuccess = createAction(KodypayInnerActionMerchantTypes.UploadStockCSVImportSuccess);
export const UploadStockCSVImportPending = createAction(KodypayInnerActionMerchantTypes.UploadStockCSVImportPending);

export const GetStoreTheme = createAction(KodypayInnerActionMerchantTypes.GetTheme, props<{ payload: string }>());
export const GetStoreThemeSuccess = createAction(KodypayInnerActionMerchantTypes.GetThemeSuccess, props<{ theme: Theme }>());

export const UpdateStoreTheme = createAction(KodypayInnerActionMerchantTypes.UpdateTheme, props<{ theme: ThemePayload }>());
export const UpdateStoreThemeSuccess = createAction(KodypayInnerActionMerchantTypes.UpdateThemeSuccess);

export const GetAddonItemsSuccess = createAction(KodypayInnerActionMerchantTypes.GetAddonItemsSuccess, props<{ addonItems: Addon[] }>());
export const GetAddonGroupsSuccess = createAction(
  KodypayInnerActionMerchantTypes.GetAddonGroupsSuccess,
  props<{ addonGroups: AddonGroup[] }>()
);

export const DeleteStockItemSuccess = createAction(KodypayInnerActionMerchantTypes.DeleteStockItemSuccess);
export const DeleteStockItemsSuccess = createAction(KodypayInnerActionMerchantTypes.DeleteStockItemsSuccess);
export const CreateStockSuccess = createAction(KodypayInnerActionMerchantTypes.CreateStockSuccess, props<{ menuCategoryId: string }>());
export const UpdateStockSuccess = createAction(KodypayInnerActionMerchantTypes.UpdateStockSuccess, props<{ menuCategoryId: string }>());
export const ReorderStockItemsSuccess = createAction(KodypayInnerActionMerchantTypes.ReorderStockItemsSuccess);
export const GetPrintersSuccess = createAction(KodypayHttpActionMerchantTypes.GetPrintersSuccess, props<{ printers: Integration[] }>());
export const GetPrintingConfig = createAction(KodypayHttpActionMerchantTypes.GetPrintingConfig, props<{ merchantStoreId: string }>());
export const GetPrintingConfigSuccess = createAction(
  KodypayHttpActionMerchantTypes.GetPrintingConfigSuccess,
  props<{ printingConfig: PrintingConfig | null }>()
);
export const UpdatePrintingConfig = createAction(
  KodypayHttpActionMerchantTypes.UpdatePrintingConfig,
  props<{ printingConfig: PrintingConfig }>()
);
export const UpdatePrintingConfigSuccess = createAction(KodypayHttpActionMerchantTypes.UpdatePrintingConfigSuccess);
