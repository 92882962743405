<kody-dialog>
  <ng-container title>{{ config.title }}</ng-container>
  <ng-container content *ngIf="step$ | async as step" [ngSwitch]="step">
    <ng-container *ngSwitchCase="'configuration'">
      <div class="kody-form-group">
        <h3>{{ config.uploadHeader }}</h3>
        <p>{{ config.uploadHint }}</p>
        <kody-form-group>
          <kody-file-upload-binary
            [formControl]="fileControl"
            [fileName]="fileControl.value?.name"
            placeholder="Upload"
            [fileTypes]="['text/csv']"
          ></kody-file-upload-binary>
        </kody-form-group>
      </div>
      <button mat-flat-button color="primary" type="submit" [disabled]="!fileControl.value" (click)="import()">Import</button>
    </ng-container>

    <ng-container *ngSwitchCase="'verifying'">
      <kody-wizard-status
        status="verifying"
        title="Importing your inventory"
        message="We're importing your inventory into the Kody platform now"
        actionText="Cancel"
        (action)="cancel()"
      ></kody-wizard-status>
    </ng-container>

    <ng-container *ngSwitchCase="'success'">
      <kody-wizard-status
        status="success"
        title="Success!"
        message="Your import is complete"
        actionText="Done"
        (action)="done()"
      ></kody-wizard-status>
    </ng-container>

    <ng-container *ngSwitchCase="'failure'">
      <kody-wizard-status
        *ngIf="error$ | async as error"
        status="failure"
        title="We've encountered a problem"
        actionText="Back to configuration"
        (action)="reconfigure()"
      >
        <ng-container message>
          <p>{{ error.reason }}</p>
          <ul>
            <li *ngFor="let lineError of error.failed">Line: {{ lineError.line }}, Error: {{ lineError.reason }}</li>
          </ul>
        </ng-container>
      </kody-wizard-status>
    </ng-container>
  </ng-container>
</kody-dialog>
