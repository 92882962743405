export const notificationSuccessMessage = {
  customerCharges: 'Customer charges updated',
  merchantInfo: 'Merchant information updated',
  merchantKyc: 'Merchant KYC information updated',
  storeInfo: 'Store information updated',
  clickCollect: 'Click and collect updated',
  staffAddUpdate: 'Staff updated',
  staffRemove: 'Staff member removed',
  stockCSVImport: 'Stock information updated with import data',
  themeUpdated: 'White labelling theme saved',
  addonItemRemove: 'Selected add-on items removed',
  stockItemRemove: 'Selected inventory items removed',
  printingConfigUpdated: 'Printing settings saved',
  storeInformationUpdated: 'Store information updated',
  timeSlotsUpdated: 'Time slots updated',
};

export const notificationFailureMessage = {
  formSubmission: 'There has been a problem.  Please try again',
  apiError: 'Something went wrong.  Please try again',
  stockCSVImport: 'Stock import went wrong.  Please try again',
};
