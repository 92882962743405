import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ApiEffects } from '@app/state/app-state/api.effects';
import { reducer } from '@app/state/app-state/app.reducer';
import { adminReducerCreator } from '@app/state/admin-state/admin.reducer';
import { merchantReducerCreator } from '@app/state/merchant-state/reducers/merchant.reducer';
import { NavigationEffects } from '@app/state/app-state/navigation.effects';
import { ModalEffects } from '@app/state/app-state/modal.effects';
import { KodypayCommonModule } from '@common/kodypay-common.module';
import { GraphQLModule } from './graphql.module';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@env/environment';
import { AdminUsersEffects } from '@app/state/admin-state/admin-users.effects';
import { AdminNotificationsEffects } from '@app/state/admin-state/admin-notifications.effects';
import { AdminRewardsEffects } from '@app/state/admin-state/admin-rewards.effects';
import { StockEffects } from '@app/state/merchant-state/effects/stock/stock.effects';
import { StoreEffects } from '@app/state/merchant-state/effects/store.effects';
import { OrdersEffects } from '@app/state/merchant-state/effects/orders.effects';
import { MerchantEffects } from '@app/state/merchant-state/effects/merchant.effects';
import { AdminMerchantsMerchantEffects } from './state/admin-state/admin-merchants-merchant-info.effects';
import { AdminKodypayAppSettingsEffects } from './state/admin-state/admin-kodypay-app-settings/admin-kodypay-app-settings.effects';
import { IntercomConfig, IntercomModule } from 'ng-intercom';
import { CoreModule } from '@core/core.module';
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { QrModule } from '@shared/qr/qr.module';
import { FEATURE_FLAG_INITIALIZER } from '@core/feature-flags/providers/feature-flag-app-initializer.provider';
import { FormsModule } from '@shared/forms/forms.module';
import { MenuCategoriesEffects } from './state/merchant-state/effects/menu-categories/menu-categories.effects';
import { AuthenticationEffects } from './core/store/authentication/effects/authentication.effects';
import { httpInterceptProviders } from './core/authentication/interceptors/authentication.interceptor';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '£ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: 0,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

export const intercomConfig: IntercomConfig = { appId: environment.intercomAppId, updateOnRouterChange: true };
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KodypayCommonModule,
    GraphQLModule,
    IntercomModule.forRoot(intercomConfig),
    StoreModule.forRoot({ appState: reducer, adminState: adminReducerCreator, merchantState: merchantReducerCreator }),
    EffectsModule.forRoot([
      ApiEffects,
      NavigationEffects,
      ModalEffects,
      AdminUsersEffects,
      AdminNotificationsEffects,
      AdminRewardsEffects,
      MerchantEffects,
      StockEffects,
      StoreEffects,
      OrdersEffects,
      MenuCategoriesEffects,
      AdminMerchantsMerchantEffects,
      AdminKodypayAppSettingsEffects,
      AuthenticationEffects,
    ]),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    CoreModule,
    QrModule,
    FormsModule,
  ],
  providers: [httpInterceptProviders, { provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 100 } }, FEATURE_FLAG_INITIALIZER],
  bootstrap: [AppComponent],
})
export class AppModule {}
