import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { ColorTheme } from '@shared/types/color-theme.type';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kody-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThemePickerComponent),
      multi: true,
    },
  ],
})
export class ThemePickerComponent extends ControlValueAccessor<ColorTheme> {
  @Input() excludeWhite: boolean;
  theme: ColorTheme;
  private _allThemeColors: Record<ColorTheme, { primary: string; secondary: string; borderSelected: string; borderHighlight: string }> = {
    white: { primary: '#f5f8fa', secondary: '#121323', borderSelected: '#121323', borderHighlight: '#f5f8fa' },
    blue: { primary: '#415278', secondary: '#D6DDED', borderSelected: '#415278', borderHighlight: '#D6DDED' },
    green: { primary: '#889151', secondary: '#EAEDD6', borderSelected: '#889151', borderHighlight: '#EAEDD6' },
    red: { primary: '#592C2C', secondary: '#EDD6D6', borderSelected: '#592C2C', borderHighlight: '#EDD6D6' },
    aqua: { primary: '#4D6E80', secondary: '#D6E5ED', borderSelected: '#4D6E80', borderHighlight: '#D6E5ED' },
    grey: { primary: '#5C4747', secondary: '#C6C6C6', borderSelected: '#5C4747', borderHighlight: '#C6C6C6' },
    yellow: { primary: '#5F5E48', secondary: '#F4F0A4', borderSelected: '#5F5E48', borderHighlight: '#F4F0A4' },
    orange: { primary: '#904B35', secondary: '#FDE5DD', borderSelected: '#904B35', borderHighlight: '#FDE5DD' },
    purple: { primary: '#4A3553', secondary: '#E9D2F4', borderSelected: '#4A3553', borderHighlight: '#E9D2F4' },
  };

  get themeColours(): Omit<
    Record<ColorTheme, { primary: string; secondary: string; borderSelected: string; borderHighlight: string }>,
    'white'
  > {
    if (this.excludeWhite) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { white, ...others } = this._allThemeColors;
      return others;
    } else {
      return this._allThemeColors;
    }
  }

  compareFn = () => 0;

  writeValue(value: ColorTheme) {
    this.theme = value;
    this.onChange(this.theme);
  }
}
