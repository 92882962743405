/* TODO: FIX ALL ESLINT ISSUES */
/* eslint-disable */
import { Directive } from '@angular/core';

@Directive({
  selector: '[kodyPreventEnter]',
  host: {
    '(keydown.enter)': 'handleEnterKeyPress($event)',
    '(keydown.shift.enter)': 'handleEnterKeyPress($event)',
    '(keydown.control.enter)': 'handleEnterKeyPress($event)',
  },
})
export class PreventEnterOnFormDirective {
  handleEnterKeyPress(event: Event): boolean {
    const tagName = (event?.target as any)?.tagName?.toLowerCase();
    if (tagName !== 'textarea') {
      return false;
    }
  }
}
