import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { CommonHttpErrorResponse } from '@app/state/app-state/actions';
import {
  Get3DSExemptionThreshold,
  Get3DSExemptionThresholdFailure,
  Get3DSExemptionThresholdSuccess,
  GetOrganizationSettings,
  GetOrganizationSettingsSuccess,
  GetVersioning,
  GetVersioningFailure,
  GetVersioningSuccess,
  Update3DSExemptionThreshold,
  Update3DSExemptionThresholdFailure,
  Update3DSExemptionThresholdSuccess,
  UpdateOrganizationSettings,
  UpdateVersion,
  UpdateVersionSuccess,
} from '@app/state/admin-state/admin.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import { ClientType, IAppSettings } from '../interfaces';
import { Store } from '@ngrx/store';
import { getCurrentRole } from '../../app-state/selectors';
import { AdminApiService } from '@admin/services/admin-api/admin-api.service';
import { NotificationService } from '@common/notifications/services/notification.service';

const getOrganizationSettingsQuery = gql`
  query getOrganizationSettings {
    appSettings {
      hasAlipay
      hasClickCollect
      hasPrideIcons
      hasTransactionBatch
    }
  }
`;

const updateOrganizationSettings = gql`
  mutation updateSettings($settings: AppSettingInput) {
    updateAppSettings(input: { req: $settings }) {
      appSetting {
        hasAlipay
        hasClickCollect
        hasPrideIcons
        hasTransactionBatch
      }
    }
  }
`;

@Injectable({ providedIn: 'any' })
export class AdminKodypayAppSettingsEffects {
  constructor(
    private actions$: Actions,
    private apollo: Apollo,
    private store: Store,
    private adminApiService: AdminApiService,
    private notificationService: NotificationService
  ) {}

  getOrganizationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetOrganizationSettings),
      exhaustMap(() => {
        return this.store.select(getCurrentRole).pipe(
          take(1),
          exhaustMap(() => {
            return this.apollo.query<IAppSettings>({ query: getOrganizationSettingsQuery }).pipe(
              map((data: ApolloQueryResult<IAppSettings>) => {
                if (data.errors?.length) throw data.errors;
                return data?.data?.['appSettings'] as IAppSettings;
              }),
              map((payload: IAppSettings) => GetOrganizationSettingsSuccess({ payload })),
              catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
            );
          })
        );
      })
    )
  );

  updateOrganizationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateOrganizationSettings),
      exhaustMap((action) => {
        return this.apollo.mutate<IAppSettings>({ mutation: updateOrganizationSettings, variables: { settings: action.payload } }).pipe(
          map((data: ApolloQueryResult<IAppSettings>) => {
            if (data.errors?.length) throw data.errors;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return data.data['updateAppSettings']['appSetting'] as IAppSettings;
          }),
          map((payload: IAppSettings) => GetOrganizationSettingsSuccess({ payload })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        );
      })
    )
  );

  getAppVersioning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetVersioning),
      switchMap(() =>
        forkJoin([
          this.adminApiService.getClientVersion(ClientType.ios).pipe(catchError(() => of(null))),
          this.adminApiService.getClientVersion(ClientType.android).pipe(catchError(() => of(null))),
        ]).pipe(
          map(([IOS, ANDROID]) => GetVersioningSuccess({ versioning: { IOS, ANDROID } })),
          catchError(() => of(GetVersioningFailure()))
        )
      )
    )
  );

  updateVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateVersion),
      switchMap(({ version }) =>
        this.adminApiService.updateClientVersion(version).pipe(
          map((version) => {
            this.notificationService.success(`${version.clientType} version updated`);
            return UpdateVersionSuccess({ version });
          }),
          catchError((error) => {
            const errorMessage: string = error?.error?.errors?.map(({ errorMessage }) => errorMessage) ?? error.error;
            this.notificationService.error(errorMessage);
            return of(CommonHttpErrorResponse({ error }));
          })
        )
      )
    )
  );

  get3DSExemptionThreshold$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Get3DSExemptionThreshold),
      switchMap(() =>
        this.adminApiService.get3DSExemptionThreshold().pipe(
          map((threeDSExemptionThreshold) => Get3DSExemptionThresholdSuccess({ threeDSExemptionThreshold })),
          catchError(() => of(Get3DSExemptionThresholdFailure({ message: 'Unable to retrieve 3DS exemption threshold' })))
        )
      )
    )
  );

  update3DSExemptionThreshold$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Update3DSExemptionThreshold),
      switchMap(({ threeDSExemptionThreshold }) =>
        this.adminApiService.update3DSExemptionThreshold(threeDSExemptionThreshold).pipe(
          map((threeDSExemptionThreshold) => {
            return Update3DSExemptionThresholdSuccess({ threeDSExemptionThreshold });
          }),
          catchError(() => of(Update3DSExemptionThresholdFailure({ message: 'Unable to update 3DS exemption threshold' })))
        )
      )
    )
  );

  update3DSExemptionThresholdSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Update3DSExemptionThresholdSuccess),
        tap(() => {
          this.notificationService.success('3DS exemption threshold updated');
        })
      ),
    { dispatch: false }
  );

  handle3DSExemptionThresholdFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Get3DSExemptionThresholdFailure, Update3DSExemptionThresholdFailure),
        tap(({ message }) => {
          this.notificationService.error(message);
        })
      ),
    { dispatch: false }
  );
}
