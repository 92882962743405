import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'merchantStaffStatusPill',
})
export class MerchantStaffStatusPillPipe implements PipeTransform {
  transform(element: string): { color: string; text: string } {
    switch (element?.toLowerCase()) {
      case 'enabled':
        return { color: 'success', text: 'Active' };
      case 'disabled':
        return { color: 'warn', text: 'Disabled' };
      default:
        return { color: 'primary', text: 'N/A' };
    }
  }
}
