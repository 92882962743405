import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IEnvironment } from '../interfaces/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {
  get production(): boolean {
    return environment.production;
  }

  get apiEndpoint(): string {
    return environment.apiEndpoint;
  }

  get baseApiEndpoint(): string {
    return environment.baseApiEndpoint;
  }

  get graphQlEndpoint(): string {
    return environment.graphQlEndpoint;
  }

  get intercomAppId(): string {
    return environment.intercomAppId;
  }

  get kodyPayWebUrl(): string {
    return environment.kodyPayWebUrl;
  }

  get googleFontsApiKey(): string {
    return environment.googleFontsApiKey;
  }

  get launchDarklyClientId(): string {
    return environment.launchDarklyClientId;
  }
}
