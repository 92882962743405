import { createReducer, on, Action } from '@ngrx/store';
import {
  GetAdminUsersSuccess,
  GetAdminMerchantsStockSuccess,
  GetAdminMerchantsSToreSuccess,
  GetAdminMerchantsSuccess,
  GetAdminNotificationsSuccess,
  GetAdminRewardsSuccess,
  GetAdminStockStatusesSuccess,
  GetAdminRestaurantCategoriesSuccess,
  CreateUpdateAdminStockSuccess,
  GetAdminOfferTypesSuccess,
  GetAdminMerchantStockByIdSuccess,
  GetAdminUserByIdSuccess,
  UpdateAdminUserCard,
  GetRewardStatusesSuccess,
  CreateUpdateRewardSuccess,
  GetAdminUsersStatusesSuccess,
  GetOrganizationSettingsSuccess,
  GetAdminMerchantByIdSuccess,
  AddTriggerToRewardSuccess,
  RemoveTriggerFromRewardSuccess,
  DeleteAdminRewardsSuccess,
  UpdateAdminRewardsSuccess,
  GetAdminRewardByIdSuccess,
  DeleteAdminNotificationsSuccess,
  UpdateAdminUserStatusSuccess,
  VerifyAdminUserSuccess,
  GetMerchantStatsSuccess,
  GetVersioningSuccess,
  UpdateVersionSuccess,
  Get3DSExemptionThresholdSuccess,
  Update3DSExemptionThresholdSuccess,
} from '@app/state/admin-state/admin.actions';
import { ClientType, IAdminState } from './interfaces';
import { GetMerchantByRoleSuccess, GetMerchantByRole, Logout } from '../app-state/actions';

const initalAdminState: IAdminState = {};

function getInitalState(): IAdminState {
  const savedState = getSavedState();
  if (savedState) {
    return savedState;
  } else {
    return initalAdminState;
  }
}

function getSavedState(): IAdminState {
  const savedState = sessionStorage.getItem('adminState');
  if (savedState) {
    const parsedState = JSON.parse(savedState);
    if (parsedState?.merchantState) {
      return parsedState.merchantState;
    }
  }
  return null;
}

const adminReducer = createReducer(
  getInitalState(),
  on(GetAdminUsersSuccess, (state, { payload }) => {
    return {
      ...state,
      users: payload,
    };
  }),
  on(GetAdminUserByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      userToEdit: payload,
    };
  }),
  on(GetOrganizationSettingsSuccess, (state, { payload }) => {
    return {
      ...state,
      organizationSettings: payload,
    };
  }),
  on(GetAdminRewardByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      rewardToEdit: payload,
    };
  }),
  on(UpdateAdminUserCard, (state, { payload, status }) => {
    return {
      ...state,
      userToEdit: {
        ...state.userToEdit,
        cards: state.userToEdit.cards
          .map((card) => ({ ...card }))
          .map((card) => {
            // 3
            if (card.userCardId === payload.userCardId) {
              return {
                ...card,
                status: status,
              };
            } else {
              return card;
            }
          }),
      },
    };
  }),
  on(GetAdminMerchantsStockSuccess, (state, { payload }) => {
    return {
      ...state,
      merchantsStock: payload,
    };
  }),
  on(GetAdminMerchantsSToreSuccess, (state, { payload }) => {
    return {
      ...state,
      merchantStores: payload,
    };
  }),
  on(GetAdminMerchantByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      merchantInfo: payload,
    };
  }),
  on(GetAdminMerchantsSuccess, (state, { payload }) => {
    const merchantsArr = payload.map((store) => {
      return { ...store, statType: 'weekly' };
    });
    const map = {};
    merchantsArr.forEach((item) => {
      map[item.merchantId] = item;
    });
    return {
      ...state,
      merchants: merchantsArr,
      merchantsById: { ...(state.merchantsById || {}), ...map },
    };
  }),
  on(GetMerchantStatsSuccess, (state, { payload }) => {
    return {
      ...state,
      merchants: state.merchants
        .map((merchant) => ({ ...merchant }))
        .map((merchant) => {
          if (merchant.merchantId == payload.merchantId) {
            merchant.totalAmount = payload.totalAmount;
            merchant.transactionsCount = payload.transactionsCount;
            merchant.customersCount = payload.customersCount;
            merchant.statType = payload.statType;
            return {
              ...merchant,
            };
          } else {
            return merchant;
          }
        }),
    };
  }),
  on(GetAdminNotificationsSuccess, (state, { payload }) => {
    return {
      ...state,
      notifications: payload,
    };
  }),
  on(DeleteAdminNotificationsSuccess, (state) => {
    return {
      ...state, //need to update the notifications
    };
  }),
  on(GetAdminRewardsSuccess, (state, { payload }) => {
    return {
      ...state,
      rewards: payload,
    };
  }),
  on(DeleteAdminRewardsSuccess, (state) => {
    return {
      ...state,
    };
  }),

  on(AddTriggerToRewardSuccess, (state, { payload }) => {
    return {
      ...state,
      rewardToEdit: { ...state?.rewardToEdit, triggers: [...state?.rewardToEdit?.triggers, payload] },
    };
  }),
  on(RemoveTriggerFromRewardSuccess, (state, action) => {
    return {
      ...state,
      rewardToEdit: { ...state?.rewardToEdit, triggers: state?.rewardToEdit?.triggers?.filter((val, i) => i !== action.payload) },
    };
  }),
  on(GetAdminStockStatusesSuccess, (state, { payload }) => {
    const map = {};
    payload.forEach((item) => {
      map[item.statusId] = item;
    });
    return {
      ...state,
      statuses: payload,
      statusesById: {
        ...(state.statusesById || {}),
        ...map,
      },
    };
  }),
  on(GetRewardStatusesSuccess, (state, { payload }) => {
    const map = {};
    payload.forEach((item) => {
      map[item.statusId] = item;
    });
    return {
      ...state,
      statuses: payload,
      statusesById: {
        ...(state.statusesById || {}),
        ...map,
      },
    };
  }),
  on(GetAdminUsersStatusesSuccess, (state, { payload }) => {
    const map = {};
    payload.forEach((item) => {
      map[item.statusId] = item;
    });
    return {
      ...state,
      statuses: payload,
      statusesById: {
        ...(state.statusesById || {}),
        ...map,
      },
    };
  }),
  on(UpdateAdminUserStatusSuccess, (state) => {
    return {
      ...state,
    };
  }),
  on(VerifyAdminUserSuccess, (state, { payload }) => {
    return {
      ...state,
      users: state.users
        .map((user) => ({ ...user }))
        .map((user) => {
          if (payload.indexOf(user.userId) > -1) {
            user.emailConfirmed = true;
            user.phoneConfirmed = true;
            return {
              ...user,
            };
          } else {
            return user;
          }
        }),
    };
  }),
  on(CreateUpdateRewardSuccess, (state, { payload }) => {
    return {
      ...state,
      rewards: [...(state.rewards || []), payload],
      rewardToEdit: {},
    };
  }),

  on(UpdateAdminRewardsSuccess, (state) => {
    return {
      ...state,
    };
  }),
  on(GetAdminRestaurantCategoriesSuccess, (state, { payload }) => {
    const map = {};
    payload.forEach((item) => {
      map[item.restaurantMenuCategoryId] = item;
    });
    return {
      ...state,
      adminRestaurantMenuCategories: payload,
      restaurantMenuCategoryId: {
        ...(state.restaurantMenuCategoryId || {}),
        ...map,
      },
    };
  }),
  on(CreateUpdateAdminStockSuccess, (state) => {
    return {
      ...state,
      adminStockToEdit: {},
    };
  }),
  on(Logout, () => {
    sessionStorage.removeItem('appState');
    return initalAdminState;
  }),
  on(GetAdminOfferTypesSuccess, (state, { payload }) => {
    return {
      ...state,
      adminOfferTypes: payload,
    };
  }),
  on(GetAdminMerchantStockByIdSuccess, (state, { payload }) => {
    return {
      ...state,
      adminStockToEdit: payload,
    };
  }),
  on(GetMerchantByRoleSuccess, (state, { payload }) => {
    return {
      ...state,
      selectedMerchant: payload,
      selectedMerchantId: payload?.merchantId,
    };
  }),
  on(GetMerchantByRole, (state, { payload }) => {
    return {
      ...state,
      selectedMerchant: payload,
      selectedMerchantId: payload?.merchantId,
    };
  }),
  on(GetVersioningSuccess, (state, { versioning: { IOS, ANDROID } }) => {
    // If they have no version set up, provide a default
    const defaultIos = {
      internalVersion: '',
      externalVersion: '',
      clientType: ClientType.ios,
    };
    const defaultAndroid = {
      internalVersion: '',
      externalVersion: '',
      clientType: ClientType.android,
    };
    return {
      ...state,
      versioning: {
        IOS: IOS ?? defaultIos,
        ANDROID: ANDROID ?? defaultAndroid,
      },
    };
  }),
  on(UpdateVersionSuccess, (state, { version }) => ({
    ...state,
    versioning: {
      ...state.versioning,
      [version.clientType]: version,
    },
  })),
  on(Get3DSExemptionThresholdSuccess, Update3DSExemptionThresholdSuccess, (state, { threeDSExemptionThreshold }) => ({
    ...state,
    threeDSExemptionThreshold,
  }))
);

export function adminReducerCreator(state: IAdminState | undefined, action: Action): IAdminState {
  return adminReducer(state, action);
}
