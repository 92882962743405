import { isPingPassed } from '@app/state/app-state/selectors';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationFacadeService {

  constructor(
    protected store: Store,
  ) { }


  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  userInitialised(): Observable<boolean> {
    return this.store.select(isPingPassed);
  }

}
