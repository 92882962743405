import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Logout, PingSuccess } from '@app/state/app-state/actions';
import { tap } from 'rxjs/operators';
import { Intercom } from 'ng-intercom';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { Store } from '@ngrx/store';
import { getUser } from '@app/state/app-state/selectors';

@Injectable({ providedIn: 'root' })
export class IntercomEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private intercom: Intercom,
    private environmentService: EnvironmentService
  ) {}

  boot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PingSuccess),
        concatLatestFrom(() => this.store.select(getUser)),
        tap(([, { email, userId }]) =>
          this.intercom.boot({
            app_id: this.environmentService.intercomAppId,
            user_id: userId,
            email,
          })
        )
      ),
    { dispatch: false }
  );

  shutdown$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(Logout),
        tap(() => this.intercom.shutdown())
      ),
    { dispatch: false }
  );
}
