import { SetSession } from '@app/state/app-state/actions';
import { take, switchMap, map, tap, catchError, shareReplay, timeout } from 'rxjs/operators';
import { SecurityManagerService } from '@providers/security-manager.service';
import { SessionService } from './../../../providers/session-controller.service';
import { authHeaders } from './../constants/auth-headers.constant';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpInterceptor,
} from '@angular/common/http';
import { of } from 'rxjs';
import { AuthenticationFacadeService } from '../services/authentication.facade.service';
import { authenticationInterceptorActions } from '../../store/authentication/actions/authentication-interceptor.actions';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
  private readonly timeoutMillis = 15000;

  private registeringDevice$: Observable<any>;

  constructor(
    private authenticationFacade: AuthenticationFacadeService,
    private sessionService: SessionService,
    private securityManagerService: SecurityManagerService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO - Migrate HTTP proprietary 'feature flags' to use HTTP context flags
    if (request.headers.has(authHeaders.simple)) {
      return this.handleRequest({
        next,
        request,
        signRequest: false,
        simple: true,
      });
    } else if (request.headers.has(authHeaders.unsignedHeaderKey)) {
      return this.handleRequest({
        next,
        request,
        signRequest: false,
        simple: false,
      });
    } else if (request.headers.has(authHeaders.signedHeaderKey)) {
      return this.handleRequest({
        next,
        request,
        signRequest: true,
        simple: false,
      });
    } else {
      // Get the current session - performs a refresh on the auth token if required.
      return this.sessionService.currentSession().pipe(
        take(1),
        switchMap((session) => {
          return this.handleRequest({
            next,
            request,
            signRequest: true,
            accessToken: session?.accessToken,
            simple: false,
          });
        })
      );
    }
  }

  private handleRequest(config: HandleRequestArgs, retryOnFail = true): Observable<HttpEvent<any>> {
    const { next, request, signRequest, accessToken, simple } = config;
    const request$: Observable<HttpRequest<any>> = of(request);

    return request$.pipe(
      map((req) => {
        let headers = req.headers;
        headers = headers.delete(authHeaders.simple);
        headers = headers.delete(authHeaders.unsignedHeaderKey);
        headers = headers.delete(authHeaders.signedHeaderKey);

        // A 'simple' request does not have any additonal headers
        if (simple) {
          return req.clone({ headers });
        }

        if (!headers.has(authHeaders.generatedContentType)) {
          headers = headers.set('Content-Type', 'application/json');
        } else {
          headers = headers.delete(authHeaders.generatedContentType);
        }

        if (accessToken) {
          headers = headers.set('Authorization', `Bearer ${accessToken}`);
        }
        return req.clone({ headers });
      }),
      switchMap((req) => (signRequest ? this.securityManagerService.signRequest(req) : of(req))),
      switchMap((req) => next.handle(req)),
      tap(this.setSession),
      catchError((error) => this.handleErrorResponse(error, config, retryOnFail)),
      timeout(this.timeoutMillis)
    );
  }

  private setSession = (res: HttpResponse<any>) => {
    if (res instanceof HttpResponse) {
      const session = {
        accessTokenExp: res.headers.get('exp') ? parseInt(res.headers.get('exp')) : null,
        accessToken: res.headers.get('Authorization'),
        refreshToken: res.headers.get('refresh-token'),
      };
      if (session.accessToken || session.accessTokenExp || session.refreshToken) {
        this.authenticationFacade.dispatch(SetSession({ payload: session }));
      }
    }
    return res;
  };

  private handleErrorResponse = (error: HttpErrorResponse, handleRequestArgs: HandleRequestArgs, retry: boolean) => {
    if (error?.status === 423 && retry) {
      // Try registering the device if it is not already in progress.
      if (!this.registeringDevice$) {
        this.registeringDevice$ = this.securityManagerService.registerDevice().pipe(
          take(1),
          tap(() => (this.registeringDevice$ = undefined)),
          shareReplay(1)
        );
      }

      // Retry the request once the device is registered
      return this.registeringDevice$.pipe(switchMap(() => this.handleRequest(handleRequestArgs, false)));
    }

    if (error.status === 423) {
      this.authenticationFacade.dispatch(authenticationInterceptorActions.deviceRegistrationError({ error }));
    } else if (error.status === 401) {
      this.authenticationFacade.dispatch(authenticationInterceptorActions.notAuthenticatedError({ error }));
    }
    return throwError(error);
  };
}

export const httpInterceptProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationHttpInterceptor, multi: true }];

interface HandleRequestArgs {
  next: HttpHandler;
  request: HttpRequest<any>;
  signRequest?: boolean;
  accessToken?: string;
  simple: boolean;
}
