import { Injectable } from '@angular/core';
import { IMenuCategory, IStock } from '@app/state/merchant-state/interfaces';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { TableData } from '@shared/state-table/interfaces/table-data.interface';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaginatedResponse } from '@shared/state-table/interfaces/paginated-response.type';
import { authHeaders } from '@core/authentication/constants/auth-headers.constant';
import { ComboItem, CreateComboItem } from '@common/stock/types/combo-item.type';

@Injectable({
  providedIn: 'root',
})
export class StockDataService {
  constructor(private httpClient: HttpClient, private environment: EnvironmentService) {}

  private getEndpoint(merchantId: string, prefix: string, suffix?: string): string {
    return `${this.environment.apiEndpoint}/merchants/${merchantId}/${prefix}${suffix ? `/${suffix}` : ''}`;
  }

  getMenuCategories(merchantId: string): Observable<IMenuCategory[]> {
    return this.httpClient.get<IMenuCategory[]>(this.getEndpoint(merchantId, 'menuCategories'));
  }

  getMenuCategory(merchantId: string, restaurantMenuCategoryId: string): Observable<IMenuCategory> {
    return this.httpClient.get<IMenuCategory>(this.getEndpoint(merchantId, 'menuCategories', restaurantMenuCategoryId));
  }

  createMenuCategory(merchantId: string, menuCategory: Partial<IMenuCategory>): Observable<IMenuCategory> {
    return this.httpClient.post<IMenuCategory>(this.getEndpoint(merchantId, 'menuCategories'), menuCategory);
  }

  updateMenuCategory(
    merchantId: string,
    restaurantMenuCategoryId: string,
    menuCategory: Partial<IMenuCategory>
  ): Observable<IMenuCategory> {
    return this.httpClient.patch<IMenuCategory>(this.getEndpoint(merchantId, 'menuCategories', restaurantMenuCategoryId), menuCategory);
  }

  deleteMenuCategory(merchantId: string, restaurantMenuCategoryId: string): Observable<void> {
    return this.httpClient.delete<void>(this.getEndpoint(merchantId, 'menuCategories', restaurantMenuCategoryId));
  }

  reorderMenuCategories(merchantId: string, categoryIds: string[]): Observable<IMenuCategory[]> {
    return this.httpClient.put<IMenuCategory[]>(this.getEndpoint(merchantId, 'reorderMenuCategories'), categoryIds);
  }

  getStockItem(merchantId: string, merchantItemId: string): Observable<IStock> {
    return this.httpClient.get<IStock>(this.getEndpoint(merchantId, 'items', merchantItemId));
  }

  getStockItems(merchantId: string): Observable<IStock[]> {
    return this.httpClient.get<{ items: IStock[] }>(this.getEndpoint(merchantId, 'items')).pipe(pluck('items'));
  }

  createStockItem(merchantId: string, stock: Partial<IStock>): Observable<IStock> {
    return this.httpClient.post<IStock>(this.getEndpoint(merchantId, 'items'), stock);
  }

  updateStockItem(merchantId: string, merchantItemId: string, stock: Partial<IStock>): Observable<IStock> {
    return this.httpClient.patch<IStock>(this.getEndpoint(merchantId, 'items', merchantItemId), stock);
  }

  deleteStockItem(merchantId: string, merchantItemId: string): Observable<void> {
    return this.httpClient.delete<void>(this.getEndpoint(merchantId, 'items', merchantItemId));
  }

  deleteStockItems(merchantId: string, itemIds: string[], comboIds: string[]): Observable<void> {
    return this.httpClient.put<void>(this.getEndpoint(merchantId, 'deleteItems'), {
      items: itemIds,
      combos: comboIds,
    });
  }

  reorderStockItems(merchantId: string, categoryId: string, merchantItemIds: string[]): Observable<IStock[]> {
    const url = `${this.environment.apiEndpoint}/merchants/${merchantId}/menuCategories/${categoryId}/reorderItems`;
    return this.httpClient.put<IStock[]>(url, merchantItemIds);
  }

  importStockItems(merchantId: string, formData: FormData): Observable<void> {
    const url = `${this.environment.apiEndpoint}/merchants/${merchantId}/import/items`;
    return this.httpClient.post<void>(url, formData, { headers: { [authHeaders.generatedContentType]: 'true' } });
  }

  exportStockItems(merchantId: string, params: HttpParams): Observable<Blob> {
    const url = `${this.environment.apiEndpoint}/merchants/${merchantId}/items-export`;
    return this.httpClient.get(url, { params, responseType: 'blob' });
  }

  getPaginatedStockItems(merchantId: string, params: HttpParams): Observable<TableData<IStock>> {
    const url = this.getEndpoint(merchantId, 'items');
    return this.httpClient.get<PaginatedResponse<IStock, 'items'>>(url, { params }).pipe(
      map(({ items, paging }) => ({
        items,
        total: paging.count,
      }))
    );
  }

  createComboItem(storeId: string, combo: CreateComboItem): Observable<ComboItem> {
    const url = `${this.environment.apiEndpoint}/stores/${storeId}/combos`;
    return this.httpClient.post<ComboItem>(url, {
      ...combo,
      storeId,
    });
  }

  getComboItem(storeId: string, comboId: string): Observable<ComboItem> {
    const url = `${this.environment.apiEndpoint}/stores/${storeId}/combos/${comboId}`;
    return this.httpClient.get<ComboItem>(url);
  }

  updateComboItem(storeId: string, comboId: string, combo: Partial<CreateComboItem>): Observable<ComboItem> {
    const url = `${this.environment.apiEndpoint}/stores/${storeId}/combos/${comboId}`;
    return this.httpClient.patch<ComboItem>(url, {
      ...combo,
      comboId,
      storeId,
    });
  }

  deleteComboItem(storeId: string, comboId: string): Observable<void> {
    const url = `${this.environment.apiEndpoint}/stores/${storeId}/combos/${comboId}`;
    return this.httpClient.delete<void>(url);
  }
}
