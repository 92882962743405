import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, exhaustMap, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommonHttpErrorResponse } from '@app/state/app-state/actions';
import {
  GetRestaurantCategoriesSuccess,
  UpdateRestaurantCategoryOrder,
  GetCategoryByIdSuccess,
  CreateUpdateRestaurantCategory,
  GetCategoryById,
  GetRestaurantCategories,
  CreateUpdateRestaurantCategorySuccess,
  DeleteCategory,
  DeleteCategorySuccess,
} from '@app/state/merchant-state/actions/merchant.actions';
import { MerchantFacadeService } from '@merchant/merchant/services/merchant-facade.service';
import { StockDataService } from '@common/stock/services/stock-data/stock-data.service';
import { MerchantService } from '@merchant/merchant/services/merchant.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'any' })
export class MenuCategoriesEffects {
  constructor(
    private merchantService: MerchantService,
    private merchantFacadeService: MerchantFacadeService,
    private stockDataService: StockDataService,
    private actions$: Actions,
    private router: Router
  ) {}

  navigateToStockPage(menuCategoryIdEq?: string): void {
    this.merchantService.getPathPrefix().subscribe((pathPrefix) => {
      this.router.navigate([`${pathPrefix}/stock`], { queryParams: menuCategoryIdEq ? { menuCategoryIdEq } : null });
    });
  }

  getMenuCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetRestaurantCategories),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      exhaustMap(([_, merchantId]) =>
        this.stockDataService.getMenuCategories(merchantId).pipe(
          map((menuCategories) => GetRestaurantCategoriesSuccess({ payload: menuCategories })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  getMenuCategoryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetCategoryById),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      exhaustMap(([{ payload }, merchantId]) =>
        this.stockDataService.getMenuCategory(merchantId, payload).pipe(
          map((menuCategory) => GetCategoryByIdSuccess({ payload: menuCategory })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  createUpdateMenuCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateUpdateRestaurantCategory),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      exhaustMap(([{ payload, restaurantMenuCategoryId }, merchantId]) =>
        (restaurantMenuCategoryId
          ? this.stockDataService.updateMenuCategory(merchantId, restaurantMenuCategoryId, payload)
          : this.stockDataService.createMenuCategory(merchantId, payload)
        ).pipe(
          switchMap((menuCategory) => [CreateUpdateRestaurantCategorySuccess({ payload: menuCategory }), GetRestaurantCategories()]),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  deleteMenuCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteCategory),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([{ restaurantMenuCategoryId }, merchantId]) =>
        this.stockDataService.deleteMenuCategory(merchantId, restaurantMenuCategoryId).pipe(
          switchMap(() => [DeleteCategorySuccess({ restaurantMenuCategoryId }), GetRestaurantCategories()]),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  updateMenuCategoriesOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateRestaurantCategoryOrder),
      withLatestFrom(this.merchantFacadeService.getMerchantId()),
      switchMap(([{ payload }, merchantId]) =>
        this.stockDataService.reorderMenuCategories(merchantId, payload).pipe(
          map((payload) => GetRestaurantCategoriesSuccess({ payload })),
          catchError((error: Error) => of(CommonHttpErrorResponse({ error })))
        )
      )
    )
  );

  handleUpdateMenuCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CreateUpdateRestaurantCategorySuccess),
        tap(({ payload }) => {
          this.navigateToStockPage(payload.restaurantMenuCategoryId);
        })
      ),
    {
      dispatch: false,
    }
  );

  handleDeleteMenuCategorySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeleteCategorySuccess),
        tap(() => {
          this.navigateToStockPage();
        })
      ),
    {
      dispatch: false,
    }
  );
}
