import { Component, Input, ChangeDetectionStrategy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export interface IFlatBtnConfig {
  color: string;
  icon?: IconDefinition;
  text?: string;
  method?: (prop?: any) => void;
  responsive?: boolean;
}
@Component({
  selector: 'kody-flat-button',
  templateUrl: './kodypay-flat-button.component.html',
  styleUrls: ['./kodypay-flat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KodypayFlatButtonComponent {
  @Input() buttonConfig: IFlatBtnConfig;
  @Input() buttonTemplate: TemplateRef<HTMLElement>;
  @Output() buttonClickOutput: EventEmitter<() => void> = new EventEmitter<() => void>();

  onButtonClick(method: () => void): void {
    this.buttonClickOutput.emit(method);
  }
}
