<div class="wizard-status__container" [ngClass]="status">
  <div class="wizard-status__icon-container">
    <fa-icon class="wizard-status__icon" [icon]="icons[status]" [ngClass]="{ 'fa-spin': status === 'verifying' }"></fa-icon>
  </div>
</div>

<h3 class="wizard-status__title">{{ title }}</h3>
<p class="wizard-status__message">
  {{ message }}
  <ng-content select="[message]"></ng-content>
</p>

<button *ngIf="actionText" class="wizard-status__action" mat-raised-button color="primary" (click)="action.emit()">
  {{ actionText }}
</button>
