import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LoginError, CognitoHttpErrorResponse } from '@app/state/app-state/actions';
import { ErrorModalComponent } from '@common/error-modal.component';

@Injectable({ providedIn: 'any' })
export class ModalEffects {
  constructor(private actions$: Actions, private dialog: MatDialog) {}

  onHttpError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginError),
        map((action) => {
          this.openErrorModal(action.error);
        })
      ),
    { dispatch: false }
  );

  openErrorModal = (error: Error): void => {
    if (!(this.dialog.openDialogs.length > 0)) {
      this.dialog.open(ErrorModalComponent, {
        width: '250px',
        data: error,
      });
    }
  };

  cognitoHttpError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CognitoHttpErrorResponse),
        map((action) => {
          let msg = 'Something went wrong!';
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (action.error?.message) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            msg = action.error?.message as string;
          }
          this.openErrorModal(new Error(msg));
        })
      ),
    { dispatch: false }
  );
}
