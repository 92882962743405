import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@ngneat/reactive-forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EnvironmentService } from '@core/environment/services/environment.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { imageFileTypes } from '@utils/constants';
import { IUploadFile } from '@app/state/app-state/interfaces';
import { FileUploadComponent } from '@common/file-upload/components/file-upload-input/file-upload.component';

@Component({
  selector: 'kody-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ImageUploadComponent,
      multi: true,
    },
  ],
})
// Expects input of url or base 64. Outputs base 64
export class ImageUploadComponent extends ControlValueAccessor<string> {
  @ViewChild('fileUpload') fileUpload: FileUploadComponent;
  @Input() placeholder = 'Upload an image';
  @Input() fileTypes = imageFileTypes;
  @Input() width = 400;
  @Input() height = 200;

  icons = { faTrash, faRedo, faUpload };
  value: string;
  imageSrc: string; // Full url or base 64 with metadata

  constructor(private environmentService: EnvironmentService, private cd: ChangeDetectorRef) {
    super();
  }

  writeValue(value: string) {
    this.value = value;
    this.onChange(this.value);

    if (this.value?.indexOf('/web') === 0) {
      this.imageSrc = `${this.environmentService.baseApiEndpoint}${this.value}`;
    }
  }

  clear(): void {
    this.writeValue(null);
    this.fileUpload.clear();
  }

  redo(): void {
    this.clear();
    this.upload();
  }

  upload(): void {
    this.fileUpload.upload();
  }

  onFileSelected({ dataBase64 }: IUploadFile): void {
    this.writeValue(dataBase64);
    this.cd.markForCheck();
  }

  onFileLoaded(base64WithMeta: string): void {
    this.imageSrc = base64WithMeta;
  }
}
