import { Observable, of } from 'rxjs';
import { ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { stateTableActions } from '@shared/state-table/actions/state-table.actions';
import { catchError, distinctUntilChanged, map, switchMap, tap, takeUntil, debounceTime } from 'rxjs/operators';
import { TableData } from '@shared/state-table/interfaces/table-data.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { isEqual } from 'lodash';
import { Router } from '@angular/router';

export const fetchStateTableData$ = <T>(
  type: string,
  router: Router,
  actions$: Observable<Action>,
  query: Observable<any>,
  fetchData: (query) => Observable<TableData<T>>
): Observable<Action> => {
  const { initialise, refresh, fetchDataSuccess, fetchDataError, destroy } = stateTableActions<T>(type);
  return actions$.pipe(
    ofType(initialise, refresh),
    switchMap(() => query.pipe(distinctUntilChanged(isEqual), debounceTime(300))),
    tap((query) => {
      router.navigate([], {
        queryParams: {
          ...query,
        },
        replaceUrl: true,
      });
    }),
    switchMap((query) =>
      fetchData(query).pipe(
        map((data) => fetchDataSuccess(data)),
        catchError((error: HttpErrorResponse) => of(fetchDataError({ error }))),
        takeUntil(actions$.pipe(ofType(destroy)))
      )
    )
  );
};
