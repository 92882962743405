import { Component, ChangeDetectionStrategy, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ITableDisplayedColumn } from '@app/state/app-state/interfaces';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'filter-date-range-control',
  templateUrl: './filter-date-range-control.component.html',
  styleUrls: ['./filter-date-range-control.component.scss'],
  host: { class: 'd-flex w-100 align-items-baseline' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDateRangeControlComponent {
  @Input()
  column: ITableDisplayedColumn;
  @Output() changed: EventEmitter<any> = new EventEmitter();

  from: Date;
  to: Date;

  writeValue(): void {
    const fromToObj = {};
    fromToObj[this.column?.id] = {};
    if (this.to) fromToObj[this.column?.id]['lessThanOrEqualTo'] = new Date(this.to.setHours(23, 59, 59)).toISOString();
    if (this.from) fromToObj[this.column?.id]['greaterThanOrEqualTo'] = this.from.toISOString();
    if (Object.keys(fromToObj[this.column?.id])?.length == 0) {
      this.changed.emit(null);
    } else {
      this.changed.emit(fromToObj);
    }
  }
}
