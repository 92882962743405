import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { take, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationFacadeService } from '../services/authentication.facade.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivateChild, CanActivate {

  constructor(
    private authenticationFacade: AuthenticationFacadeService,
  ) { }

  private guardFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {

    return this.authenticationFacade.userInitialised()
      .pipe(
        filter(((userInitialised) => !!userInitialised)),
        take(1),
      );
  }

  canActivate = this.guardFn;
  canActivateChild = this.guardFn;
}
