import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from '@app/state/app-state/interfaces';
import { getQrCodesToPrint } from '@app/state/app-state/selectors';
import { Ping } from './state/app-state/actions';

@Component({
  selector: 'kody-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'w-100 h-100 justify-content-center',
  },
})
export class AppComponent implements AfterViewInit {
  users$: Observable<User[]>;
  qrCodes: Observable<string[]> = this.store.select(getQrCodesToPrint);

  constructor(private store: Store) {}

  ngAfterViewInit(): void {
    this.store.dispatch(Ping());
  }
}
