import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { FormColourInputComponent } from './components/form-colour-input/form-colour-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { WorkingHoursFormComponent } from './components/working-hours-form/working-hours-form.component';
import { CheckListComponent } from './components/check-list/check-list.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ThemePickerComponent } from './components/theme-picker/theme-picker.component';
import { KodypayCommonModule } from '@common/kodypay-common.module';

@NgModule({
  declarations: [FormGroupComponent, FormColourInputComponent, WorkingHoursFormComponent, CheckListComponent, ThemePickerComponent],
  exports: [FormGroupComponent, FormColourInputComponent, WorkingHoursFormComponent, CheckListComponent, ThemePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    MatChipsModule,
    DragDropModule,
    MatCheckboxModule,
    MatTooltipModule,
    KodypayCommonModule,
  ],
})
export class FormsModule {}
