import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { IUploadFile } from '@app/state/app-state/interfaces';

@Component({
  selector: 'kody-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Output() fileSelected: EventEmitter<IUploadFile> = new EventEmitter<IUploadFile>();
  @Output() fileLoaded = new EventEmitter<string>();
  @ViewChild('upload') uploadInputRef: ElementRef<HTMLInputElement>;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() maxSizeMb = 15;
  @Input() fileTypes: string[] = [];
  fileName = '';
  private reader: FileReader = new FileReader();
  @Input()
  get value(): IUploadFile {
    return this._value;
  }
  set value(newValue: IUploadFile) {
    this._value = newValue;
    this._updateInputValue(this._value?.fileName);
  }
  private _value: IUploadFile = { dataBase64: '', fileName: '', mimeType: '' };
  constructor(private _changeDetector: ChangeDetectorRef) {}

  _onTouched: () => void = () => {};
  private _onChange: (value: any) => void = (_: any) => {};

  writeValue(value: IUploadFile): void {
    if (value) {
      this.value = { ...value };
      this._onChange(this.value);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  handleFiles(filelist: FileList) {
    if (filelist.length > 0) {
      const file: File = filelist.item(0);
      if (this.fileTypes.indexOf(file.type) == -1) {
        return;
      }
      if (this.maxSizeMb * 1024 * 1024 < file.size) {
        return;
      }
      this.reader.onload = () => {
        const result = this.reader.result.toString();
        let encoded = result.replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        const newVal: IUploadFile = {
          dataBase64: encoded,
          fileName: file.name,
          mimeType: file.type,
        };
        this._resetInputFile();
        this._updateInputValue(file);
        this.writeValue(newVal);
        this.fileSelected.next(newVal);
        this.fileLoaded.emit(result);
      };

      this.reader.readAsDataURL(file);
    }
  }

  clear(): void {
    this._resetInputFile();
    this.writeValue(null);
    this._updateInputValue(null);
  }

  upload(): void {
    this.uploadInputRef.nativeElement.click();
  }

  private _resetInputFile() {
    this.uploadInputRef.nativeElement.value = '';
    this._changeDetector.markForCheck();
  }

  private _updateInputValue(file: File | string) {
    if (file instanceof File) {
      this.fileName = file.name != null ? file.name : null;
    } else {
      this.fileName = file || '';
    }
    this._changeDetector.markForCheck();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    if (c.validator) {
      const validator = c.validator({} as AbstractControl);
      if (validator && validator.required) {
        return this.value?.fileName ? null : { invalidForm: { valid: false, message: 'basicInfoForm fields are invalid' } };
      }
    }
  }
}
