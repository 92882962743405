import { IAppState, ITableState, ITableDisplayedColumn } from '@app/state/app-state/interfaces';
import { createSelector, Action } from '@ngrx/store';
import { sideMenusByRole } from '@utils/constants';

export interface AppState {
  appState: IAppState;
}

export const appState = (state: AppState): IAppState => state.appState;

export const currentSession = createSelector(appState, (state: IAppState) => state?.session);

export const isPingPassed = createSelector(appState, (state: IAppState) => state.pingPassed);

export const getSideMenu = createSelector(appState, (state: IAppState) => {
  const role = state.selectedRole?.name?.toLowerCase();
  if (role === 'organization_admin') {
    return sideMenusByRole?.admin;
  }
  if (role === 'kodypay_admin') {
    return sideMenusByRole?.kodypayAdmin;
  }
  return sideMenusByRole?.default;
});

export const getRoles = createSelector(appState, (state: IAppState) => state.roles);

export const getUser = createSelector(appState, (state: IAppState) => state.user);

export const getCurrentRole = createSelector(appState, (state: IAppState) => state?.selectedRole);

export const getOthers = createSelector(appState, (state: IAppState) => state?.selectedRole?.reducedOthers);

export const getSelectedMainPath = createSelector(appState, (state: IAppState) => {
  return state.selectedMainPath;
});

export const getQrCodesToPrint = createSelector(appState, (state: IAppState) => {
  return state.qrCodes;
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTableState = (action: Action, initialColumns?: ITableDisplayedColumn[], pagination?: boolean, orderActionId?: string) =>
  createSelector(appState, (state: IAppState) => {
    if (state?.tableStates?.[action.type]) {
      if (initialColumns) {
        return {
          ...state.tableStates[action.type],
          displayedColumnNames: initialColumns?.filter((item) => !item?.hideColumn)?.map((item) => item.id),
          displayedColumns: initialColumns?.filter((item) => !item?.hideColumn),
          filters: initialColumns?.filter((item) => item.filterable),
          searchableColumns: initialColumns?.filter((item) => item.searchable),
          hasClickableRows: initialColumns?.filter((item) => item.clickable),
          exportableColumns: initialColumns?.filter((item) => item.exportableOpts?.exportable),
          filterObject: null,
          orderActionId: orderActionId,
        };
      } else {
        return state.tableStates[action.type];
      }
    } else {
      const defaultOpts: ITableState = {
        id: action,
        length: 0,
        offset: 0,
        first: 5,
        displayedColumnNames: initialColumns?.filter((item) => !item?.hideColumn)?.map((item) => item.id),
        displayedColumns: initialColumns?.filter((item) => !item?.hideColumn),
        filters: initialColumns?.filter((item) => item.filterable),
        searchableColumns: initialColumns?.filter((item) => item.searchable),
        hasClickableRows: initialColumns?.filter((item) => item.clickable),
        exportableColumns: initialColumns?.filter((item) => item.exportableOpts?.exportable),
        orderActionId: orderActionId,
        filterObject: null,
        droppable: false,
      };
      return defaultOpts;
    }
  });

export const getSelectedMainPathLabel = createSelector(appState, (state: IAppState) => {
  return state.selectedMainPath.label;
});
